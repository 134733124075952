.Template6Wrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.saveButton {
		position: absolute;
		top: 38%;
		left: 2.913%;
		z-index: 10;
		border: none;
		background: transparent;
		cursor: pointer;
		font-size: larger;
		color: #2f7a7a;
		font-weight: 700;
	}
}

.Template6 {
	// width: 1728px;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	scroll-behavior: smooth;
	scroll-snap-type: y proximity;
	margin: 0 auto;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	section {
		scroll-snap-align: start;
		scroll-snap-stop: always;
	}
}

.container1 {
	width: 108rem;
	min-width: 100vw;
	height: 80.96044rem;
	max-width: 100vw;
	max-height: 100vh;
	background: white;
	background: linear-gradient(90deg, rgba(255, 255, 255, 1) 38%, rgba(7, 8, 10, 1) 62%, rgba(7, 8, 10, 1) 100%);
	position: relative;
	// overflow: hidden;

	@media (max-width: 930px) and (min-height: 600px) {
		min-width: 100vw;
		min-height: 100vh;
		// border: 3px solid blueviolet;
	}
	.Reel1UploadBtn {
		position: absolute;
		z-index: 10;
		top: 3%;
		right: 3%;
		height: 3.5rem;
		width: 3.5rem;
	}

	.coverImage {
		width: 62.76338rem;
		width: 100vw;
		height: 81.41456rem;
		max-height: 100vh;
		// max-width: 80vw;
		margin-left: auto;
		position: relative;

		@media (max-width: 930px) and (min-height: 600px) {
			min-height: 80vh;
			max-height: 80vh;
			max-width: 100vw;
		}

		.editImageIcon {
			position: absolute;
			top: 0;
			right: 0;
			font-size: xx-large;
			z-index: 2;
			background-color: rgba(255, 255, 255, 0.027);
		}

		.structure {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			img {
				height: 100%;
				width: 100%;
			}
		}

		// border: 2px solid rgb(31, 33, 155);
	}

	.coverImage img {
		position: relative;
		z-index: 55;
		height: 100%;
		width: 100%;
		object-fit: contain;
		z-index: 1;
		// -webkit-mask-image: url("./images/desk.png");
		// mask-image: url("./images/desk.png");
		// -webkit-mask-repeat: no-repeat;
		// mask-repeat: no-repeat;
		// mask-position: center;
		// mask-size: 100% 100%;
		// -webkit-mask-position: center;
		// @media (max-width: 930px) and (min-height: 600px) {
		// 	-webkit-mask-image: url("./images/mobileStructure.png");
		// 	mask-image: url("./images/mobileStructure.png");
		// }
		@media (max-width: 930px) and (min-height: 600px) {
			object-fit: cover;
		}
	}

	.nameContainer {
		height: 10rem;
		max-height: 12vw;
		width: 42rem;
		max-width: 65vh;
		color: #000;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		white-space: nowrap;
		transform: rotate(-90deg);
		// border: 5px solid rgb(9, 148, 101);
		position: absolute;
		bottom: 56%;
		left: 16%;
		z-index: 2;
		span {
			z-index: 5;
			font-family: "Italiana";
			font-size: 13.98263rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 7.5rem !important;
			}
		}

		&::before {
			content: "I'm";
			position: absolute;
			top: -30%;
			left: 10%;
			color: #000;
			font-family: Italiana;
			font-size: 5.08231rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			@media (max-width: 650px) {
				font-size: 4.47863rem;
				top: -80%;
			}
		}
		&::-webkit-scrollbar {
			display: none;
		}

		@media (max-width: 1410px) {
			@media (max-height: 577px) {
				left: 24%;
				// font-size: 2.9rem !important;
			}
		}
		@media (max-width: 1280px) {
			bottom: 59%;
		}
		@media (max-width: 930px) and (min-height: 600px) {
			left: 7%;
			bottom: 52%;
		}
		@media (max-width: 630px) and (min-height: 600px) {
			left: -19%;
			bottom: 52%;
		}
	}

	.shadow {
		color: #ededed;
		text-align: center;
		font-family: "Poppins";
		font-size: 15rem;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
		position: absolute;
		z-index: 4;
		top: 25%;
		left: -5%;
		// transform: rotate(-90deg);
		text-transform: uppercase;

		@media (max-width: 1410px) {
			@media (max-height: 580px) {
				font-size: 9.5rem;
			}
		}
		@media (max-width: 1250px) {
			font-size: 15.5rem;
		}

		@media (max-width: 930px) and (min-height: 600px) {
			top: -30%;
			left: -40%;
		}
	}

	.leftStructure {
		position: absolute;
		min-height: 100vh;
		min-width: 100vw;
		max-height: 100vh;
		max-width: 100vw;
		// background-color: red;
		background-image: url("./images/desktop.png");
		background-size: cover;
		background-position: center;
		left: 0;
		top: 0;
		z-index: 2;
		border: 5px solid red !important;

		@media (max-width: 930px) and (min-height: 600px) {
			background-image: url("./images/mobileStructure.png");
		}
	}

	.coverFooter {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 5rem;
		width: 100vw;
		position: absolute;
		bottom: 0;
		z-index: 9;

		@media (max-width: 930px) and (min-height: 600px) {
			// background-color: red;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 20vh;
			max-width: 100vw;
			position: relative;
		}

		.coverLeft {
			width: 51.09525rem;
			height: 24.06531rem;
			max-width: 47.3447vw;
			max-height: 29.7246vh;
			background: #383a39;
			margin-bottom: 1rem;

			@media (max-width: 930px) and (min-height: 600px) {
				min-width: 100vw;
				min-height: 20vh;
				max-height: 20vh;
			}

			// border: 5px solid rebeccapurple;

			.nameusername {
				height: 60%;
				max-height: 60%;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.name {
					color: #fff;
					font-family: Poppins;
					font-size: 4.33081rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (max-width: 1410px) {
						@media (max-height: 570px) {
							font-size: 3.89rem;
						}
					}
				}

				.username {
					color: #fff;
					font-family: Poppins;
					font-size: 2.03163rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (max-width: 1410px) {
						@media (max-height: 570px) {
							font-size: 1.89rem;
						}
					}
				}
			}

			.coverbuttons {
				height: 40%;
				width: 100%;
				display: flex;
				align-items: flex-end;
				justify-content: space-evenly;
				padding: 2rem;
				@media (max-width: 1410px) {
					@media (max-height: 570px) {
						justify-content: space-around;
						padding: 0.3rem;
					}
				}
			}

			.coverbuttons button {
				border: none;
				background: transparent;
				color: #fff;
				font-family: Poppins;
				font-size: 1.563rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				cursor: pointer;
			}
		}

		.coverRight {
			display: flex;
			align-items: center;
			gap: 2.3145rem;
			justify-content: center;

			@media (max-width: 930px) and (min-height: 600px) {
				position: absolute;
				top: -60%;
				width: 100vw;
				justify-content: space-between;
				padding: 1rem 4.5rem;
			}

			.star {
				width: 9.615rem;
				height: 9.615rem;
				border-radius: 50%;
				background-color: 515151;
				background-image: url("../../assets/images/rating.png");
				background-size: cover;
				background-position: center;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-family: Poppins;
				font-size: 1.72663rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				cursor: pointer;
			}

			.followers {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.followerCount {
					color: #fff;
					font-family: Poppins;
					font-size: 5.01513rem;
					font-style: normal;
					font-weight: 600;
					line-height: 5rem;
				}

				.textFollower {
					color: #fff;
					font-family: Poppins;
					font-size: 1.34181rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}
	}
}

.container2 {
	// width: 108rem;
	height: 80.96044rem;
	min-width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	background-color: white;
	position: relative;

	@media (max-width: 930px) and (min-height: 600px) {
		min-height: 100vh;
		min-width: 100vw;
		max-height: 100vh;
		max-width: 100vw;
	}

	.hero {
		width: 108.60581rem;
		width: 100vw;
		height: 57.24463rem;
		max-height: 65.7373vh;
		max-width: 100vw;
		position: relative;

		@media (max-width: 930px) and (min-height: 600px) {
			min-height: 70vh;
			min-width: 100vw;
			max-height: 100vh;
			max-width: 100vw;
		}

		.editImageIcon {
			position: absolute;
			top: 2rem;
			right: 4rem;
			height: 3.5rem;
			width: 3.5rem;
		}

		.IntroImage {
			top: 8%;
			right: 10%;
			@media (max-width: 650px) {
				top: 0;
			}
		}
	}

	.hero img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.introFooter {
		background-color: white;
		padding: 0 6.6574rem;
		height: 30%;
		max-height: 29vh;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		transform: translateY(-10%);
		@media (max-width: 650px) {
			position: absolute;
			bottom: 0;
			height: 40vh;
			max-height: 40vh;
		}

		.EditTextIcon {
			height: 3.5rem;
			width: 3.5rem;
			position: absolute;
			top: 7%;
			left: 30%;
			cursor: pointer;
			@media (max-width: 930px) and (min-height: 600px) {
				top: 5%;
				left: 75%;
			}
		}

		.introTitle {
			color: #000;
			font-family: Italiana;
			font-size: 6.38463rem;
			font-style: normal;
			font-weight: 400;
			line-height: 6.7rem;
			border: none;
		}

		.introDescription {
			width: 100%;
			height: 23rem;
			width: 100%;
			min-height: 7rem;
			color: #000;
			font-family: "Inter";
			font-size: 1.5rem;
			font-style: normal;
			font-weight: 400;
			line-height: 138%;
			/* 2.76rem */
			overflow-y: scroll;
			margin-bottom: 2rem;
			border: none;

			&::-webkit-scrollbar {
				display: none;
			}
			// @media(max-width:1280px) and (max-height:577px){
			//   color: red;
			//   border: 2px solid red;
			// }

			@media (max-width: 930px) and (min-height: 600px) {
				width: 20.37825rem;
				min-width: 70vw;
				// height: 7.63506rem;
				height: 25vh;
				color: #000;
				font-family: Poppins;
				font-style: normal;
				font-weight: 300;
				line-height: 174%;
			}
		}

		.introButtons {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 6rem;

			@media (max-width: 930px) and (min-height: 600px) {
				flex-direction: column;
				gap: 1rem;
			}

			.buttonContainer {
				height: 100%;
				width: 100%;
				border: 2px solid grey;
				white-space: nowrap;
			}
		}

		.introButtons button {
			color: #3f3f3f;
			font-family: Poppins;
			font-size: 1.50625rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			cursor: pointer;
			background: transparent;
			padding: 0.6rem 5.6rem;
			border: none;

			@media (max-width: 930px) and (min-height: 600px) {
				padding: 1rem 13rem;
				white-space: nowrap;
			}
		}
	}

	.bottomStructure {
		background-image: url("./images/structure2.png");
		background-size: cover;
		background-position: center;
		min-height: 100%;
		min-width: 100%;
		// border: 2px solid greenyellow;
		position: absolute;
		top: 28vh;
		@media (max-width: 930px) and (min-height: 600px) {
			top: 13vh;
		}
	}
}

.container3 {
	width: 108rem;
	height: 80.96044rem;
	max-width: 100vw;
	max-height: 100vh;
	// border: 2px solid #ff0000;
	background-color: white;

	@media (max-width: 930px) and (min-height: 600px) {
		min-width: 100vw;
		min-height: 100vh;
		max-height: 100vh;
	}

	.galleryContainer {
		display: flex;
		align-items: center;
		// justify-content: space-around;
		height: 100%;
		width: 100%;

		@media (max-width: 930px) and (min-height: 600px) {
			flex-direction: column;
		}

		.galleryLeft {
			height: 100%;
			width: 50%;
			// background-color: rgba(47, 0, 255, 0.74);
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			@media (max-width: 930px) and (min-height: 600px) {
				min-width: 100%;
				min-height: 70%;
				// border: 3px solid green;
				transform: translateY(11vh);
			}

			.galleryHero {
				width: 34.96975rem;
				height: 64.22494rem;
				max-height: 79.33vh;
				max-width: 32.43vw;
				// border: 2px solid lightcoral;
				border-radius: 170.70488rem;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #000;
				overflow: hidden;

				@media (max-width: 930px) and (min-height: 600px) {
					width: 19.20519rem;
					height: 35.27194rem;
					min-width: 72vw;
					min-height: 60vh;
				}
			}

			.galleryHero img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				// border-radius: 170.70488rem;
			}

			.heroLeft {
				width: 12.50438rem;
				height: 25.65025rem;
				max-height: 31.6761vh;
				max-width: 11.59vw;
				border-radius: 61.04025rem;
				// border: 2px solid red;
				position: absolute;
				top: 20%;
				right: 70%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #000;
				overflow: hidden;

				@media (max-width: 930px) and (min-height: 600px) {
					min-width: 22vw;
					min-height: 25vh;
					top: 10%;
					right: 75%;
				}
			}

			.heroLeft img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				// border-radius: 61.04025rem;
			}

			.heroRight {
				width: 12.50438rem;
				height: 25.65025rem;
				max-height: 31.6761vh;
				max-width: 11.59vw;
				border-radius: 61.04025rem;
				// border: 2px solid red;
				position: absolute;
				bottom: 20%;
				left: 70%;

				@media (max-width: 930px) and (min-height: 600px) {
					min-width: 22vw;
					min-height: 25vh;
					bottom: 10%;
					left: 75%;
				}
			}

			.heroRight img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 61.04025rem;
			}
		}

		.galleryRight {
			width: 50%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			flex-direction: column;
			gap: 1rem;

			.gallery {
				height: auto;
				width: 30vw;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				gap: 0.6rem;

				@media (max-width: 930px) and (min-height: 600px) {
					// background-color: #b41f1f;
					min-width: 80vw;
					flex-wrap: nowrap;
					justify-content: flex-start;
					overflow: scroll;
					gap: 1.7rem;
					margin-top: 6rem;

					&::-webkit-scrollbar {
						display: none;
					}
				}
			}

			.galleryText {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				min-width: 100vw;
				max-height: 10vh;

				// background-color: greenyellow;

				@media (max-width: 930px) and (min-height: 600px) {
					transform: translatey(-69vh) translateX(-24vw);
					padding: 0 3rem;
				}

				.EditTextIcon {
					width: 4rem;
					height: 4rem;
					position: absolute;
					bottom: -2rem;
					left: -5%;
					cursor: pointer;
				}

				.myGallery {
					color: #000;
					font-family: Italiana;
					font-size: 4.48931rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					@media (max-width: 930px) and (min-height: 600px) {
					}
				}

				.galleryDesc {
					width: 27.564rem;
					height: 3.92331rem;
					border: none;
					color: #686868;
					font-family: Poppins;
					font-size: 1.11306rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					overflow-y: scroll;
					margin-bottom: 2rem;

					&::-webkit-scrollbar {
						display: none;
					}
				}
			}

			.ViewAll {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding-right: 4rem;

				@media screen and (max-width: 700px) {
					position: absolute;
					bottom: 10%;
					right: -8rem;
				}

				p {
					cursor: pointer;
					color: #000;
					font-size: 1.3rem;
				}
			}
		}
	}
}

.container4 {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 0.5rem;
	background-color: white;
	padding-bottom: 5rem;
	position: relative;

	.EditTextIcon {
		height: 3.5rem;
		width: 3.5rem;
		position: absolute;
		top: 2%;
		left: 30%;
		cursor: pointer;
		@media (max-width: 930px) and (min-height: 600px) {
			top: 2%;
			left: 13vw;
		}
	}

	.shortMessage {
		color: #000;
		font-family: Italiana;
		font-size: 3.82219rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.galleryDesc {
		width: 35.616rem;
		min-height: 10rem;
		// max-height: 11rem;
		color: #686868;
		text-align: center;
		font-family: Poppins;
		font-size: 1.70063rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		overflow-y: scroll;
		border: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.thankYou {
		color: #686868;
		font-family: Noto Serif;
		font-size: 1.74913rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 1.7rem;
	}
}

.smallContainer {
	width: 6.748rem;
	height: 6.748rem;
	// min-height: 6.748rem;
	// min-width: 6.748rem;
	border-radius: 6.748rem;
	cursor: pointer;

	@media (max-width: 930px) and (min-height: 600px) {
		min-width: 6.748rem;
		min-height: 6.748rem;
	}
}

.smallContainer img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 6.748rem;
}
