.TemplateWrapper {
	height: 100%;
	height: 100%;
	background: #ffffff;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template_3 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		// border: 1px solid #f00;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}

		.thankYou {
			margin-top: 8.38rem;
			height: 5rem;

			h1 {
				height: 4.625rem;
				text-align: center;
				font-weight: 400;
				font-size: 2.93513rem;
				line-height: 4.625rem;
				font-family: Noto Serif;
				color: #000;
			}
		}
	}
}
