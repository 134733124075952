#CarouselB10 {
	.alice-carousel__wrapper {
		
	}

	.alice-carousel__prev-btn {
		display: none;
	}

	.alice-carousel__next-btn {
		display: none;
	}

	.alice-carousel__dots {
		display: none;
	}
}