.TemplateWrapper {
	height: 100%;
	height: 100%;
	background: #ffffff;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template4 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		// border: 1px solid #f00;
		background: #0a090d;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}

	.Container1 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), lightgray 50% / cover no-repeat;
		display: flex;
		flex-direction: column;

		.topSection {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 87.2%;
			width: 100%;
			z-index: 1;
			position: relative;
			padding: 3vh 3vw;

			@media (max-width: 930px) and (min-height: 600px) {
				width: 100%;
				height: 90.49vh;
			}
			.backgroundImage {
				z-index: -1;
				height: 100%;
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				.landingChangeIcon {
					width: 4rem;
					height: 4rem;
					cursor: pointer;
					right: 3rem;
					top: 2rem;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 3rem;
						height: 3rem;
					}
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.section1 {
				.logo {
					display: flex;
					width: 11.57vw;
					height: 10.74vh;
					justify-content: center;
					align-items: center;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 23.256vw;
						height: 6.43vh;
					}
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
					p {
						color: #fff;
						font-family: Poppins;
						font-size: 2.16544rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						letter-spacing: 0.34644rem;
						@media (max-width: 930px) and (min-height: 600px) {
							font-size: 1.125rem;
							letter-spacing: 0.18rem;
						}
					}
				}
			}
			.section2 {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.leftSection {
					display: flex;
					flex-direction: column;
					gap: 0.1rem;
					position: relative;
					.EditTextIcon {
						height: 4rem;
						width: 4rem;
						position: absolute;
						top: -4rem;
						left: 1rem;
						@media (max-width: 930px) and (min-height: 600px) {
							height: 3rem;
							width: 3rem;
							left: 7rem;
						}
					}
					.name {
						color: #fff;
						font-family: Bebas Neue;
						font-size: 9.27175rem;
						font-style: normal;
						font-weight: 400;
						line-height: 108%; /* 10.0135rem */
						letter-spacing: 0.37088rem;
						@media (max-width: 930px) and (min-height: 600px) {
							font-size: 3.25rem;
							letter-spacing: 0.13rem;
						}
					}
					.buttons {
						display: flex;
						gap: 1rem;

						button {
							display: inline-flex;
							height: 4.6875rem;
							padding: 1.25rem 3.75rem;
							justify-content: center;
							align-items: center;
							gap: 0.625rem;
							flex-shrink: 0;
							border: none;
							background: transparent;
							font-family: Inter;
							font-size: 1rem;
							font-style: normal;
							font-weight: 300;
							line-height: normal;
							letter-spacing: 0.265rem;
							text-transform: uppercase;
							@media (max-width: 930px) and (min-height: 600px) {
								display: flex;
								width: 8.75rem;
								height: 3rem;
								padding: 1.25rem 3.75rem;
								justify-content: center;
								align-items: center;
								gap: 0.625rem;
								flex-shrink: 0;
							}
							&:first-child {
								border: 1px solid #fff;
								background: #fff;
								color: #000;
								width: 11rem;
							}
							&:last-child {
								border: 1px solid #fff;
								color: #fff;
							}
						}
					}
					.socials {
						margin-top: 2rem;
						height: 4.25rem;
						width: 31.125rem;
						// transform: rotate(-90deg);
						flex-shrink: 0;
						border-radius: 1.375rem;
						background: rgba(0, 0, 0, 0.05);
						backdrop-filter: blur(10px);
						display: flex;
						align-items: center;
						justify-content: space-around;
						@media (max-width: 930px) and (min-height: 600px) {
							width: 15.9375rem;
							height: 2.875rem;
							border-radius: 1.25rem;
						}
						img {
							cursor: pointer;
							width: 2.53494rem;
							height: 2.53494rem;
							@media (max-width: 930px) and (min-height: 600px) {
								width: 1.2105rem;
								height: 1.2105rem;
								flex-shrink: 0;
							}
						}
					}
				}
				.rightSection {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(-50%, -50%);
					display: flex;
					flex-direction: column;
					gap: 4rem;
					@media (max-width: 930px) and (min-height: 600px) {
						position: relative;
						top: 50%;
						left: 50%;
						flex-direction: row;
					}
					.posts,
					.subscribers {
						h3 {
							color: #fff;
							font-family: Poppins;
							font-size: 3.667rem;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							@media (max-width: 930px) and (min-height: 600px) {
								font-size: 1.7755rem;
							}
						}
						p {
							color: #736d6d;
							font-family: Poppins;
							font-size: 2.047rem;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							@media (max-width: 930px) and (min-height: 600px) {
								font-size: 0.99113rem;
							}
						}
					}
					.RatingContainer {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 7rem;
						height: 7rem;
						cursor: pointer;
						@media (max-width: 930px) and (min-height: 600px) {
							width: 3.99925rem;
							height: 3.99925rem;
							flex-shrink: 0;
						}

						img {
							width: 100%;
							height: 100%;
						}

						p {
							color: #fff;
							font-family: Poppins;
							font-size: 1.31556rem;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							position: absolute;
							@media (max-width: 930px) and (min-height: 600px) {
								color: #fff;
								leading-trim: both;
								text-edge: cap;
								font-family: Poppins;
								font-size: 0.71819rem;
							}
						}
					}
				}
			}
		}

		.bottomSection {
			height: 12.8%;
			width: 100%;
			display: flex;
			align-items: center;
			background: rgba(33, 33, 33, 0.92);
			backdrop-filter: blur(5px);
			padding: 0.2rem;
			@media (max-width: 930px) and (min-height: 600px) {
				height: 9.51vh;
			}

			img {
				height: 7vh;
				object-fit: contain;
				margin-right: 3vw;
				@media (max-width: 930px) and (min-height: 600px) {
					margin-right: 5vw;
					height: 5vh;
				}
			}
		}
	}

	.Container2 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;

		@media (max-width: 930px) and (min-height: 600px) {
			flex-direction: column;
		}

		.leftSection {
			width: 55%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			@media (max-width: 930px) and (min-height: 600px) {
				height: 50%;
				width: 100%;
				align-items: flex-start;
				justify-content: flex-start;
				padding-top: 3rem;
				padding-left: 1.5rem;
			}
			.frame {
				width: 33.36244rem;
				height: 48.07681rem;
				flex-shrink: 0;
				background: radial-gradient(50% 50% at 50% 50%, #4788e9 0%, #6037d4 100%);
				@media (max-width: 930px) and (min-height: 600px) {
					width: 15.8165rem;
					height: 22.79231rem;
				}
			}
			.heroImage {
				width: 50.91781rem;
				height: 45.275rem;
				flex-shrink: 0;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-47%);
				@media (max-width: 930px) and (min-height: 600px) {
					width: 24.13913rem;
					height: 21.464rem;
					left: 1rem;
				}
				img {
					height: 100%;
					width: 100%;
				}
				.aboutusChangeIcon {
					height: 4rem;
					width: 4rem;
					cursor: pointer;
					position: absolute;
					top: 1rem;
					left: 4rem;
					@media (max-width: 930px) and (min-height: 600px) {
						height: 3rem;
						width: 3rem;
						left: 1rem;
					}
				}
			}
		}
		.rightSection {
			width: 45%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 3rem;
			padding-left: 0.3rem;
			position: relative;
			@media (max-width: 930px) and (min-height: 600px) {
				height: 50%;
				width: 100%;
				padding-left: 2rem;
			}
			.EditTextIcon {
				height: 4rem;
				width: 4rem;
				position: absolute;
				right: 4rem;
				top: 21rem;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 3rem;
					height: 3rem;
					right: 1rem;
					top: 4rem;
				}
			}
			h2 {
				width: 40.06675rem;
				color: #fff;
				font-family: Poppins;
				font-size: 5.42638rem;
				font-style: normal;
				font-weight: 600;
				line-height: 103%; /* 5.58919rem */
				letter-spacing: 0.21706rem;
				@media (max-width: 930px) and (min-height: 600px) {
					color: #fff;
					font-family: Poppins;
					font-size: 2.125rem;
					font-style: normal;
					font-weight: 600;
					line-height: 103%; /* 2.18875rem */
					letter-spacing: 0.085rem;
				}
			}
			.aboutusText {
				width: 36.37675rem;
				height: 22.49231rem;
				flex-shrink: 0;
				color: #e9e9e9;
				font-family: Poppins;
				font-size: 1.4375rem;
				font-style: normal;
				font-weight: 300;
				line-height: 208.5%; /* 2.99719rem */
				letter-spacing: 0.38094rem;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 20.42525rem;
					height: 12.62925rem;
					color: #e9e9e9;
					font-family: Poppins;
					font-size: 0.875rem;
					font-style: normal;
					font-weight: 300;
					line-height: 208.5%; /* 1.82438rem */
					letter-spacing: 0.23188rem;
				}
			}
			button {
				margin-top: 2rem;
				display: inline-flex;
				padding: 1.77281rem 3.93963rem;
				justify-content: center;
				align-items: center;
				gap: 0.98488rem;
				border-radius: 0.62775rem;
				border: 4.728px solid #c9b7ff;
				background: rgba(96, 55, 213, 0.4);
				color: #c9b7ff;
				font-family: Poppins;
				font-size: 1.37888rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				width: max-content;
				align-self: center;
				@media (max-width: 930px) and (min-height: 600px) {
					padding: 0.99544rem 2.21206rem;
					gap: 0.553rem;
					border-radius: 0.3525rem;
					border: 2.654px solid #c9b7ff;
				}
			}
		}
	}
	.Container3 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		background: #5d41d7;
		@media (max-width: 930px) and (min-height: 600px) {
			flex-direction: column-reverse;
		}
		.leftSection {
			width: 40%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 6rem;
			gap: 3rem;
			position: relative;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 100%;
				height: 60%;
				padding-left: 3rem;
			}
			.EditTextIcon {
				height: 4rem;
				width: 4rem;
				position: absolute;
				right: 2rem;
				top: 28rem;
				@media (max-width: 930px) and (min-height: 600px) {
					height: 3rem;
					width: 3rem;
					top: 12rem;
				}
			}

			h2 {
				width: 27.25294rem;
				color: #fff;
				font-family: Poppins;
				font-size: 5.42638rem;
				font-style: normal;
				font-weight: 600;
				line-height: 103%; /* 5.58919rem */
				letter-spacing: 0.21706rem;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 11.51044rem;
					font-size: 2.875rem;
					letter-spacing: 0.115rem;
				}
			}
			.chooseusText {
				width: 32.67563rem;
				height: 22.49231rem;
				color: #e9e9e9;
				font-family: Poppins;
				font-size: 1.4375rem;
				font-style: normal;
				font-weight: 400;
				line-height: 208.5%; /* 2.99719rem */
				letter-spacing: 0.38094rem;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 19.46456rem;
					height: 13.39844rem;
					font-size: 0.875rem;
					letter-spacing: 0.23188rem;
				}
			}
		}
		.rightSection {
			width: 60%;
			height: 100%;
			display: flex;
			justify-content: center;
			position: relative;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 100%;
				height: 40%;
			}
			.frame {
				width: 39.78744rem;
				height: 60.24281rem;
				max-height: 90vh;
				flex-shrink: 0;
				background: url("../../assets/business/image/temp4chooseusbg.png");
				background-size: cover;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 20.84744rem;
					height: 20.37319rem;
					flex-shrink: 0;
				}
			}
			.heroImage {
				position: absolute;
				top: 0;
				width: 100%;
				height: 60.24281rem;
				max-height: 90vh;
				.chooseusChangeIcon {
					height: 4rem;
					width: 4rem;
					cursor: pointer;
					position: absolute;
					top: 2rem;
					right: 2rem;
					@media (max-width: 930px) and (min-height: 600px) {
						top: 1rem;
						right: 4rem;
						width: 3rem;
						height: 3rem;
					}
				}
				@media (max-width: 930px) and (min-height: 600px) {
					width: 100%;
					height: 20.37319rem;
					flex-shrink: 0;
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.Container4 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 3rem 6rem;
			margin-bottom: 2rem;
			@media (max-width: 930px) and (min-height: 600px) {
				flex-direction: column;
				align-items: start;
				gap: 2rem;
				padding: 3rem 2rem;
			}
			.ourProducts {
				color: #fff;
				font-family: Poppins;
				font-size: 3.875rem;
				font-style: normal;
				font-weight: 600;
				line-height: 103%; /* 3.99125rem */
				letter-spacing: 0.155rem;
				@media (max-width: 930px) and (min-height: 600px) {
					font-size: 2.875rem;
					letter-spacing: 0.115rem;
				}
			}
			.viewAll {
				color: rgba(255, 255, 255, 0.6);
				font-family: Poppins;
				font-size: 1.5rem;
				font-style: normal;
				font-weight: 600;
				line-height: 103%; /* 1.545rem */
				letter-spacing: 0.06rem;
				cursor: pointer;
				@media (max-width: 930px) and (min-height: 600px) {
					font-size: 1.5rem;
					letter-spacing: 0.06rem;
				}
			}
		}
		.products {
			flex-grow: 1;
			display: flex;
			align-items: center;
			justify-content: space-around;

			@media (max-width: 930px) and (min-height: 600px) {
				flex-wrap: wrap;
				padding-bottom: 6rem;
			}

			.productCard {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;

				img {
					width: 27.5725rem;
					height: 28.8385rem;
					flex-shrink: 0;
				}
				h3 {
					color: #fff;
					font-family: Poppins;
					font-size: 2.625rem;
					font-style: normal;
					font-weight: 600;
					line-height: 108%; /* 2.835rem */
				}

				.actions {
					display: flex;
					gap: 0.8rem;
					.like {
						width: 2.87269rem;
						height: 2.737rem;
					}
					.comment {
						width: 3.02388rem;
						height: 2.58144rem;
					}
					.share {
						width: 3.77513rem;
						height: 2.22631rem;
					}
					.like,
					.comment,
					.share {
						img {
							height: 100%;
							width: 100%;
							cursor: pointer;
						}
					}
				}
			}

			.productCardMob {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				img {
					width: 10.81238rem;
					height: 11.30881rem;
					flex-shrink: 0;
				}
				h3 {
					color: #fff;
					font-family: Poppins;
					font-size: 1.02938rem;
					font-style: normal;
					font-weight: 600;
					line-height: 108%; /* 1.11175rem */
				}

				.actions {
					display: flex;
					gap: 0.8rem;
					.like {
						width: 1.1265rem;
						height: 1.07331rem;
					}
					.comment {
						width: 1.18581rem;
						height: 1.01231rem;
					}
					.share {
						width: 1.48038rem;
						height: 0.87306rem;
					}
					.like,
					.comment,
					.share {
						img {
							height: 100%;
							width: 100%;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	.Container5 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		@media (max-width: 930px) and (min-height: 600px) {
			justify-content: space-around;
		}

		.topSection {
			display: flex;
			justify-content: space-between;
			padding: 0 6vw;

			@media (max-width: 930px) and (min-height: 600px) {
				flex-direction: column;
				justify-content: flex-start;
				gap: 2vh;
				padding: 0.1vh 2vw;
			}

			h2 {
				color: #fff;
				font-family: Poppins;
				font-size: 3.875rem;
				font-style: normal;
				font-weight: 600;
				line-height: 103%; /* 3.99125rem */
				letter-spacing: 0.155rem;
				@media (max-width: 930px) and (min-height: 600px) {
					font-size: 2.875rem;
				}
			}

			p {
				color: rgba(255, 255, 255, 0.6);
				font-family: Poppins;
				font-size: 1.5rem;
				font-style: normal;
				font-weight: 600;
				line-height: 103%; /* 1.545rem */
				letter-spacing: 0.06rem;
			}
		}
		.carousel {
			width: 90%;
			margin-left: 10vw;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 100%;
				margin-left: 1.2vw;
			}
		}

		.thankYou {
			color: #fff;
			text-align: center;
			font-family: Calligraffitti;
			font-size: 3.73438rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 6.05vw;
			}
		}
	}

	.postMainContainer {
		width: 95%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 1;

		@media (max-width: 930px) and (min-height: 600px) {
			// width: 97.39vw;
			height: 64.62vh;
		}

		.media {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.profile {
			display: flex;
			align-items: center;
			gap: 1vw;
			padding: 1vh 2vw;
			img {
				width: 2.97vw;
				aspect-ratio: 1/1;
				border-radius: 50%;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 8.39vw;
					aspect-ratio: 1/1;
				}
			}
			.profileDetails {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-around;
				h4 {
					color: #000;
					font-family: Poppins;
					font-size: 0.908vw;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					@media (max-width: 930px) and (min-height: 600px) {
						font-size: 2.56vw;
					}
				}
				h5 {
					color: #000;
					font-family: Poppins;
					font-size: 0.495vw;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (max-width: 930px) and (min-height: 600px) {
						font-size: 1.4vw;
					}
				}
			}
		}

		.details {
			width: 100%;
			height: 7.78vh;
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(0.216vw);
			display: flex;
			align-items: center;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 96.97vw;
				height: 7.62vh;
				backdrop-filter: blur(0.36vw);
			}
			p {
				padding: 0 1vw;
				width: 25.567vw;
				height: 7.03vh;
				color: #fff;
				font-family: Poppins;
				font-size: 1.34vw;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 41.89vw;
					height: 2.98vh;
					font-size: 2.2vw;
				}
			}
		}
	}

	.mobileCarousel {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		.activeItem {
			width: 22.33rem;
			height: 19.85988rem;
			flex-shrink: 0;
			border: 0.527px solid #2169d6;
			background: lightgray 50% / cover no-repeat, #c4c4c4;
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.backImg {
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: -1;
				img {
					height: 100%;
					width: 100%;
				}
			}

			.profile {
				display: flex;
				gap: 0.3rem;
				padding: 1rem;
				img {
					width: 1.58088rem;
					height: 1.58088rem;
					flex-shrink: 0;
					border-radius: 1.58088rem;
				}
				.nameDetail {
					display: flex;
					flex-direction: column;
					h3 {
						color: #fff;
						font-family: Poppins;
						font-size: 0.48306rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
					p {
						color: #fff;
						font-family: Poppins;
						font-size: 0.2635rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}

			.details {
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: rgba(255, 255, 255, 0.2);
				backdrop-filter: blur(1.8443654775619507px);
				padding: 0 1rem;
				.desc {
					width: 11.60219rem;
					height: 1.73194rem;
					flex-shrink: 0;
					color: #fff;
					font-family: Poppins;
					font-size: 0.71369rem;
					font-style: normal;
					font-weight: 400;
					line-height: 123.4%; /* 0.88063rem */
				}

				.actions {
					display: flex;
					align-items: center;
					gap: 1rem;
					.like,
					.comment,
					.share {
						display: flex;
						align-items: center;
						p {
							color: #fff;
							font-family: Poppins;
							font-size: 0.70063rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%; /* 0.86456rem */
						}
					}
					.like {
						img {
							width: 0.70419rem;
							height: 0.67094rem;
						}
					}
					.comment {
						img {
							width: 1.02388rem;
							height: 0.87394rem;
						}
					}
					.share {
						img {
							width: 1.28013rem;
							height: 0.75494rem;
						}
					}
				}
			}
		}

		.allItems {
			display: flex;
			align-items: flex-start;
			width: 86vw;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				display: none;
			}
			gap: 3vw;
			.postCards {
				width: 10.91594rem;
				height: 13.06013rem;
				flex-shrink: 0;
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), lightgray 50% / cover no-repeat;
				position: relative;
				z-index: 1;

				.backImage {
					width: 100%;
					height: 100%;
					position: absolute;
					z-index: -1;
					img {
						height: 100%;
						width: 100%;
					}
				}
				.profile {
					display: flex;
					gap: 0.3rem;
					padding: 1rem;
					img {
						width: 1.03963rem;
						height: 1.03963rem;
						flex-shrink: 0;
						border-radius: 1.03963rem;
					}
					.nameDetail {
						display: flex;
						flex-direction: column;
						h3 {
							color: #fff;
							font-family: Poppins;
							font-size: 0.31769rem;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
						p {
							color: #fff;
							font-family: Poppins;
							font-size: 0.17325rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
				}
			}
		}
	}
}
