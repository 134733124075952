html {
	&::-webkit-scrollbar {
		display: none;
	}
}

.Template4Wrapper {
	height: 100vw;
	height: 100vh;
	scrollbar-width: none;
	background: var(--frsd, radial-gradient(50% 50% at 50% 50%, #0e2138 0%, #0a1b30 100%));
	scroll-behavior: smooth;
	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template4 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		position: relative;
		background: #fff;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.container1 {
	position: relative;
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	position: relative;
	background: #222321;

	.coverChangeIcon {
		position: absolute;
		top: 2rem;
		right: 4rem;
		height: 3.5rem;
		width: 3.5rem;
	}

	.coverImage {
		height: 100%;
		width: 100%;
		position: relative;

		&::after {
			content: "";
			width: 7rem;
			aspect-ratio: 1/1;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			border-top: 5px solid #ff2727;
			border-right: 5px solid #ff2727;
			@media (max-width: 1410px) {
				@media (max-height: 570px) {
					width: 4rem;
				}
			}
		}
		&::before {
			content: "";
			width: 7rem;
			aspect-ratio: 1/1;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			border-bottom: 5px solid #ff2727;
			border-left: 5px solid #ff2727;
			@media (max-width: 1410px) {
				@media (max-height: 580px) {
					width: 4rem;
				}
			}
		}
	}

	.coverImage img {
		height: 100%;
		width: 100%;
		// object-fit: cover;
	}

	.coverFrame {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
	}

	.coverFrame img {
		height: 100%;
		width: 100%;
	}

	.buttons {
		bottom: -5rem;
		right: -4rem;
		position: absolute;
		width: 36.84119rem;
		max-width: 30vw;
		aspect-ratio: 1/1;
		border-radius: 50%;
		background: #fff;
		display: flex;
		flex-direction: column;
		gap: 4rem;
		align-items: center;
		justify-content: center;

		@media (max-width: 930px) and (min-height: 600px) {
			min-width: 70vw;
		}
	}

	.buttons button {
		color: #010101;
		font-family: Poppins;
		font-size: 2.932rem;
		font-style: italic;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.29319rem;
		text-transform: uppercase;
		border: none;
		background: transparent;
		position: relative;
		cursor: pointer;

		&:first-child::after {
			content: "";
			position: absolute;
			bottom: -25%;
			left: 10%;
			width: 50%;
			border: 2px solid #000;
		}
		// @media (max-width: 300px) {
		//   font-size: 1.6rem;
		// }
	}

	.followers {
		bottom: 13%;
		right: 23%;
		position: absolute;
		width: 18.28538rem;
		max-width: 15vw;
		aspect-ratio: 1/1;
		border-radius: 50%;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media (max-width: 1410px) {
			@media (max-height: 570px) {
				right: 23%;
				bottom: 14%;
			}
		}

		// @media (max-width: 850px) {
		//   min-width: 25rem;
		//   right: 32%;
		//   bottom: 5%;
		// }
		@media (max-width: 930px) and (min-height: 600px) {
			min-width: 30vw;
			bottom: 5%;
			right: 54%;
		}

		.followerCount {
			color: #010101;
			font-family: Poppins;
			font-size: 4.31813rem;
			font-style: normal;
			font-weight: 800;
			line-height: normal;
			letter-spacing: 0.43181rem;
			text-transform: uppercase;
		}

		.textFollower {
			color: #010101;
			font-family: Poppins;
			font-size: 1.37931rem;
			font-style: italic;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.13794rem;
			text-transform: uppercase;
		}
	}

	.nameIcon {
		position: absolute;
		top: 6%;
		width: 11.04031rem;
		height: 56.58244rem;
		min-height: 75vh;
		max-height: 82vh;
		max-width: 9rem;
		border-radius: 0rem 3.75rem 0rem 0rem;
		background: #fff;
		display: flex;
		flex-direction: column;
		z-index: 2;

		@media (max-width: 930px) and (min-height: 600px) {
			top: 17%;
			// width: 6.56613rem;
			color: red;
			height: 44.01763rem;
			min-height: 70vh;
			width: 20vw;
			max-width: 24rem;
			max-height: 70vh;
			border-radius: 0rem 3.28306rem 0rem 0rem;
		}

		.name {
			height: 80%;
			width: calc(85vh - 80%);
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.coverName {
				height: fit-content;
				width: 35rem;
				// min-width: 90%;
				min-width: 60vh;
				max-width: 68vh;
				padding: 1rem 0.2rem;
				color: #000;
				font-family: Poppins;
				// font-size: 4.85406rem;
				font-size: 2vh;
				font-style: normal;
				font-weight: 500;
				line-height: 100%;
				transform: rotate(270deg);
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				white-space: nowrap;
				overflow: scroll;
				// border: 1px solid red;

				@media (max-width: 1280px) {
					@media (max-height: 577px) {
						// max-height: 7vw;
					}
				}
				@media (max-width: 950px) {
					font-size: 4.726rem !important;
				}
				@media (max-width: 930px) and (min-height: 600px) {
					min-width: 55vh;
					font-size: 5rem !important;
				}
				@media (max-width: 380px) {
					min-width: 47vh;
				}
				@media (max-width: 380px) and (max-height: 680px) {
					min-width: 47vh;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}

			// .coverName textarea {}
		}

		.icon {
			height: 20%;
			width: 100%;
			// background-color: rgb(146, 123, 20);
			display: flex;
			align-items: center;
			justify-content: center;

			.iconContainer {
				width: 5.44188rem;
				min-width: 6.7vw;
				max-width: 7vh;
				aspect-ratio: 1/1;
				border-radius: 50%;
				fill: linear-gradient(180deg, #2f2f2f 0%, rgba(53, 52, 52, 0) 100%);
				backdrop-filter: blur(2.796437978744507px);
				background-image: url("../../assets/images/rating.png");
				background-size: cover;
				background-position: center;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;

				font-family: Poppins;
				font-size: 0.97725rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 7.44rem;
				}
			}
		}
	}
}

.container2 {
	position: relative;
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	.introImage {
		height: 100vh;
		width: 100vw;
		position: relative;
		transform: rotate(-16deg) scaleX(1.32);
		img {
			height: 100%;
			width: 100%;
		}
		.introFrame {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			img {
				height: 100%;
				width: 100%;
			}
		}

		@media (max-width: 930px) and (min-height: 600px) {
			height: 80%;
			top: -6%;
			transform: rotate(-16deg) scaleX(1.62);
		}
		.introChangeIcon {
			position: absolute;
			top: 16rem;
			right: 25rem;
			transform: rotate(16deg);
			height: 3.5rem;
			width: 3.5rem;
			@media (max-width: 930px) and (min-height: 600px) {
				top: 5rem;
				right: 5rem;
			}
			img {
				border-radius: 0;
			}
		}
	}
	.myIntro {
		position: absolute;
		left: 2rem;
		top: 4rem;
		z-index: 2;
		color: #010101;
		font-family: Poppins;
		font-size: 3.98863rem;
		font-style: italic;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.39888rem;
		text-transform: uppercase;
		transform: rotate(-16deg);
		@media (max-width: 930px) and (min-height: 600px) {
			top: 2rem;
			// font-size: 1.5rem;
			font-size: 2.3rem;
			letter-spacing: 0.15rem;
		}
	}
	.buttonWrapper {
		position: absolute;
		bottom: 2rem;
		right: 4rem;
		display: flex;
		flex-direction: column;
		gap: 0.3rem;
		@media (max-width: 930px) and (min-height: 600px) {
			bottom: 4rem;
		}
		button {
			color: #010101;
			font-family: Poppins;
			font-size: 1.91088rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.19106rem;
			text-transform: uppercase;
			background-color: transparent;
			cursor: pointer;
			border: none;
			&:nth-child(2) {
				margin-left: 10rem;
			}
		}
	}
	.descWrapper {
		background: rgba(12, 12, 12, 0.33);
		backdrop-filter: blur(6.847777843475342px);
		width: 164.74894rem;
		height: 12.07075rem;
		position: absolute;
		bottom: -1rem;
		transform: rotate(-16deg);
		display: flex;
		align-items: center;
		@media (max-width: 930px) and (min-height: 600px) {
			width: 120vw;
			bottom: 13rem;
		}
		.EditTextIcon {
			position: absolute;
			left: 40%;
			bottom: 60%;
			height: 3.5rem;
			width: 3.5rem;
			@media (max-width: 930px) and (min-height: 600px) {
				left: 15%;
				bottom: 80%;
			}
		}
		.desc {
			color: #fff;
			font-family: Poppins;
			font-size: 1.375rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			width: 55.18331rem;
			max-height: 10rem;
			overflow-y: scroll;
			position: relative;
			left: 46%;
			&::-webkit-scrollbar {
				display: none;
			}
			@media (max-width: 930px) and (min-height: 600px) {
				// font-size: 0.875rem;
				font-size: 1.1875rem;
				max-width: 90vw;
				left: 10%;
			}
		}
	}
}

.container3 {
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	position: relative;

	.int1ChangeIcon {
		position: absolute;
		top: 2rem;
		left: 4rem;
		height: 3.5rem;
		width: 3.5rem;
	}

	.int2ChangeIcon {
		position: absolute;
		top: 2rem;
		right: 4rem;
		height: 3.5rem;
		width: 3.5rem;
		@media (max-width: 930px) and (min-height: 600px) {
			top: 45%;
		}
	}

	.int3ChangeIcon {
		position: absolute;
		top: 50%;
		right: 4rem;
		height: 3.5rem;
		width: 3.5rem;
		@media (max-width: 930px) and (min-height: 600px) {
			top: 90%;
			right: 3rem;
		}
	}

	.image_container {
		height: 100%;
		width: 100%;
		display: flex;
		position: absolute;

		.coverImage1 {
			height: 100%;
			width: 100%;
			position: absolute;
			clip-path: polygon(0 0, 0 68%, 68% 0);

			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(0 0, 100% 0, 100% 25%, 0 43%);
			}
		}

		.coverImage1 img {
			position: absolute;
			height: 70%;
			width: 68%;

			// object-fit: cover;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 100%;
				height: 44%;
			}
		}

		.coverImage2 {
			height: 100%;
			width: 100%;
			position: absolute;
			// clip-path: polygon(42% 0, 83% 0, 61% 100%, 16% 100%);
			clip-path: polygon(70% 0, 100% 0, 100% 17%, 18% 100%, 0 100%, 0 70%);
			background: #b8d3dc;

			@media (max-width: 930px) and (min-height: 600px) {
				// clip-path: polygon(62% 0, 100% 0%, 100% 16%, 41% 100%, 0% 100%, 0% 85%);
				clip-path: polygon(0 45%, 100% 27%, 100% 57%, 0 75%);
			}
		}

		.coverImage2 img {
			position: absolute;
			height: 100%;
			width: 100%;

			@media (max-width: 930px) and (min-height: 600px) {
				left: 0;
				width: 100%;
				top: 24.5%;
				height: 51%;
				background-color: black;
			}
		}

		.coverImage3 {
			height: 100%;
			width: 100%;
			position: absolute;
			bottom: 0;
			// clip-path: polygon(83% 0, 100% 0, 100% 100%, 61% 100%);
			clip-path: polygon(100% 20%, 20% 100%, 100% 100%);

			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(0 77%, 100% 59%, 100% 100%, 0 100%);
			}
		}

		.coverImage3 img {
			position: absolute;
			right: 0;
			bottom: 0;
			height: 80%;
			width: 80%;

			@media (max-width: 930px) and (min-height: 600px) {
				width: 100%;
				height: 42%;
			}
		}
	}
}

.container4 {
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	// background-color: rgb(28, 165, 184);
	

	.headerSection {
		height: 10%;
		width: 100%;
		padding: 0 5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// background-color: blue;

		.myGallery {
			color: #000;
			font-family: Poppins;
			font-size: 2.625rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.viewAll {
			color: #6d6d6d;
			font-family: Poppins;
			font-size: 1.625rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}

	.carouselSection {
		height: 65%;
		width: 100%;

		.activeContainer {
			width: 95.70431rem;
			max-width: 50vw;
			height: 55.35088rem;
			border-radius: 2.57763rem;
			max-height: 50vh;
			min-height: 50vh;
			// aspect-ratio: 16/9;
			margin: auto;
			position: relative;

			@media (max-width: 930px) and (min-height: 600px) {
				min-height: 58vh;
				min-width: 90vw;
			}
			@media (max-width: 400px) {
				max-height: 55vh;
			}

			.imageSection {
				height: 100%;
				width: 100%;
				background-color: #010101;
				border-radius: 2.57763rem;
			}

			.imageSection img {
				border-radius: 2.57763rem;
				height: 100%;
				width: 100%;
				object-fit: contain;
			}

			.below {
				position: absolute;
				bottom: 0;
				width: 100%;
				display: flex;
				justify-content: space-between;
				background-color: rgba(0, 0, 0, 0.2);
				border-radius: 0 0 2.57763rem 2.57763rem;

				.desc {
					margin: auto;
					margin-left: 2rem;
					width: 25.64925rem;
					max-width: 40%;
					height: 4.34688rem;
					color: #fff;
					font-family: Poppins;
					font-size: 1.79113rem;
					font-style: normal;
					font-weight: 400;
					line-height: 123.4%;
					overflow: hidden;
					// border: 1px solid red;
				}
				.like {
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 4.5rem;
						height: 4.5rem;
					}
					p {
						width: 3.2rem;
						height: 3.2rem;
						color: #fff;
						font-family: "Poppins";
						font-size: 2.46006rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 1.80175rem */
					}
				}
				// .LikeIcon {
				// }

				.icons {
					display: flex;
					gap: 2rem;
					padding-right: 1rem;

					@media (max-width: 930px) and (min-height: 600px) {
						gap: 1rem;
					}

					.Icon {
						margin-top: 0.4rem;
						height: 5.8rem;
						width: 6.4rem;
						display: flex;
						align-items: center;
						gap: 0.31rem;
						cursor: pointer;

						img {
							width: 100%;
							height: 100%;
						}

						p {
							width: 3.2rem;
							height: 3.2rem;
							color: #fff;
							font-family: "Poppins";
							font-size: 2.46006rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%; /* 1.80175rem */
						}
					}

					.comment {
						display: flex;
						align-items: center;
						justify-content: center;

						.commentIcon {
							width: 4.132rem;
							height: 5.185675rem;
							// background-color: red;
						}

						.commentIcon img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}

						.commentCount {
							color: #fff;
							font-family: Poppins;
							font-size: 2.983rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%;
						}
					}

					.shareIcon {
						width: 4rem;
						height: 6.285675rem;
						// background-color: red;
					}

					.shareIcon img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

	.footer {
		height: 25%;
		width: max-content;
		// background-color: red;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-evenly;
		position: relative;
		margin: auto;

		.EditTextIcon {
			height: 3.5rem;
			width: 3.5rem;
			z-index: 5;
			position: absolute;
			top: 0;
			left: -5rem;
			@media (max-width: 930px) and (min-height: 600px) {
				bottom: 92%;
				left: 17%;
			}
		}
		.thankDesc {
			width: 48.51713rem;
			max-width: 70vw;
			height: 60%;
			max-height: 60%;
			color: #000;
			text-align: center;
			font-family: Poppins;
			font-size: 1.375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 172%;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.thanku {
			color: #000;
			font-family: Playball;
			font-size: 2rem;
			font-style: normal;
			font-weight: 400;
			line-height: 94.5%;
			/* 1.89rem */
		}
	}
}
