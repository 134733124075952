#carouselB7 {
	.alice-carousel__wrapper {
		.alice-carousel__stage-item {
			transition: all ease 0.3s;
			#details {
				display: none;
			}
			#postcard {
				width: 95%;
				margin-right: auto;
			}
		}
		.__target {
			flex-shrink: 0;
			width: 42% !important;
			#postcard {
				width: 97%;
			}
			#details {
				display: flex;
			}
		}

        

	}

	.alice-carousel__prev-btn {
		display: none;
	}

	.alice-carousel__next-btn {
		display: none;
	}

	.alice-carousel__dots {
		display: none;
	}
}
