#carousel1 {
	.alice-carousel__wrapper {
		// .alice-carousel__stage {
		// 	margin-top: 2vh;
		// 	position: relative;
		// }

		// .alice-carousel__stage-item {
		// 	width: 25.83vw;
		// 	height: 47.36vh;
		// 	flex-shrink: 0;
		// 	transition: all ease 0.3s;
		// }
		// .__target {
		// 	width: 28.06vw;
		// 	height: 56.74vh;
		// 	flex-shrink: 0;
		// }
        .alice-carousel__stage-item {
			width: 23.25rem;
			height: 30.375rem;
			flex-shrink: 0;
			transition: all ease 0.3s;
		}
		.__target {
			width: 25.25rem;
			height: 36.375rem;
			flex-shrink: 0;
		}
	}

	.alice-carousel__prev-btn {
		display: none;
	}

	.alice-carousel__next-btn {
		display: none;
		// color: E89F71;
		// position: absolute;
		// right: 2vw;
		// top: 50%;
		// width: 3.33vw;
		// height: 3.33vw;
		// background-color: #fff;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// border-radius: 50%;
	}

	.alice-carousel__dots {
		display:none;
		// position: absolute;
		// right: 25vw;
		// bottom: 3vh;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// ul {
		// 	list-style-type: none;
		// 	padding: 0;
		// 	margin: 0;
		// }

		// li {
		// 	padding: 8px;
		// 	width: 0.76vw;
		// 	height: 0.76vw;
		// 	background-color: #f4f4f4;
		// 	border-radius: 4px;
		// 	background-image: url("../../assets/svg/list.svg");
		// 	background-size: cover;
		// }

		// .__active {
		// 	width: 1.88vw;
		// 	height: 1.88vw;
		// 	background-image: url("../../assets//svg/listActive.svg");
		// 	background-size: cover;
		// 	background-color: transparent;
		// }
	}
}

#carousel2{
    .alice-carousel__wrapper {
	width: 100%;
        .alice-carousel__stage-item {
			// width: 30rem;
			height: 30.375rem;
			flex-shrink: 0;
			transition: all ease 0.3s;
			#details{
				display: none;
			}
		}
		.__target {
			width: 45vw !important;
			height: 30.375rem;
			flex-shrink: 0;
			#details{
				display: block;
			}
		}
	}


	.alice-carousel__prev-btn {
		display: none;
	}

	.alice-carousel__next-btn{
		display: none;
	}

	.alice-carousel__dots {
		display: none;
	}
}