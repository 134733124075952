.Template8Wrapper {
	height: 100vw;
	height: 100vh;
	scrollbar-width: none;
	background: var(--frsd, radial-gradient(50% 50% at 50% 50%, #0e2138 0%, #0a1b30 100%));
	scroll-behavior: smooth;
	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 12;
	}

	.saveButton {
		position: absolute;
		top: 38%;
		left: 2.913%;
		z-index: 12;
		border: none;
		background: transparent;
		cursor: pointer;
		font-size: larger;
		color: #2f7a7a;
		font-weight: 700;
	}

	.Template8 {
		// width: 1728px;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.container1 {
	width: 100%;
	max-width: 100vw;
	// border: 2px solid red;
	overflow: hidden;
	height: 100vh;
	position: relative;
	z-index: 2;

	.background {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.background img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		filter: blur(7px);

		@media (max-width: 930px) and (min-height: 600px) {
		}
	}

	.cover {
		position: absolute;
		top: 0;
		left: 0;
		color: white;
		height: 100%;
		width: 100%;
		display: flex;

		.left {
			width: 30%;
			display: flex;
			align-items: center;
			justify-content: center;

			.name {
				transform: rotate(-90deg);
				white-space: nowrap;
				z-index: 2;
				@media (max-width: 930px) and (min-height: 600px) {
					margin-bottom: 32rem;
				}

				.sur {
					color: #fff;
					font-family: Noto Serif;
					font-size: 2.9965rem;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					margin-left: 3rem;
					@media (max-width: 930px) and (min-height: 600px) {
						font-size: 3.5rem;
					}
				}

				.full {
					color: #fff;
					font-family: Noto Serif;
					font-size: 5.02019rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					height: 7rem;
					width: 90vh;
					&::-webkit-scrollbar {
						display: none;
					}
					@media (max-width: 930px) and (min-height: 600px) {
						color: #fff;
						font-family: Noto Serif;
						// font-size: 5.69888rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}

		.right {
			width: 70%;
			//   background-color: beige;
			display: flex;
			align-items: center;
			justify-content: center;

			.hero {
				width: 46.23213rem;
				height: 56.934rem;
				max-height: 85vh;
				max-width: 40vw;
				position: relative;
				// border: 4px solid limegreen;

				.CoverImage {
					top: 5rem;
					right: 10rem;
				}

				@media (max-width: 930px) and (min-height: 600px) {
					height: 70vh;
					min-width: 82vw;
					left: -4.3rem;
				}
				.editImageIcon {
					position: absolute;
					top: 0;
					left: calc(100% - 6rem);
					filter: contrast(3);
					height: 3.5rem;
					width: 3.5rem;
				}

				.frame {
					position: absolute;
					bottom: -3.5rem;
					left: 25%;
					width: 24.35275rem;
					height: 66.49788rem;
					max-height: 95vh;
					max-width: 30vw;
					border: 3px solid yellow;
					display: flex;
					align-items: flex-end;
					justify-content: center;
					z-index: 2;
					border-radius: 0 7.13456rem 7.13456rem 7.13456rem;

					@media (max-width: 930px) and (min-height: 600px) {
						height: 80vh;
						min-width: 53vw;
						bottom: -8.4rem;
					}

					.inner {
						width: 24.33469rem;
						height: 9.44219rem;
						width: 100%;
						background-color: yellow;
						border-radius: 7.13456rem;
						color: #333;
						display: flex;
						align-items: center;
						justify-content: space-around;

						.buttons {
							display: flex;
							flex-direction: column;
							justify-content: space-evenly;
							align-items: flex-start;
							height: 70%;
							cursor: pointer;
							z-index: 5;

							.follow {
								color: #000;
								font-family: Poppins;
								font-size: 1.07581rem;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
								cursor: pointer;
								padding: 0.5rem;
								position: relative;

								&::after {
									content: "";
									position: absolute;
									width: 1.84625rem;
									bottom: 0;
									left: 10%;
									border: 1px solid #000;
								}
							}

							.message {
								color: #000;
								font-family: Poppins;
								font-size: 0.91138rem;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
								cursor: pointer;
								padding: 0.5rem;
							}
						}

						.buttons button {
							border: none;
							outline: none;
							background-color: transparent;
							cursor: pointer;
						}

						.followers {
							.count {
								color: #000;
								font-family: Poppins;
								font-size: 3.35581rem;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
								text-align: center;
							}

							.text {
								color: #000;
								font-family: Poppins;
								font-size: 1.07581rem;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
							}
						}
					}
				}
			}

			.hero img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.star {
			position: absolute;
			right: 6rem;
			bottom: 0;
			width: 10.82019rem;
			height: 10.82019rem;
			border-radius: 50%;
			background-color: #2f2f2f;
			z-index: 15;
			background-image: url("../../assets/images/rating.png");
			background-size: cover;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-family: Poppins;
			font-size: 2.30219rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			cursor: pointer;

			@media (max-width: 1410px) {
				@media (max-height: 577px) {
					right: 7rem;
				}
			}

			@media (max-width: 930px) and (min-height: 600px) {
				width: 3.98419rem;
				height: 3.98419rem;
				min-width: 18vw;
				min-height: 18vw;
				left: 5.7rem;
				bottom: 7.2rem;
			}
		}
	}
}

.container2 {
	width: 107.875rem;
	height: 70.33956rem;
	min-height: 100vh;
	max-height: 100vh;
	min-width: 100vw;
	max-width: 100%;
	background-color: #000;
	display: flex;
	z-index: 1;
	position: relative;

	@media (max-width: 930px) and (min-height: 600px) {
		flex-direction: column-reverse;
	}

	.left {
		width: 45%;
		max-width: 45vw;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 5rem;
		position: relative;

		@media (max-width: 930px) and (min-height: 600px) {
			height: 30%;
			min-width: 100vw;
		}

		.Text {
			width: max-content;
			height: max-content;
			position: relative;
			.EditTextIcon {
				height: 3.5rem;
				width: 3.5rem;
				position: absolute;
				top: -5rem;
				left: 5%;
				cursor: pointer;
				@media (max-width: 930px) and (min-height: 600px) {
					top: -4vh;
					left: -3vw;
				}
			}

			.desc {
				width: 40vw;
				max-width: 42vw;
				height: 48vh;
				max-height: 55vh;
				overflow: hidden;
				color: #fff;
				font-family: Poppins;
				font-size: 2.01063rem;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				text-align: center;
				&::-webkit-scrollbar {
					display: none;
				}

				// background-color: rgb(24, 182, 10);
				@media (max-width: 930px) and (min-height: 600px) {
					width: 23.1875rem;
					height: 10.5625rem;
					min-width: 85vw;
					min-height: 16vh;
					// background-color: #0e2138;
					text-align: center;
				}
			}
		}

		.buttons {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10rem;
		}
		.buttons button:first-child::before {
			content: "About me";
			position: absolute;
			bottom: -33%;
			color: #3e3e3e;
			transform: rotateX(180deg);
			filter: blur(2.2029826641082764px);
		}
		.buttons button:last-child::before {
			content: "About me";
			position: absolute;
			bottom: -33%;
			color: #3e3e3e;
			transform: rotateX(180deg);
			filter: blur(2.2029826641082764px);
		}

		.buttons button {
			color: #fff;
			font-family: Poppins;
			font-size: 1.98125rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			background-color: transparent;
			border: none;
			border: 1px solid yellow;
			border-bottom: none;
			padding: 0.2rem 1rem;
			text-transform: uppercase;
		}
	}

	.right {
		// background-color: rgb(45, 20, 182);
		height: 100%;
		max-width: 55vw;
		z-index: 10;
		position: relative;
		// border: 7px solid seagreen;
		width: 100%;

		@media (max-width: 930px) and (min-height: 600px) {
			min-width: 100vw;
			height: 70%;
			max-height: 70vh;
			// background-color: red;
		}

		.hero {
			position: absolute;
			bottom: -10vh;
			left: 17%;
			width: 33.773rem;
			height: 53.00794rem;
			max-height: 75vh;
			max-width: 33vw;
			z-index: 2;
			&::before {
				content: "my\Aintro";
				position: absolute;
				top: -40%;
				left: 50%;
				width: 38.95306rem;
				height: 42.52125rem;
				max-height: 60vh;
				border: 7.548px solid #ffaf14;
				display: flex;
				align-items: flex-start;
				z-index: -1;
				color: #fff;
				font-family: Poppins;
				font-size: 3.27181rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				text-transform: capitalize;
				white-space: pre-wrap;
				word-wrap: break-word;
				padding: 2rem 4rem;
				@media (max-width: 930px) and (min-height: 600px) {
					top: -38%;
				}
			}
			&::after {
				content: "M";
				position: absolute;
				top: -60%;
				height: 100%;
				width: 97%;
				left: 9%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #333;
				font-family: Poppins;
				font-size: 33.96806rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				text-transform: capitalize;
				z-index: -2;
				@media (max-width: 930px) and (min-height: 600px) {
					left: -2%;
				}
			}
			.triangle {
				position: absolute;
				bottom: 2%;
				right: 2%;
				z-index: 10;
				fill: rgba(0, 0, 0, 0.02);
				backdrop-filter: blur(16.89208984375px);
				width: 30.07481rem;
				height: 30.59038rem;
				clip-path: polygon(100% 0, 0% 100%, 100% 100%);
				@media (max-width: 930px) and (min-height: 600px) {
					height: 30vh;
					width: 70vw;
					bottom: 2vh;
					right: 5%;
				}
			}

			.IntroImage {
				top: 1rem;
				right: 1rem;
			}

			@media (max-width: 930px) and (min-height: 600px) {
				position: absolute;
				bottom: 0;
				min-height: 50vh;
				max-height: 50vh;
				min-width: 80vw;
				// border: 4px solid blue;
				transform: translateX(-5%) translateY(0);
			}

			// @media (max-width:450px){
			//   max-height: 53vh;
			//   transform: translateX(10%) translateY(-82%);
			// }
		}
		.hero img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
}

.container3 {
	// height: 70.33956rem;
	height: 100%;
	// width: 107.875rem;
	width: 100%;
	max-width: 100vw;
	max-height: 100vh;
	// overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	background-color: #000;

	.header {
		width: 23.02363rem;
		height: 17.56519rem;
		max-height: 15vh;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-evenly;
		margin-left: 5.6547vw;
		position: relative;
		// gap: 2rem;
		z-index: 4;
		&::before {
			content: "";
			position: absolute;
			top: -1.2rem;
			width: 18.417rem;
			height: 15.38738rem;
			max-height: 5vh;
			z-index: -1;
			border: 1px solid #ffaf14;
			border-bottom: 0;
		}

		// background-color: antiquewhite;
		@media (max-width: 930px) and (min-height: 600px) {
			margin: 2rem 0;
			width: 100%;
			max-height: 8vh;
			align-items: center;
		}

		.mygallery {
			color: #fff;
			font-family: Poppins;
			font-size: 4.30506rem;
			font-style: normal;
			font-weight: 600;
			line-height: 100%;
			white-space: nowrap;
			text-align: center;
			margin-left: 0;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 3.30506rem;
			}
		}

		.viewall {
			color: #fff;
			text-align: center;
			font-family: Poppins;
			font-size: 1.68006rem;
			font-style: normal;
			font-weight: 300;
			line-height: 100%;
			cursor: pointer;
			margin-top: 2rem;
			@media (max-width: 1440px) {
				@media (max-height: 580px) {
					margin-top: 1rem;
				}
			}
		}
	}

	.mobileView {
		// .activeSlide {}
		display: none;
		.smallContainer {
			display: flex;
			overflow-y: scroll;
			padding: 0 2rem;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		@media (max-width: 931px) and (min-height: 600px) {
			display: block;
		}
	}

	.desktopView {
		.alice-carousel__prev-btn {
			font-size: 4rem;
		}

		@media (max-width: 930px) and (min-height: 600px) {
			display: none;
		}
	}

	//mobile
	.activeContainer {
		width: 95.70431rem;
		max-width: 90vw;
		height: 55.35088rem;
		border-radius: 2.57763rem;
		max-height: 70vh;
		min-height: 70vh;
		margin: auto;
		position: relative;

		@media (max-width: 400px) {
			max-height: 55vh;
		}

		@media (max-width: 930px) and (min-height: 600px) {
			min-height: 58vh;
		}

		.imageSection {
			height: 100%;
			width: 100%;
		}

		.imageSection img {
			border-radius: 2.57763rem;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
		.imageSection video {
			border-radius: 2.57763rem;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.details {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.upper {
				display: flex;
				align-items: center;
				gap: 1.22rem;
				padding: 2.2rem;

				@media (max-width: 930px) and (min-height: 600px) {
					padding: 1.8rem;
				}

				.profileLogo {
					width: 5.97156rem;
					height: 5.97156rem;
					border-radius: 5.97156rem;
				}

				.profileLogo img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: 5.97156rem;
				}

				.nameUsername {
					.name {
						color: #fff;
						font-family: Poppins;
						font-size: 2.16688rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					.userName {
						color: #fff;
						font-family: Poppins;
						font-size: 1.14175rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}

			.below {
				width: 100%;
				display: flex;
				justify-content: space-between;
				background-color: rgba(0, 0, 0, 0.2);
				border-radius: 0 0 2.57763rem 2.57763rem;

				.desc {
					margin: auto;
					margin-left: 2rem;
					width: 25.64925rem;
					max-width: 40%;
					height: 4.34688rem;
					color: #fff;
					font-family: Poppins;
					font-size: 1.79113rem;
					font-style: normal;
					font-weight: 400;
					line-height: 123.4%;
					overflow: hidden;
					// border: 1px solid red;
				}

				.icons {
					display: flex;
					gap: 2rem;
					padding-right: 1rem;

					@media (max-width: 930px) and (min-height: 600px) {
						gap: 1rem;
					}

					.like {
						display: flex;
						align-items: center;
						justify-content: center;

						.likeIcon {
							// width: 4.32rem;
							height: 6.85675rem;
							display: flex;
							align-items: center;
							cursor: pointer;

							img {
								height: 100%;
								width: 100%;
								object-fit: cover;
							}

							.likeCount {
								color: #fff;
								font-family: Poppins;
								font-size: 2.983rem;
								font-style: normal;
								font-weight: 600;
								line-height: 123.4%;
							}
						}
					}

					.comment {
						display: flex;
						align-items: center;
						justify-content: center;

						.commentIcon {
							width: 4.32rem;
							height: 6.85675rem;
							// background-color: red;
						}

						.commentIcon img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}

						.commentCount {
							color: #fff;
							font-family: Poppins;
							font-size: 2.983rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%;
						}
					}

					.shareIcon {
						width: 4.32rem;
						height: 6.85675rem;
						// background-color: red;
					}

					.shareIcon img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

	//mobile other containers
	.cardContainer {
		width: 13.53394rem;
		height: 10.99963rem;
		border-radius: 1.25rem;
		min-width: 50vw;
		min-height: 20vh;
		padding: 2rem 1rem;

		@media (max-width: 400px) {
			min-width: 47vw;
			min-height: 23vh;
		}
	}

	.cardContainer img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 1.875rem;
	}
	.cardContainer video {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 1.875rem;
	}

	// .subSlide {
	//   width: 94.62225rem;
	//   max-width: 85vw;
	//   min-width: 85vw;
	//   height: 49.40894rem;
	//   max-height: 72vh;
	//   border-radius: 4.83125rem;

	//   @media(max-width:650px) {
	//     width: 13.53394rem;
	//     min-width: 60vw;
	//     min-height: 20vh;
	//     height: 10.99963rem;
	//     border-radius: 1.25rem;
	//     margin-left: 2rem;
	//   }

	// }

	// .subSlide img {
	//   height: 100%;
	//   width: 100%;
	//   object-fit: cover;
	//   border-radius: 4.83125rem;
	// }
}

.container4 {
	padding-bottom: 4.7rem;
	background-color: #000;
	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;

		@media (max-width: 650px) {
			max-height: 12%;
			transform: translateY(-5vh);
		}

		.shortMessage {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;

			.EditTextIcon {
				height: 3.5rem;
				width: 3.5rem;
				position: absolute;
				top: 10%;
				left: 2%;
				cursor: pointer;
			}

			.textShort {
				color: #fff;
				font-family: Poppins;
				font-size: 1.75019rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 3.3rem;
			}

			.shortDesc {
				width: 46.84544rem;
				height: 8.13581rem;
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 1.75019rem;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				overflow: scroll;
				margin-bottom: 4.2rem;
				border: none;

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}

		.thankYou {
			color: #fff;
			text-align: center;
			font-family: Poppins;
			font-size: 1.71231rem;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
		}
	}
}

// 650,580
// 750 change to mobile view
