.TemplateWrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template12 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		background: url("./assets/background.png"), lightgray 50% / cover no-repeat;
		mix-blend-mode: multiply;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	// background: radial-gradient(50% 50% at 50% 50%, #591a8a 0%, #0c6dff 100%);
	background: linear-gradient(to right, #1311afbb, #1311afbb);
	display: flex;
	@media (max-width: 930px) and (min-height: 600px) {
		flex-direction: column;
	}

	.leftSection {
		height: 100%;
		width: 60%;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 930px) and (min-height: 600px) {
			align-items: flex-end;
			padding-right: 0;
			height: 70%;
			width: 100%;
		}

		.hero {
			width: 26vw;
			height: 69.8vh;
			position: relative;
			z-index: 1;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 67.8vw;
				height: 62.9vh;
			}
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

			.coverChangeIcon {
				height: 3.5rem;
				aspect-ratio: 1/1;
				position: absolute;
				right: 1rem;
				top: 1rem;
			}
			.followers {
				width: 13.48031rem;
				// max-width:16.60vh;
				border-radius: 50%;
				aspect-ratio: 1/1;
				fill: rgba(255, 255, 255, 0.2);
				backdrop-filter: blur(12.324649810791016px);
				background-image: url("./assets/followerSvg.svg");
				background-size: cover;
				background-position: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 55%;
				right: -25%;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 26vw;
					top: 55%;
					right: 0%;
				}
				@media (max-width: 1420px) {
					@media (max-height: 580px) {
						width: 10rem;
						top: 55%;
						right: -17%;
					}
				}

				.rating {
					position: absolute;
					z-index: 10;
					bottom: -75%;
					left: 60%;
					width: 9.49056rem;
					aspect-ratio: 1/1;
					fill: linear-gradient(180deg, rgba(81, 81, 81, 0.16) 0%, rgba(53, 52, 52, 0) 100%);
					backdrop-filter: blur(4.876945495605469px);
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 15.7vw;
						bottom: -65%;
						left: 95%;
					}
					@media (max-width: 1420px) {
						@media (max-height: 580px) {
							width: 7.49056rem;
						}
					}

					span {
						position: absolute;
						color: #fff;
						font-family: "Poppins";
						font-size: 0.99213rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					img {
						width: 100%;
						height: 100%;
					}
				}

				.followerCount {
					color: #fff;
					font-family: Poppins;
					font-size: 3.86838rem;
					font-style: normal;
					font-weight: 700;
					line-height: 101.4%; /* 3.92256rem */
					@media (max-width: 1420px) {
						@media (max-height: 580px) {
							font-size: 2.86838rem;
						}
					}
				}

				.textFollower {
					color: #fff;
					font-family: Poppins;
					font-size: 1.28369rem;
					font-style: normal;
					font-weight: 400;
					line-height: 101.4%;
					@media (max-width: 1420px) {
						@media (max-height: 580px) {
							font-size: 0.86838rem;
						}
					}
				}
			}

			.overlay1 {
				position: absolute;
				z-index: -1;
				top: -9vh;
				right: -6vw;
				width: 21.82vw;
				max-width: 29.2vh;
				aspect-ratio: 1/1;
				border-radius: 23.61725rem;
				background: radial-gradient(50% 50% at 50% 50%, #fff 0%, rgba(255, 255, 255, 0.13) 100%);

				@media (max-width: 930px) and (min-height: 600px) {
					width: 47.84vw;
					max-width: 22vh;
					top: 2vh;
					right: -9vw;
				}
				@media (max-width: 1420px) {
					@media (max-height: 580px) {
						top: -15%;
						right: -16%;
					}
				}
			}
			.overlay2 {
				position: absolute;
				z-index: -1;
				width: 21.82vw;
				max-width: 29.2vh;
				aspect-ratio: 1/1;
				bottom: -9vh;
				left: -6vw;
				border-radius: 23.61725rem;
				background: radial-gradient(50% 50% at 50% 50%, #fff 0%, rgba(255, 255, 255, 0.13) 100%);
				@media (max-width: 930px) and (min-height: 600px) {
					width: 47.84vw;
					max-width: 22vh;
					bottom: 8vh;
					left: -9vw;
				}
				@media (max-width: 1420px) {
					@media (max-height: 580px) {
						bottom: -15%;
						left: -16%;
					}
				}
			}
		}
		.hero::before {
			content: "";
			width: 26vw;
			height: 21.4vh;
			z-index: -1;
			background: url("./assets/dotSvg.svg");
			background-size: 100% 100%;
			background-position: center;
			position: absolute;
			left: -13vw;
			top: -3vh;
			@media (max-width: 930px) and (min-height: 600px) {
				left: -9vw;
				top: 8vh;
			}
		}
		.hero::after {
			content: "";
			width: 26vw;
			height: 21.4vh;
			z-index: -1;
			background: url("./assets/dotSvg.svg");
			background-size: 100% 100%;
			background-position: center;
			position: absolute;
			right: -13vw;
			bottom: -3vh;
			@media (max-width: 930px) and (min-height: 600px) {
				right: -9vw;
				bottom: 2vh;
			}
		}
	}

	.rightSection {
		// background-color: rgb(66, 51, 150);
		height: 100%;
		width: 40%;
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
		align-items: center;
		justify-content: center;
		padding-right: 4rem;
		position: relative;
		@media (max-width: 930px) and (min-height: 600px) {
			padding-right: 0;
			height: 30%;
			width: 100%;
			gap: 0.21rem;
		}

		.iam {
			color: #fff;
			font-family: Poppins;
			font-size: 1.75981rem;
			font-style: normal;
			font-weight: 500;
			line-height: 99.5%;
		}
		.name {
			color: #fff;
			font-family: Poppins;
			font-size: 2.37638rem;
			font-style: normal;
			font-weight: 500;
			line-height: 174%;
		}
		.email {
			color: #fff;
			font-family: Poppins;
			font-size: 0.89744rem;
			font-style: normal;
			font-weight: 300;
			line-height: 174%;
		}

		.TextWrapper {
			position: relative;
			width: max-content;
			height: max-content;
			.EditTextIcon {
				height: 3.5rem;
				aspect-ratio: 1/1;
				position: absolute;
				left: -2rem;
				top: -4rem;
				@media (max-width: 1420px) {
					@media (max-height: 580px) {
						left: 7rem;
					}
				}
			}

			.coverText {
				color: #fff;
				width: 30.27263rem;
				max-width: 28vw;
				text-align: center;
				font-family: Poppins;
				font-size: 0.83806rem;
				font-style: normal;
				font-weight: 300;
				line-height: 174%; /* 1.45825rem */

				@media (max-width: 930px) and (min-height: 600px) {
					width: 83.8vw;
					max-width: 83.8vw;
					// border: 1px solid red;
				}
			}
		}
		.buttons {
			width: 70%;
			margin-top: 2rem;
			display: flex;
			justify-content: space-around;

			button {
				background-color: transparent;
				border: none;
				outline: none;
				cursor: pointer;
				&:nth-child(1) {
					position: relative;
					z-index: 1;
					&::before {
						content: "";
						position: absolute;
						left: -1.5rem;
						bottom: -0.4rem;
						z-index: -1;
						width: 2.92075rem;
						height: 2.81494rem;
						border-radius: 0.42944rem;
						background: rgba(255, 255, 255, 0.2);
						backdrop-filter: blur(11.452128410339355px);
					}
				}
			}

			.follow {
				color: #fff;
				font-family: Poppins;
				font-size: 2.30938rem;
				font-style: normal;
				font-weight: 400;
				line-height: 76.4%;
			}
			.message {
				color: #fff;
				font-family: Poppins;
				font-size: 1.58231rem;
				font-style: normal;
				font-weight: 400;
				line-height: 76.4%;
			}
		}
	}
}

.Container2 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	// background-color: rgb(15, 177, 50);
	background: radial-gradient(50% 50% at 50% 50%, #591a8a8e 0%, #1311afbb 100%);

	display: flex;
	@media (max-width: 930px) and (min-height: 600px) {
		flex-direction: column;
	}
	.leftSection {
		width: 50%;
		height: 100%;
		// background-color: rgb(30, 136, 74);
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 930px) and (min-height: 600px) {
			width: 100%;
			height: 45%;
		}
		.imgContainer {
			height: auto;
			width: auto;
			display: flex;
			position: relative;
			padding-right: 28%;
			padding-bottom: 10%;
			@media (max-width: 930px) and (min-height: 600px) {
				padding-top: 8%;
			}

			.introImg1 {
				width: 18.36vw;
				height: 47.2vh;
				position: relative;
				z-index: 1;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 42.3vw;
					height: 33.25vh;
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
				.intro1ChangeIcon {
					position: absolute;
					bottom: -1rem;
					left: -1rem;
				}
				&::before {
					content: "";
					width: 14.12vw;
					height: 34.68vh;
					z-index: -1;
					background: url("./assets/dotSvg.svg");
					background-size: 100% 100%;
					background-position: center;
					position: absolute;
					left: -3vw;
					top: -4vh;
					@media (max-width: 1420px) {
						@media (max-height: 580px) {
							left: -4.2vw;
							top: -4vh;
						}
					}
					@media (max-width: 930px) and (min-height: 600px) {
						width: 32.7vw;
						height: 24.42vh;

						left: -10vw;
						top: -3vh;
					}
					@media (max-width: 530px) and (min-height: 600px) {
						width: 32.7vw;
						height: 24.42vh;
						left: -7vw;
						top: -3vh;
					}
				}

				&::after {
					content: "";
					position: absolute;
					z-index: -1;
					top: 29vh;
					right: 4vw;
					width: 21.82vw;
					max-width: 29.2vh;
					aspect-ratio: 1/1;
					border-radius: 23.61725rem;
					background: radial-gradient(50% 50% at 50% 50%, #fff 0%, rgba(255, 255, 255, 0.13) 100%);

					@media (max-width: 930px) and (min-height: 600px) {
						width: 47.84vw;
						max-width: 22vh;
						top: -10%;
						right: -66%;
					}
					@media (max-width: 630px) and (min-height: 600px) {
						width: 47.84vw;
						max-width: 22vh;
						top: -12%;
						right: -62%;
					}
					@media (max-width: 530px) and (min-height: 600px) {
						width: 47.84vw;
						max-width: 22vh;
						top: -2%;
						right: -74%;
					}
					// @media (max-width: 1420px) {
					// 	@media (max-height: 580px) {
					// 		top: -15%;
					// 		right: -16%;
					// 	}
					// }
				}
				.overlay {
					position: absolute;
					z-index: -1;
					top: -5vh;
					right: -15.6vw;
					width: 21.82vw;
					max-width: 29.2vh;
					aspect-ratio: 1/1;
					border-radius: 23.61725rem;
					background: radial-gradient(50% 50% at 50% 50%, #fff 0%, rgba(255, 255, 255, 0.13) 100%);

					@media (max-width: 930px) and (min-height: 600px) {
						width: 47.84vw;
						max-width: 22vh;
						top: 52%;
						right: 34%;
					}
					@media (max-width: 630px) and (min-height: 600px) {
						width: 47.84vw;
						max-width: 22vh;
						top: 52%;
						right: 43%;
					}
					@media (max-width: 530px) and (min-height: 600px) {
						width: 47.84vw;
						max-width: 22vh;
						top: 52%;
						right: 8%;
					}
					// @media (max-width: 1420px) {
					// 	@media (max-height: 580px) {
					// 		top: -15%;
					// 		right: -16%;
					// 	}
					// }
				}
			}

			.introImg2 {
				width: 18.36vw;
				height: 47.2vh;
				position: absolute;
				z-index: 1;
				margin-top: 4rem;
				left: 48%;
				@media (max-width: 930px) and (min-height: 600px) {
					left: 40%;
					width: 42.3vw;
					height: 33.25vh;
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
				.intro2ChangeIcon {
					position: absolute;
					bottom: -1rem;
					left: -1rem;
				}
				&::before {
					content: "";
					width: 14.12vw;
					height: 34.68vh;
					z-index: -1;
					background: url("./assets/dotSvg.svg");
					background-size: 100% 100%;
					background-position: center;
					position: absolute;
					right: -3vw;
					top: 6vh;
					@media (max-width: 1420px) {
						@media (max-height: 580px) {
							right: -4.2vw;
							top: 6vh;
						}
					}
					@media (max-width: 930px) and (min-height: 600px) {
						width: 32.7vw;
						height: 24.42vh;
						left: 19vw;
						top: 4.2vh;
					}
					@media (max-width: 530px) and (min-height: 600px) {
						width: 32.7vw;
						height: 24.42vh;
						left: 16vw;
						top: 4.2vh;
					}
				}
			}
		}
	}
	.rightSection {
		// background-color: red;
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 3rem;
		position: relative;
		@media (max-width: 930px) and (min-height: 600px) {
			width: 100%;
			height: 55%;
		}

		.TextWrapper {
			width: max-content;
			height: max-content;
			position: relative;
			.introText {
				width: 41vw;
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 1.75169rem;
				font-style: normal;
				font-weight: 300;
				line-height: 174%; /* 48.767px */
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				overflow: hidden;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 83.2vw;
				}
			}

			.EditTextIcon {
				height: 3.5rem;
				aspect-ratio: 1/1;
				position: absolute;
				top: -4rem;
				left: 4rem;
				@media (max-width: 930px) and (min-height: 600px) {
					top: 0rem;
					left: -3rem;
				}
			}
		}

		.buttons {
			display: flex;
			width: 41vw;
			justify-content: space-evenly;
			button {
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 1.8975rem;
				font-style: normal;
				font-weight: 300;
				line-height: 174%; /* 52.827px */
				border: none;
				background: transparent;
				cursor: pointer;
				text-transform: uppercase;
			}
		}
		.introImages {
			height: auto;
			width: auto;
			display: flex;
			gap: 0.5rem;
			margin-top: 3rem;

			.introImg3,
			.introImg4,
			.introImg5 {
				width: 11.11vw;
				height: 26vh;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 27.8vw;
					height: 20vh;
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
			.introImg3 {
				position: relative;
				.intro3ChangeIcon {
					position: absolute;
					left: 4rem;
					bottom: -2rem;
				}
			}

			.introImg4 {
				margin-top: 2rem;
				position: relative;
				.intro4ChangeIcon {
					position: absolute;
					left: 4rem;
					bottom: -2rem;
				}
			}
			.introImg5 {
				margin-top: 1rem;
				position: relative;
				.intro5ChangeIcon {
					position: absolute;
					left: 4rem;
					bottom: -2rem;
				}
			}
		}
	}
}

.Container3 {
	height: 100%;
	width: 100%;
	max-width: 100vw;
	max-height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	background: radial-gradient(50% 50% at 50% 50%, #591a8a8e 0%, #1311afbb 100%);
	// background-color: cornflowerblue;

	.header {
		margin: auto;
		width: 80%;
		height: 17.56519rem;
		max-height: 15vh;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 930px) and (min-height: 600px) {
			height: 7.72138rem;
		}

		.mygallery {
			color: #fff;
			font-family: Poppins;
			font-size: 3.30025rem;
			font-style: normal;
			font-weight: 600;
			line-height: 76.4%;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 2.72138rem;
			}
		}

		.viewall {
			width: 1.8975rem;
			height: 1.8975rem;
			cursor: pointer;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 1.18975rem;
				height: 1.18975rem;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.mobileView {
		// .activeSlide {}
		display: none;
		.smallContainer {
			display: flex;
			overflow-y: scroll;
			padding: 0 2rem;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		@media (max-width: 930px) and (min-height: 600px) {
			display: block;
		}
	}

	.desktopView {
		margin-top: 3rem;
		.alice-carousel__prev-btn {
			font-size: 4rem;
		}

		@media (max-width: 930px) and (min-height: 600px) {
			display: none;
		}
	}

	//mobile
	.activeContainer {
		width: 95.70431rem;
		max-width: 90vw;
		height: 55.35088rem;
		border-radius: 2.57763rem;
		max-height: 70vh;
		min-height: 70vh;
		margin: auto;
		position: relative;

		@media (max-width: 400px) {
			max-height: 55vh;
		}

		@media (max-width: 930px) and (min-height: 600px) {
			min-height: 58vh;
		}

		.imageSection {
			height: 100%;
			width: 100%;
		}

		.imageSection img {
			border-radius: 2.57763rem;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
		.imageSection video {
			border-radius: 2.57763rem;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.details {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.upper {
				display: flex;
				align-items: center;
				gap: 1.22rem;
				padding: 2.2rem;

				@media (max-width: 930px) and (min-height: 600px) {
					padding: 1.8rem;
				}

				.profileLogo {
					width: 5.97156rem;
					height: 5.97156rem;
					border-radius: 5.97156rem;
				}

				.profileLogo img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: 5.97156rem;
				}

				.nameUsername {
					.name {
						color: #fff;
						font-family: Poppins;
						font-size: 2.16688rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					.userName {
						color: #fff;
						font-family: Poppins;
						font-size: 1.14175rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}

			.below {
				width: 100%;
				height: 11.48619rem;
				max-height: 19%;
				display: flex;
				align-content: center;
				justify-content: space-between;
				background-color: rgba(0, 0, 0, 0.2);
				border-radius: 0 0 2.57763rem 2.57763rem;
				padding: 0 2rem;
				@media (max-width: 930px) and (min-height: 600px) {
					padding: 0 0.71rem;
				}

				.desc {
					width: 50%;
					height: 4.56231rem;
					overflow: hidden;
					margin: auto;
					padding: 0 8rem;
					color: #fff;
					font-family: Poppins;
					font-size: 1.87994rem;
					font-style: normal;
					font-weight: 400;
					line-height: 123.4%;
					@media (max-width: 930px) and (min-height: 600px) {
						padding: 0 2rem;
					}
				}

				.icons {
					width: 50%;
					// justify-self: flex-end;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 3rem;
					@media (max-width: 930px) and (min-height: 600px) {
						gap: 0.8rem;
					}

					.like {
						display: flex;
						align-items: center;
						justify-content: center;

						.likeIcon {
							// width: 4.32rem;
							width: 4rem;
							aspect-ratio: 1/1;
							display: flex;
							align-items: center;
							cursor: pointer;

							img {
								height: 100%;
								width: 100%;
								object-fit: cover;
							}

							.likeCount {
								color: #fff;
								font-family: Poppins;
								font-size: 2.6rem;
								font-style: normal;
								font-weight: 600;
								line-height: 123.4%;
							}
						}
					}

					.comment {
						display: flex;
						align-items: center;
						justify-content: center;

						.commentIcon {
							width: 4rem;
							aspect-ratio: 1/1;

							// background-color: red;
						}

						.commentIcon img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}

						.commentCount {
							color: #fff;
							font-family: Poppins;
							font-size: 2.6rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%;
						}
					}

					.shareIcon {
						width: 4rem;
						aspect-ratio: 1/1;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.shareIcon img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

	//mobile other containers
	.cardContainer {
		width: 13.53394rem;
		height: 10.99963rem;
		border-radius: 1.25rem;
		min-width: 31vw;
		min-height: 22vh;
		padding: 2rem 1rem;

		@media (max-width: 400px) {
			min-width: 40vw;
			min-height: 29vh;
		}
	}

	.cardContainer img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 1.875rem;
	}
	.cardContainer video {
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 1.875rem;
	}
}

.Container4 {
	height: auto;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	padding-top: 6rem;
	background: linear-gradient(to right, #1311afbb, #1311afbb);

	// background-color: rgb(216, 41, 149);
	.ShortMessageCon {
		width: 33.65106rem;
		height: 15.01838rem;
		background: rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(7.418872356414795px);
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		position: relative;
		z-index: 1;

		// 	&::before{
		// content: "";
		// position: absolute;
		// left: -2rem;
		// top: -2rem;
		// z-index: -1;
		// background-image: url("./assets/dotSvg.svg");
		// height: 12.66131rem;
		// 	width: 7.83169rem;
		// 	}

		.EditTextIcon {
			position: absolute;
			top: 1rem;
			left: 1rem;
			height: 4rem;
			aspect-ratio: 1/1;
		}

		.heading {
			color: #fff;
			font-family: Poppins;
			font-size: 2.91794rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.shortMessage {
			color: #f9f9f9;
			text-align: center;
			font-family: Poppins;
			font-size: 1.11281rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	.thankYou {
		color: #fff;
		text-align: center;
		font-family: Noto Serif;
		font-size: 1.73938rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 3rem 0;
	}
}
