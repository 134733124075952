.HomeButton {
	position: absolute;
	top: 2rem;
	left: 2rem;
	z-index: 10;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #1e1e1e;
	border: 1px solid #00a498;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 0.5rem;

	@media screen and (max-width: 1000px) {
		display: none;
	}

	@media screen and (max-width: 800px) {
		top: 5rem;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.SubmitMobileView {
	position: absolute;
	top: 2rem;
	right: 2rem;
	z-index: 10;
	// width: 50px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #1e1e1e;
	border: 1px solid #00a498;
	cursor: pointer;
	padding: 0px 10px;
	border-radius: 10px;
	color: #fff;

	svg {
		width: 100%;
		height: 100%;
	}
}
