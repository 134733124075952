.TemplateWrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	textarea {
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.Template14 {
		width: 100%;
		height: 100%;
		background: #010135;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	height: auto;
	min-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #010135 80.93%);
	backdrop-filter: blur(21.665359497070312px);
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: relative;
	@media (orientation: portrait) {
		align-items: center;
	}
	// textarea {
	// 	border: none;
	// 	padding: 0.2rem 1rem;
	// 	color: #fff;
	// 	font-size: 2.3rem;
	// 	@media (orientation: portrait) {
	// 		font-size: 1.2rem;
	// 	}
	// }
	.ratingFollower {
		position: absolute;
		bottom: 4rem;
		right: 4rem;
		display: flex;
		align-items: center;
		gap: 1rem;
		.RatingContainer {
			width: 5.52rem;
			height: 5.52rem;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			cursor: pointer;

			span {
				position: absolute;
				color: #fff;
				font-family: "Poppins";
				font-size: 0.99213rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}
		.followers {
			display: flex;
			flex-direction: column;

			h3 {
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 5.826rem;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 2.86575rem;
				}
			}
			h4 {
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 2.01606rem;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 0.99169rem;
				}
			}
		}
	}

	.heroImage {
		margin-top: 3rem;
		width: 43.10131rem;
		height: 55.11081rem;
		max-height: 80vh;
		position: relative;
		z-index: 1;
		.coverChangeIcon {
			height: 3.5rem;
			width: 3.5rem;
			position: absolute;
			top: 20%;
			left: 10%;
			transform: translateY(-40%);
			@media (orientation: portrait) {
				height: 2.5rem;
				width: 2.5rem;
				top: 30%;
			}
		}
		@media (orientation: portrait) {
			width: 22.83163rem;
			height: 29.19331rem;
		}
		img {
			height: 100%;
			width: 100%;
		}

		.NameMobile {
			font-family: Poppins;
			font-size: 6rem;
			font-style: normal;
			font-weight: 800;
			line-height: 94.5%; /* 5.67rem */
			background: var(--color-sec, linear-gradient(180deg, #34027f 10.42%, #fff 73.44%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			transform: rotate(90deg);
			display: none;
			position: absolute;
			top: -3rem;
			@media (max-width: 930px) and (min-height: 600px) {
				display: block;
			}
		}

		.follow {
			position: absolute;
			bottom: -17%;
			left: 10%;
			width: 5.48706rem;
			height: 22.31713rem;
			flex-shrink: 0;
			border-radius: 0rem 0rem 2.70819rem 2.70819rem;
			background: #6218ff;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			z-index: -1;
			@media (orientation: portrait) {
				width: 3.79894rem;
				height: 15.45125rem;
				border-radius: 0rem 0rem 1.875rem 1.875rem;
				background: #6218ff;
				bottom: -20%;
			}
			button {
				padding: 3rem;
				color: #fff;
				background-color: transparent;
				border: none;
				text-align: center;
				font-family: Poppins;
				font-size: 1.26381rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				transform: rotate(-90deg);
				cursor: pointer;
				@media (orientation: portrait) {
					font-size: 0.875rem;
					padding: 2rem;
				}
			}
		}

		.message {
			position: absolute;
			bottom: -11%;
			left: 25%;
			width: 5.48706rem;
			height: 20.69488rem;
			flex-shrink: 0;
			border-radius: 0rem 0rem 2.70819rem 2.70819rem;
			background: #fff;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			z-index: -1;
			@media (orientation: portrait) {
				width: 3.79894rem;
				height: 14.32806rem;
				border-radius: 0rem 0rem 1.875rem 1.875rem;
				background: #fff;
				bottom: -13%;
				left: 28%;
			}
			button {
				padding: 2rem;
				color: #000;
				text-align: center;
				font-family: Poppins;
				font-size: 0.72219rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				transform: rotate(-90deg);
				cursor: pointer;
				background: transparent;
				border: none;
				@media (orientation: portrait) {
					font-size: 0.5rem;
				}
			}
		}

		.details {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 43.99331rem;
			height: 25.47556rem;
			max-height: 40vh;
			border-radius: 3.01069rem;
			background: rgba(0, 0, 0, 0.22);
			backdrop-filter: blur(28.90284538269043px);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 3rem;

			@media (orientation: portrait) {
				width: 22.83163rem;
				height: 13.22131rem;
				border-radius: 1.5625rem;
				background: rgba(0, 0, 0, 0.22);
				backdrop-filter: blur(15px);
			}
			.fullName {
				display: flex;
				flex-direction: column;
				.Iam {
					color: #fff;
					font-family: Poppins;
					font-size: 2.06706rem;
					font-style: normal;
					font-weight: 500;
					line-height: 99.5%; /* 2.05675rem */
					@media (orientation: portrait) {
						font-size: 1.09419rem;
					}
				}
				.name {
					color: #fff;
					font-family: Poppins;
					font-size: 4.74675rem;
					font-style: normal;
					font-weight: 800;
					line-height: 94.5%; /* 4.48569rem */
					@media (orientation: portrait) {
						font-size: 2.4635rem;
					}
					span {
						display: block;
						margin-left: 5rem;
						margin-top: 0.4rem;
						color: #fff;
						font-family: Poppins;
						font-size: 2.15338rem;
						font-style: normal;
						font-weight: 600;
						line-height: 94.5%; /* 2.03494rem */
						@media (orientation: portrait) {
							font-size: 1.11756rem;
						}
					}
				}
			}

			.TextWrapper {
				width: max-content;
				height: max-content;
				position: relative;
				.EditTextIcon {
					position: absolute;
					height: 3.5rem;
					width: 3.5rem;
					top: -4rem;
					left: 1rem;
					@media (orientation: portrait) {
						top: -3rem;
						left: 0;
						height: 2.5rem;
						width: 2.5rem;
					}
				}
				.coverText {
					width: 33.17681rem;
					height: 5rem;
					color: #fff;
					text-align: center;
					font-family: Poppins;
					font-size: 1.04506rem;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					&::-webkit-scrollbar {
						display: none;
					}
					@media (orientation: portrait) {
						width: 17.21813rem;
						font-size: 0.54238rem;
						height: 4rem;
					}
				}
			}
		}
	}

	.dot1 {
		height: 8rem;
		width: 16rem;
		background: url("./assets/dot1.png");
		background-size: cover;
		position: absolute;
		top: -1rem;
		left: 2rem;
		@media (orientation: portrait) {
			display: none;
		}
	}
	.dot2 {
		height: 8rem;
		width: 16rem;
		background: url("./assets/dot1.png");
		background-size: cover;
		position: absolute;
		top: -3rem;
		right: 0;
		@media (orientation: portrait) {
			display: none;
		}
	}
	.dot3 {
		height: 16rem;
		width: 8rem;
		background: url("./assets/dot2.png");
		background-size: cover;
		position: absolute;
		bottom: 4rem;
		left: -2rem;
		@media (orientation: portrait) {
			display: none;
		}
	}
	.dot4 {
		height: 16rem;
		width: 8rem;
		background: url("./assets/dot2.png");
		background-size: cover;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -2rem;
		@media (orientation: portrait) {
			display: none;
		}
	}
	.dot1Mob {
		width: 5.65175rem;
		height: 5.48681rem;
		background: url("./assets/dots.svg");
		background-size: cover;
		position: absolute;
		top: 6rem;
		transform: translateY(-50%);
		left: -2rem;
		display: none;
		@media (orientation: portrait) {
			display: block;
		}
	}
	.dot2Mob {
		width: 5.65175rem;
		height: 5.48681rem;
		background: url("./assets/dots.svg");
		background-size: cover;
		position: absolute;
		top: 12rem;
		transform: translateY(-50%);
		right: -1rem;
		display: none;
		@media (orientation: portrait) {
			display: block;
		}
	}
	.dot3Mob {
		width: 5.65175rem;
		height: 5.48681rem;
		background: url("./assets/dots.svg");
		background-size: cover;
		position: absolute;
		top: 6rem;
		transform: translateY(-50%);
		left: 2rem;
		display: none;
		z-index: -1;
		@media (orientation: portrait) {
			display: block;
		}
	}
}

.Container2 {
	height: auto;
	min-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	padding: 2rem 0;
	.myIntro {
		font-family: Poppins;
		font-size: 5.94506rem;
		font-style: normal;
		font-weight: 800;
		line-height: 94.5%; /* 5.61813rem */
		background: var(--color-sec, linear-gradient(180deg, #34027f 10.42%, #fff 73.44%));
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		position: relative;
		z-index: 1;
		margin-bottom: 1rem;
		@media (orientation: portrait) {
			font-size: 4.11606rem;
		}
		&::after {
			content: "My Intro";
			transform: rotateX(180deg);
			position: absolute;
			bottom: -80%;
			left: 0;
			background: var(--color-sec, linear-gradient(180deg, #34027f 10.42%, #fff 73.44%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			filter: blur(2.888714551925659px);
			font-family: Poppins;
			font-size: 5.94506rem;
			font-style: normal;
			font-weight: 800;
			line-height: 94.5%; /* 5.61813rem */
			z-index: -1;
			@media (orientation: portrait) {
				font-size: 4.11606rem;
				filter: blur(2px);
			}
		}
	}
	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 2rem;
		width: 100%;
		@media (orientation: portrait) {
			padding: 0;
			position: relative;
		}
		.leftSection {
			@media (orientation: portrait) {
				transform: translateX(-14%);
			}
			.introImg1 {
				width: 32.97706rem;
				height: 41.01813rem;
				position: relative;
				.intro1ChangeIcon {
					height: 3.5rem;
					width: 3.5rem;
					position: absolute;
					left: 3rem;
					bottom: 4rem;
					@media (orientation: portrait) {
						height: 2.5rem;
						width: 2.5rem;
						left: 20%;
						bottom: 20%;
					}
				}
				@media (orientation: portrait) {
					width: 22.83163rem;
					height: 29.19331rem;
				}
				img {
					height: 100%;
					width: 100%;
				}
				.introImg2 {
					position: absolute;
					right: -15rem;
					top: 3rem;
					width: 20.58831rem;
					height: 20.028rem;
					border-radius: 50%;
					border: 4.333px solid #010132;
					.intro2ChangeIcon {
						height: 3.5rem;
						width: 3.5rem;
						position: absolute;
						right: 1rem;
						top: 1rem;
						img {
							border-radius: 0;
						}
						@media (orientation: portrait) {
							height: 2.5rem;
							width: 2.5rem;
						}
					}
					@media (orientation: portrait) {
						width: 14.25431rem;
						height: 14.25431rem;
						border-radius: 14.25431rem;
						border: 3px solid #010132;
						right: -9rem;
						top: 2rem;
						z-index: 20;
					}
					img {
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
				}
			}
		}

		.rightSection {
			width: 52.28019rem;
			height: 24.05644rem;
			flex-shrink: 0;
			border-radius: 1.79338rem;
			background: rgba(18, 18, 18, 0.6);
			backdrop-filter: blur(22.65342140197754px);
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			@media (orientation: portrait) {
				width: 21.53388rem;
				height: 11.56231rem;
				border-radius: 1.00031rem;
				background: rgba(18, 18, 18, 0.6);
				backdrop-filter: blur(12.635137557983398px);
				position: absolute;
				right: 0;
				bottom: 20%;
			}

			.TextWrapper {
				width: max-content;
				height: max-content;
				position: relative;
				.EditTextIcon {
					position: absolute;
					height: 3.5rem;
					width: 3.5rem;
					top: -4rem;
					left: 1rem;
					@media (orientation: portrait) {
						top: -3rem;
						left: 0;
						height: 2.5rem;
						width: 2.5rem;
					}
				}
				.desc {
					width: 47.91494rem;
					height: 16.29706rem;
					color: #fff;
					font-family: Poppins;
					font-size: 1.34469rem;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					overflow: hidden;
					@media (orientation: portrait) {
						width: 16.03406rem;
						height: 4.67081rem;
						font-size: 0.75rem;
					}
				}
			}
			.buttons {
				position: absolute;
				bottom: -2rem;
				right: 0rem;
				display: flex;
				align-items: center;
				button {
					height: 5.48706rem;
					width: 16.41644rem;
					flex-shrink: 0;
					border-radius: 2.70819rem 0rem 0rem 2.70819rem;
					background: #6218ff;
					border: none;
					color: #fff;
					text-align: center;
					font-family: Poppins;
					font-size: 0.78338rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					display: flex;
					align-items: center;
					padding-left: 2rem;
					cursor: pointer;
					@media (orientation: portrait) {
						height: 3.79894rem;
						width: 11.36594rem;
						flex-shrink: 0;
						border-radius: 1.875rem 0rem 0rem 1.875rem;
						background: #6218ff;
						font-size: 0.78338rem;
					}
					&:nth-child(1) {
						transform: translateX(14%);
					}
					&:nth-child(2) {
						position: relative;
						z-index: 2;
						box-shadow: -4.333px 0px 5.777px 0px rgba(0, 0, 0, 0.25);
					}
				}
			}
		}
	}
	.introGallery {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 41.0625rem;
		width: 100%;
		gap: 3rem;
		@media (orientation: portrait) {
			height: 21.7165rem;
			gap: 2rem;
		}
		.left {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			.introImg3,
			.introImg4 {
				width: 8.90125rem;
				height: 20.01113rem;
				border-radius: 0.96613rem;
				position: relative;
				.introChangeIcon {
					height: 3.5rem;
					width: 3.5rem;
					position: absolute;
					left: 50%;
					top: -1.5rem;
					transform: translateX(-50%);
					@media (orientation: portrait) {
						height: 3rem;
						width: 3rem;
					}
				}
				@media (orientation: portrait) {
					width: 4.70156rem;
					height: 10.56969rem;
					border-radius: 0.51031rem;
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: 0.96613rem;
					@media (orientation: portrait) {
						border-radius: 0.51031rem;
					}
				}
			}

			.introImg4 {
				.introChangeIcon {
					height: 3.5rem;
					width: 3.5rem;
					position: absolute;
					left: 50%;
					top: 60%;
					transform: translateX(-50%);
					@media (orientation: portrait) {
						height: 3rem;
						width: 3rem;
					}
				}
			}
		}
		.mid {
			width: 40.0625rem;
			height: 41.0625rem;
			border-radius: 1.18331rem;
			position: relative;
			.introChangeIcon {
				height: 4rem;
				width: 4rem;
				position: absolute;
				left: 50%;
				top: -1.5rem;
				transform: translateX(-50%);
				@media (orientation: portrait) {
					height: 3rem;
					width: 3rem;
				}
			}
			@media (orientation: portrait) {
				width: 13.71575rem;
				height: 21.7165rem;
				border-radius: 0.625rem;
			}
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 1.18331rem;
				@media (orientation: portrait) {
					border-radius: 0.625rem;
				}
			}
		}
		.right {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			.introImg6,
			.introImg7 {
				width: 8.90125rem;
				height: 20.01113rem;
				border-radius: 0.96613rem;
				position: relative;
				.introChangeIcon {
					height: 4rem;
					width: 4rem;
					position: absolute;
					left: 50%;
					top: -1.5rem;
					transform: translateX(-50%);
					@media (orientation: portrait) {
						height: 3rem;
						width: 3rem;
					}
				}
				@media (orientation: portrait) {
					width: 4.70156rem;
					height: 10.56969rem;
					border-radius: 0.51031rem;
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: 0.96613rem;
					@media (orientation: portrait) {
						border-radius: 0.51031rem;
					}
				}
			}
			.introImg7 {
				.introChangeIcon {
					height: 3.5rem;
					width: 3.5rem;
					position: absolute;
					left: 50%;
					top: 60%;
					transform: translateX(-50%);
					@media (orientation: portrait) {
						height: 3rem;
						width: 3rem;
					}
				}
			}
		}
	}
}

.Container3 {
	height: auto;
	min-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 3rem;

	h2 {
		color: #fff;
		text-align: center;
		font-family: Poppins;
		font-size: 4.31981rem;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
	}

	.carousel {
		width: 96%;
		height: 52.82825rem;
		max-height: 90vh;
		flex-shrink: 0;
		@media (orientation: portrait) {
			min-height: 88vh;
			max-height: 150vh;
		}
	}

	.shortMessage {
		width: 44.09606rem;
		height: 19.67994rem;
		flex-shrink: 0;
		background: rgba(255, 255, 255, 0.02);
		backdrop-filter: blur(9.721620559692383px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2rem;
		@media (orientation: portrait) {
			width: 22.67938rem;
			height: 10.12175rem;
			background: rgba(255, 255, 255, 0.02);
			backdrop-filter: blur(5px);
		}

		h3 {
			color: #fff;
			font-family: Poppins;
			font-size: 3.82363rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			@media (orientation: portrait) {
				font-size: 1.96656rem;
			}
		}

		.TextWrapper {
			width: max-content;
			height: max-content;
			position: relative;

			.EditTextIcon {
				position: absolute;
				height: 3.5rem;
				width: 3.5rem;
				top: -4rem;
				left: -1rem;
				@media (orientation: portrait) {
					top: -3rem;
					left: -1.2rem;
					height: 2.5rem;
					width: 2.5rem;
				}
			}

			.message {
				width: 36.89613rem;
				color: #f9f9f9;
				text-align: center;
				font-family: Poppins;
				font-size: 1.45825rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@media (orientation: portrait) {
					width: 18.97631rem;
					font-size: 0.75rem;
				}
			}
		}
	}
	.thankYou {
		color: #fff;
		text-align: center;
		font-family: Noto Serif;
		font-size: 1.986rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding-bottom: 4rem;
		@media (orientation: portrait) {
			font-size: 1rem;
		}
	}
}

.galleryCard {
	height: 52.82825rem;
	max-height: 80vh;
	width: 100%;
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 2.00225rem;

	.backImage {
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
		border-radius: 2.00225rem;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 2.00225rem;
		}
	}

	.profile {
		display: flex;
		align-items: center;
		gap: 0.3rem;
		padding: 2rem;
		.profilePic {
			width: 3.29456rem;
			height: 3.29456rem;
			border-radius: 50%;
			img {
				height: 100%;
				width: 100%;
			}
		}
		.detail {
			display: flex;
			flex-direction: column;
			gap: 0.1rem;
			h3 {
				color: #fff;
				font-family: Poppins;
				font-size: 1.00669rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			h4 {
				color: #fff;
				font-family: Poppins;
				font-size: 0.54913rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
	.details {
		width: 100%;
		height: 5.69688rem;
		background: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(3.843660831451416px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 4rem;
		.desc {
			color: #fff;
			font-family: Poppins;
			font-size: 1.48725rem;
			font-style: normal;
			font-weight: 400;
			line-height: 123.4%; /* 1.83525rem */
			width: 28.347rem;
			height: 3.60938rem;
			flex-shrink: 0;
		}
		.actions {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 3rem;
			.like {
				display: flex;
				align-items: center;
				justify-content: center;

				.likeIcon {
					// width: 4.32rem;
					width: 4rem;
					aspect-ratio: 1/1;
					display: flex;
					align-items: center;
					cursor: pointer;

					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}

					.likeCount {
						color: #fff;
						font-family: Poppins;
						font-size: 2.6rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%;
					}
				}
			}
			.comment {
				width: 3.13381rem;
				height: 2.82131rem;
			}
			.share {
				width: 3.66781rem;
				height: 2.57331rem;
			}
			.comment,
			.share {
				display: flex;
				align-items: center;
				gap: 0.1rem;
				p {
					color: #fff;
					font-family: Poppins;
					font-size: 2.846006rem;
					font-style: normal;
					font-weight: 600;
					line-height: 123.4%; /* 1.80175rem */
				}
				img {
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}

.MobileGallery {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	position: relative;

	.activeItem {
		position: relative;
		width: 23.18644rem;
		width: 90vw;
		height: 20.62156rem;
		height: 58vh;
		flex-shrink: 0;
		border-radius: 1.417rem;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.backImage {
			height: 100%;
			width: 100%;
			position: absolute;
			border-radius: 1.417rem;
			z-index: -1;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 1.417rem;
			}
		}

		.profile {
			display: flex;
			align-items: center;
			gap: 0.4rem;
			padding: 0.5rem 1rem;

			.profilePic {
				width: 1.6415rem;
				height: 1.6415rem;
				border-radius: 50%;

				img {
					width: 1.6415rem;
					height: 1.6415rem;
					border-radius: 50%;
				}
			}

			.detail {
				display: flex;
				flex-direction: column;
				gap: 0.2rem;

				h3 {
					color: #fff;
					font-family: Poppins;
					font-size: 0.50156rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				h4 {
					color: #fff;
					font-family: Poppins;
					font-size: 0.27356rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}

		.details {
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding: 0 1rem;
			height: 2.83844rem;
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(1.9151055812835693px);

			.desc {
				width: 14.12388rem;
				height: 1.79838rem;
				color: #fff;
				font-family: Poppins;
				font-size: 0.741rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 0.91444rem */
			}

			.actions {
				display: flex;
				align-items: center;
				gap: 2rem;

				.like {
					display: flex;
					align-items: center;
					justify-content: center;

					.likeIcon {
						// width: 4.32rem;
						width: 1.42rem;
						aspect-ratio: 1/1;
						display: flex;
						align-items: center;
						cursor: pointer;

						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}

						.likeCount {
							color: #fff;
							font-family: Poppins;
							font-size: 1rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%;
						}
					}
				}

				.comment {
					width: 1.06325rem;
					height: 0.9075rem;
				}

				.share {
					width: 1.32925rem;
					height: 0.78394rem;
				}

				.comment,
				.share {
					p {
						color: #fff;
						font-family: Poppins;
						font-size: 0.7275rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 0.89775rem */
					}

					display: flex;
					align-items: center;
					gap: 0.2rem;

					img {
						height: 100%;
						width: 100%;
					}
				}
			}
		}
	}

	.allItems {
		height: 30vh;
		width: 100%;
		display: flex;
		align-items: center;
		overflow-y: scroll;
		scroll-behavior: smooth;

		&::-webkit-scrollbar {
			display: none;
		}

		.smallCard {
			height: 20vh;
			width: 31vw;
			min-width: 31vw;
			margin-right: 2vw;
			img {
				height: 100%;
				width: 100%;
			}
		}
	}
}
