.TemplateWrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	background: var(--frsd, radial-gradient(50% 50% at 50% 50%, #0e2138 0%, #0a1b30 100%));
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template1 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 750px) and (min-height: 500px) {
		flex-direction: column;
	}

	.Left {
		padding: 4rem 0 0 7.48rem;
		z-index: 3;

		@media screen and (max-width: 640px) {
			padding: 5rem 0 0 5rem;
		}
		@media screen and (max-width: 480px) {
			padding: 3rem 0 0 4rem;
		}

		@media screen and (max-height: 670px) {
			padding: 2rem 0 0 3rem;
		}

		h4 {
			color: #fff;
			font-family: "Poppins";
			font-size: 3.17838rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			margin-bottom: 1rem;
			-webkit-leading-trim: both;

			@media screen and (max-width: 500px) {
				@media screen and (max-height: 670px) {
					font-size: 2.5rem;
				}
			}
		}

		.name {
			min-width: 28rem;
			max-width: 28rem;
			color: #fff;
			font-family: "Bebas Neue";
			font-size: 9rem;
			font-style: normal;
			font-weight: 400;
			line-height: 80%; /* 9.33763rem */
			overflow: hidden !important;

			@media screen and (max-width: 550px) {
				@media screen and (max-height: 930px) {
					font-size: 7.8rem;
				}
				@media screen and (max-height: 700px) {
					max-width: 20rem !important;
					min-width: 20rem !important;
					font-size: 6.5rem;
				}
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	.Right {
		width: 55.4735rem;
		height: 100%;
		z-index: 3;
		position: relative;

		.coverImage {
			bottom: 18%;
			left: 16%;
		}

		@media screen and (max-width: 750px) {
			align-self: flex-end;
		}

		@media screen and (max-width: 450px) {
			width: 100%;
		}

		img {
			width: 100%;
			height: 80%;
			position: absolute;
			bottom: 0;
			left: 0;

			@media screen and (max-width: 450px) {
				height: 100%;
			}
		}
	}

	.BottomPart {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 16.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 8.5rem 0 12.5rem;
		background: linear-gradient(180deg, rgba(14, 33, 56, 0) 0%, #0e2138 100%);
		z-index: 3;

		@media screen and (max-width: 750px) {
			flex-direction: column;
			gap: 2rem;
			padding: 0;
		}

		@media screen and (max-width: 450px) {
			@media screen and (max-height: 670px) {
				padding-bottom: 2rem;
			}
		}

		.LeftInner {
			display: flex;
			align-items: center;
			gap: 4.75rem;

			@media screen and (max-width: 750px) {
				width: 100%;
				justify-content: space-between;
				gap: 2.47rem;
				padding: 0 8rem 0 20rem;
			}
			@media screen and (max-width: 650px) {
				padding: 0 8rem 0 15rem;
			}
			@media screen and (max-width: 500px) {
				padding: 0 8rem 0 10rem;
			}
			@media screen and (max-width: 450px) {
				padding: 0 3rem 0 6rem;
			}

			@media screen and (max-width: 450px) {
				@media screen and (max-height: 670px) {
					padding: 0 8rem 0 7rem;
				}
			}

			.RatingContainer {
				width: 5.52rem;
				height: 5.52rem;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				cursor: pointer;

				span {
					position: absolute;
					color: #fff;
					font-family: "Poppins";
					font-size: 0.99213rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}

			.TextContainer {
				height: 5.98rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				h3 {
					color: #fff;
					text-align: center;
					font-family: "Inter";
					font-size: 3.20906rem;
					font-style: normal;
					font-weight: 800;
					line-height: 94.5%; /* 3.03256rem */
				}

				h2 {
					text-align: center;
					color: #fff;
					font-family: "Inter";
					font-size: 4.21619rem;
					font-style: normal;
					font-weight: 800;
					line-height: 94.5%; /* 3.98425rem */
				}

				p {
					color: #fff;
					font-family: "Inter";
					font-size: 1.80025rem;
					font-style: normal;
					font-weight: 800;
					line-height: 94.5%; /* 1.70125rem */
				}
			}
		}

		.RightInner {
			height: 5.98rem;
			display: flex;
			align-items: center;
			gap: 14rem;

			@media screen and (max-width: 750px) {
				width: 100%;
				height: 7rem;
				gap: 11.68rem;
				justify-content: space-around;
			}

			button {
				height: 100%;
				color: #fff;
				font-family: "Inter";
				font-size: 2rem;
				font-style: normal;
				font-weight: 800;
				line-height: 94.5%; /* 1.89rem */
				cursor: pointer;
				background: transparent;
				border: none;
				padding: 0rem 1rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;

				@media screen and (max-width: 750px) {
					padding: 0 4rem;
				}
			}
		}
	}

	.RealContainer {
		width: 190rem;
		height: 36rem;
		transform: rotate(-25deg) translate(-20%, -6%);
		position: absolute;
		background: #000;
		padding: 1.52806rem 0rem 1.52813rem 0rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1.5rem;

		@media screen and (max-width: 1400px) {
			@media screen and (max-height: 650px) {
				width: 150rem;
				height: 25rem;
				transform: rotate(340deg) translate(-10%, 30%);
			}
		}

		@media screen and (max-width: 1280px) {
			transform: rotate(336deg) translate(-8%, 9%);
			@media screen and (min-height: 800px) {
				transform: rotate(315deg) translate(-29%, -14%);
			}
		}

		@media screen and (max-width: 640px) {
			transform: rotate(-41deg) translate(-20%, -46%);
		}

		@media screen and (max-width: 480px) {
			width: 150rem;
			height: 30rem;
			transform: rotate(-47deg) translate(-39%, -40%);
		}

		@media screen and (max-width: 380px) {
			width: 150rem;
			height: 26rem;
			transform: rotate(317deg) translate(-36%, -85%);
		}

		@media screen and (max-width: 450px) {
			@media screen and (max-height: 850px) {
				transform: rotate(-44deg) translate(-38%, -60%);
			}
		}

		@media screen and (max-width: 400px) {
			@media screen and (max-height: 680px) {
				height: 25rem;
				transform: rotate(-44deg) translate(-38%, -77%);
			}
		}

		.Dots {
			display: flex;
			align-items: flex-start;
			gap: 4.36081rem;

			.Dot {
				width: 2.52131rem;
				height: 0.93181rem;
				background: #f2f2f2;
			}
		}

		.ImageContainer {
			width: 100%;
			flex: 1;
			// height: 100%;
			display: flex;
			align-items: center;
			gap: 5rem;
			overflow: hidden;
			padding-left: 7rem;

			@media screen and (max-width: 1280px) {
				gap: 4rem;
				padding-left: 1rem;
			}

			@media screen and (max-width: 640px) {
				gap: 4rem;
			}

			@media screen and (max-width: 480px) {
				justify-content: center;
				gap: 2.55rem;
			}

			.InnerImageContainer {
				width: 50.8rem;
				height: 100%;
				overflow: hidden;

				@media screen and (max-width: 1400px) {
					@media screen and (max-height: 650px) {
						width: 40rem;
					}
				}

				@media screen and (max-width: 640px) {
					width: 45rem;
				}

				@media screen and (max-width: 480px) {
					width: 40rem;
				}

				@media screen and (max-width: 450px) {
					@media screen and (max-height: 850px) {
						width: 30rem;
					}
				}

				img {
					width: 100%;
					min-height: 100%;
					max-height: 150%;
				}
			}
		}
	}

	.coverImage {
		bottom: 25%;
		right: 35%;

		@media screen and (max-width: 450px) {
			right: 70%;
		}
	}

	.Reel1UploadBtn {
		bottom: 46%;
		left: 9%;

		@media screen and (max-width: 1400px) {
			@media screen and (max-height: 600px) {
				bottom: 32%;
			}
		}
	}

	.Reel2UploadBtn {
		top: 21%;
		left: 33%;

		@media screen and (max-width: 1400px) {
			@media screen and (max-height: 600px) {
				top: 30%;
				left: 40%;
			}
		}
	}

	.Reel3UploadBtn {
		top: 1%;
		right: 41%;

		@media screen and (max-width: 1400px) {
			@media screen and (max-height: 600px) {
				right: 26%;
			}
		}
	}
}

.Container2 {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	// padding: 7.82rem 10.69rem 8.44rem 7.53rem;
	overflow: hidden;
	margin-top: 3rem;
	margin-bottom: 3rem;

	.H4 {
		display: none;

		@media screen and (max-width: 750px) {
			display: block;
			color: #fff;
			font-family: "Bebas Neue";
			font-size: 7.45619rem;
			font-style: normal;
			font-weight: 400;
			line-height: 94.5%; /* 7.04613rem */
			margin-bottom: 2rem;
		}
	}

	@media screen and (max-width: 750px) {
		align-items: center;
		padding-top: 3rem;
	}

	@media screen and (max-height: 680px) {
	}

	.Wrapper {
		width: 100%;
		min-height: 100vh;
		padding: 0rem 10.69rem 0rem 7.53rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		overflow: hidden;

		@media screen and (max-width: 750px) {
			padding: 0rem;
			flex-direction: column-reverse;
			align-items: center;
			justify-content: flex-end;
			gap: 0rem;
		}
	}

	.Left {
		width: 45rem;
		// overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// border: 1px solid #000;

		@media screen and (max-width: 750px) {
			height: auto;
			align-items: flex-start;
			justify-content: flex-start;
		}

		@media screen and (max-width: 380px) {
			width: 100%;
		}

		.LeftInner {
			padding-top: 5rem;
			width: 100%;
			display: flex;
			flex-direction: column;
			position: relative;

			@media screen and (max-width: 750px) {
				padding-top: 2rem;
			}

			@media screen and (max-width: 374px) {
				@media screen and (max-height: 650px) {
					padding: 2rem !important;
					padding-bottom: 0rem !important;
				}
			}

			@media screen and (max-width: 374px) {
				@media screen and (max-height: 850px) {
					padding: 2rem !important;
					padding-bottom: 0rem !important;
				}
			}

			.EditTextIcon {
				width: 4rem;
				height: 4rem;
				position: absolute;
				top: 14%;
				right: 5%;
				cursor: pointer;

				@media screen and (max-width: 750px) {
					top: -8%;
					right: auto;
					left: 0%;
				}
			}
		}

		h4 {
			color: #fff;
			font-family: "Bebas Neue";
			font-size: 7.45619rem;
			font-style: normal;
			font-weight: 400;
			line-height: 94.5%; /* 7.04613rem */
			margin-bottom: 2.88rem;

			@media screen and (max-width: 750px) {
				display: none;
			}

			@media screen and (max-height: 650px) {
				font-size: 7rem;
			}
		}

		.Intro {
			width: 100%;
			height: 23rem;
			width: 100%;
			min-height: 20rem;
			color: #fff;
			font-family: "Inter";
			font-size: 2rem;
			font-style: normal;
			font-weight: 400;
			line-height: 138%; /* 2.76rem */
			overflow-y: scroll;
			margin-bottom: 2rem;

			@media screen and (max-width: 374px) {
				@media screen and (max-height: 650px) {
					height: 10rem !important;
					min-height: 17rem !important;
					margin-bottom: 2rem !important;
				}
			}

			&::-webkit-scrollbar {
				display: none;
			}

			@media screen and (max-width: 750px) {
				margin-bottom: 0rem;
			}

			@media screen and (max-height: 650px) {
				height: 20rem;
				font-size: 1.8rem;
				margin-bottom: 1rem;
			}
		}

		.ButtonContainer {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
			padding-bottom: 1rem;

			@media screen and (max-width: 750px) {
				height: auto;
				flex-direction: row;
			}

			button {
				display: block;
				color: #fff;
				font-family: "Inter";
				font-size: 2rem;
				font-style: normal;
				font-weight: 400;
				line-height: 138%; /* 2.76rem */
				cursor: pointer;
				background: transparent;
				border: none;
				position: relative;
				margin-bottom: 4rem;

				@media screen and (max-width: 750px) {
					margin-bottom: 0rem;
				}

				@media screen and (max-height: 650px) {
					font-size: 1.8rem;
					margin-bottom: 3rem;
				}

				&::after {
					content: "";
					position: absolute;
					left: 0;
					bottom: -0.67rem;
					width: 4.10506rem;
					height: 1px;
					background: #fff;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.Right {
		width: 45rem;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		position: relative;

		@media screen and (max-width: 750px) {
			align-items: flex-start;
			height: 50rem;
		}

		@media screen and (max-width: 650px) {
			@media screen and (max-height: 670px) {
				height: 40rem;
			}
		}

		@media screen and (max-width: 374px) {
			@media screen and (max-height: 650px) {
				width: 35rem;
				height: 35rem;
			}

			@media screen and (max-height: 850px) {
				width: 38rem;
			}
		}

		@media screen and (max-width: 380px) {
			width: 100%;
		}

		.IntroImage {
			top: 0%;
			left: 0%;

			@media screen and (max-width: 750px) {
				top: 0%;
				left: 0%;
			}
		}

		.RightInner {
			width: 100%;
			height: 50rem;
			position: relative;
			overflow: hidden;

			@media screen and (max-width: 650px) {
				@media screen and (max-height: 670px) {
					height: 40rem;
				}
				@media screen and (max-height: 750px) {
					width: 35rem;
					height: 35rem;
				}
			}

			@media screen and (max-width: 374px) {
				@media screen and (max-height: 650px) {
					width: 35rem !important;
					height: 35rem !important;
				}
			}

			@media screen and (max-height: 650px) {
				height: 40rem;
			}

			.Overlay {
				width: 100%;
				height: 100%;
				z-index: 1;
			}

			.OverlayAsset {
				width: 100%;
				height: 100%;
				z-index: 2;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

.Container3 {
	width: 100%;
	// min-height: 100vh;
	height: 100vh;
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
	padding-left: 10.69rem;
	padding-right: 0.56rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	@media screen and (max-width: 580px) {
		padding-left: 1rem;
		align-items: center;
	}
	@media screen and (max-width: 500px) {
		padding-left: 0rem;
		padding-right: 0rem;
	}

	h1 {
		color: #fff;
		font-family: "Bebas Neue";
		font-size: 7.45619rem;
		font-style: normal;
		font-weight: 400;
		line-height: 94.5%; /* 7.04613rem */
		// margin-bottom: 1.88rem;

		@media screen and (max-height: 650px) {
			font-size: 6.5rem;
		}
	}

	.bar {
		// width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 6.75rem;
		// margin-bottom: 3.27rem;
		margin-bottom: 2rem;

		@media screen and (max-width: 580px) {
			padding-right: 0rem;
			gap: 15rem;
		}

		h3 {
			color: #fff;
			font-family: "Inter";
			font-size: 2rem;
			font-style: normal;
			font-weight: 400;
			line-height: 138%; /* 2.76rem */
			cursor: pointer;
		}

		.BtnContainer {
			display: flex;
			gap: 1rem;

			button {
				width: 2.625rem;
				height: 2.625rem;
				font-size: 2rem;
				border: none;
				background: transparent;
				color: #fff;
				cursor: pointer;
				transition: all 0.2s ease-in-out;

				&:hover {
					background: #1c395c;
				}
			}
		}
	}

	.CarouselWrapper {
		width: 100%;
		height: 48rem;
		overflow: hidden;

		.CarouselContainer {
			height: 100%;
			width: 100%;
			// width: 500%;
			display: flex;
			position: relative;

			input {
				display: none;
			}
		}

		.ActiveImageContainer {
			position: relative;
			min-width: 58rem;
			max-width: 58rem;
			height: 100%;
			overflow: hidden;
			transition: 0.5s;
			background: #000;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (max-width: 500px) {
				min-width: 100%;
				max-width: 100%;
			}

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
				transition: 0.5s;
			}

			video {
				width: 100%;
				height: 100%;
			}
		}

		.ImageContainer {
			position: relative;
			min-width: 20rem;
			max-width: 20rem;
			height: 100%;
			overflow: hidden;
			transition: 0.5s;
			background: #000;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
				height: 100%;
				transition: 0.5s;
			}

			video {
				width: 100%;
				height: 100%;
			}
		}

		.ActiveFloatingContainer {
			width: 46.53575rem;
			height: 5.69688rem;
			background: rgba(15, 15, 15, 0.6);
			backdrop-filter: blur(3.843660831451416px);
			position: absolute;
			bottom: 1.86rem;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;
			padding: 0 2.87rem 0 2.16rem;
			gap: 4rem;
			opacity: 1;
			transition: 0.5s;

			@media screen and (max-width: 500px) {
				min-width: 100%;
				max-width: 100%;
			}

			.Left {
				flex: 1;
				color: #fff;
				font-family: "Poppins";
				font-size: 1.125rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 1.38825rem */
			}

			.Right {
				display: flex;
				align-items: center;
				gap: 2rem;

				.Icon {
					height: 1.8rem;
					display: flex;
					align-items: center;
					gap: 0.31rem;
					overflow: hidden;
					cursor: pointer;

					.LikeIcon {
						width: 2.5rem;
						height: 2.5rem;
					}

					img {
						max-width: 100%;
						max-height: 100%;
						object-fit: cover;
					}

					p {
						width: 1.8rem;
						height: 1.8rem;
						color: #fff;
						font-family: "Poppins";
						font-size: 1.46006rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 1.80175rem */
					}
				}
			}
		}

		.DisableFloatingContainer {
			position: absolute;
			bottom: -186rem;
			transition: 0.3s;
			// // display: none;
			// background: #000;
			opacity: 0;
		}
	}
}

.Container4 {
	width: 100%;
	padding: 5.17rem 0 6.66rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;
	position: relative;

	.ShortSMS {
		width: 49.62119rem;
		height: 8rem;
		color: #fff;
		text-align: center;
		font-family: "Poppins";
		font-size: 1.375rem;
		font-style: normal;
		font-weight: 400;
		line-height: 172%; /* 2.365rem */

		@media screen and (max-width: 600px) {
			width: 100%;
			min-height: 10rem;
			padding: 0 3rem;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	h4 {
		color: #fff;
		font-family: "Poppins";
		font-size: 2.625rem;
		font-style: normal;
		font-weight: 400;
		line-height: 94.5%; /* 2.48063rem */
	}

	.EditTextIcon {
		width: 4rem;
		height: 4rem;
		position: absolute;
		top: 20%;
		left: 20%;
		cursor: pointer;
	}
}
