.TemplateWrapper {
	height: 100%;
	height: 100%;
	background: #ffffff;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template8 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		// border: 1px solid #f00;
		background: #110707;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}

	.Container1 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		// display: none;
		@media (orientation: portrait) {
			justify-content: space-between;
			padding-bottom: 9.51vh;
		}

		.landingChangeIcon {
			height: 4rem;
			width: 4rem;
			position: absolute;
			top: 2rem;
			right: 2rem;
			@media (orientation: portrait) {
				height: 3rem;
				width: 3rem;
			}
		}

		.backImage {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: -2;
			img {
				height: 100%;
				width: 100%;
			}
		}
		.asset {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: -1;
			img {
				height: 100vh;
				width: 100vw;
				// fill: rgba(0, 0, 0, 0.1);
				backdrop-filter: blur(3.79617691040039px);
			}
		}

		.logo {
			width: 12.5rem;
			height: 7.5rem;
			border: 1px dashed #cecece;
			margin-left: 5rem;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
			@media (orientation: portrait) {
				margin: 3rem;
				width: 6.25rem;
				height: 3.75rem;
			}
		}
		.content {
			padding: 0 5rem;
			padding-bottom: 5rem;
			display: flex;
			flex-direction: column;
			gap: 3rem;
			@media (orientation: portrait) {
				height: 60vh;
				padding: 1rem;
				justify-content: space-between;
			}
			.section1 {
				width: max-content;
				position: relative;
				display: flex;
				flex-direction: column;
				gap: 3rem;
				@media (orientation: portrait) {
					gap: 2rem;
				}
				.EditTextIcon {
					height: 4rem;
					width: 4rem;
					position: absolute;
					right: -1rem;
					top: -4rem;
					@media (orientation: portrait) {
						height: 3rem;
						width: 3rem;
					}
				}
				.businessName {
					width: max-content;
					color: #fff;
					font-family: Poppins;
					font-size: 5.88381rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					@media (orientation: portrait) {
						font-size: 2.625rem;
					}
				}
				.buttons {
					display: flex;
					align-items: center;
					gap: 3rem;
					@media (orientation: portrait) {
						gap: 5rem;
					}
					button {
						color: #fff;
						text-align: center;
						font-family: Poppins;
						font-size: 1.44306rem;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
						background-color: transparent;
						border: none;
						@media (orientation: portrait) {
							font-size: 1.44306rem;
						}
					}
				}
			}

			.section2 {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.leftSection {
					display: flex;
					flex-direction: column;
					gap: 3rem;
					.RatingContainer {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 7rem;
						height: 7rem;
						cursor: pointer;
						position: relative;
						@media (orientation: portrait) {
							width: 3.99925rem;
							height: 3.99925rem;
						}

						img {
							width: 100%;
							height: 100%;
						}

						p {
							color: #fff;
							font-family: Poppins;
							font-size: 1.31556rem;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							position: absolute;
							@media (orientation: portrait) {
								color: #fff;
								leading-trim: both;
								text-edge: cap;
								font-family: Poppins;
								font-size: 0.71819rem;
							}
						}
					}

					.socials {
						display: flex;
						gap: 1.2rem;
						.whatsapp,
						.phone,
						.location,
						.store,
						.mail {
							width: 1.73731rem;
							height: 1.73731rem;
							@media (orientation: portrait) {
								width: 1.2105rem;
								height: 1.2105rem;
							}

							img {
								height: 100%;
								width: 100%;
							}
						}
					}
				}
				.rightSection {
					display: flex;
					flex-direction: column;
					gap: 1rem;
					.subscribers,
					.posts {
						h2 {
							color: #fff;
							text-align: right;
							font-family: Poppins;
							font-size: 4.26325rem;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							@media (orientation: portrait) {
								font-size: 2.40556rem;
							}
						}
						h4 {
							color: #fff;
							text-align: right;
							font-family: Poppins;
							font-size: 0.97275rem;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							@media (orientation: portrait) {
								font-size: 0.54888rem;
							}
						}
					}
				}
			}
		}
		.adds {
			height: 6.74rem;
			width: 100%;
			display: flex;
			align-items: center;
			background: transparent;
			padding: 0.2rem;
			position: absolute;
			bottom: 0;
			@media (max-width: 930px) and (min-height: 600px) {
				height: 9.51vh;
			}
			.logos {
				display: flex;
				align-items: center;
				.cnbc {
					// width: 4.55769rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 16.46vw;
						height: 4.267vh;
					}
				}
				.bbc {
					// width: 6.4685rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 16.46vw;
						height: 4.267vh;
					}
				}
				.time {
					// width: 6.4685rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 70.644px;
						height: 4.267vh;
					}
				}
				.newyorkTimes {
					// width: 9.69175rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 24.65vw;
						height: 4.267vh;
					}
				}
			}
		}
	}

	.Container2 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		// display: none;

		.backImage {
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: -1;
			img {
				height: 100%;
				width: 100%;
				filter: blur(75px);
			}
		}

		.media {
			width: 42.48825rem;
			height: 22.87281rem;
			flex-shrink: 0;
			// border: 1px solid red;

			@media (orientation: portrait) {
				width: 20.99294rem;
				height: 16.31438rem;
				flex-shrink: 0;
			}
			video {
				height: 100%;
				width: 100%;
			}
		}

		.details {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			align-items: center;
			justify-content: space-around;
			position: relative;
			@media (orientation: portrait) {
			}
			.EditTextIcon {
				position: absolute;
				top: -1rem;
				right: 1rem;
				width: 4rem;
				height: 4rem;
				@media (orientation: portrait) {
					width: 3rem;
					height: 3rem;
					right: -1rem;
				}
			}
			h2 {
				color: #fff;
				font-family: Bentham;
				font-size: 4.41963rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 2.25rem;
				}
			}
			.aboutCompany {
				width: 61.41969rem;
				height: 17.17169rem;
				flex-shrink: 0;
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 1.69375rem;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				overflow-y: scroll;
				@media (orientation: portrait) {
					width: 21.57994rem;
					height: 14.41119rem;
					flex-shrink: 0;
					font-size: 1rem;
				}
				&::-webkit-scrollbar {
					display: none;
				}
			}
			button {
				cursor: pointer;
				display: inline-flex;
				padding: 1.64425rem 3.65388rem;
				justify-content: center;
				align-items: center;
				gap: 0.9135rem;
				border: 4.385px solid #f00;
				color: #fe9d9d;
				font-family: Poppins;
				font-size: 1.46156rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				background-color: transparent;
				@media (orientation: portrait) {
					padding: 1.125rem 2.5rem;
					font-size: 1rem;
				}
			}
		}
	}
	.Container3 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		@media (orientation: portrait) {
			align-items: center;
			justify-content: center;
			gap: 3rem;
		}

		.chooseusChangeIcon {
			position: absolute;
			top: 4rem;
			right: 4rem;
			@media (orientation: portrait) {
				height: 3rem;
				width: 3rem;
				top: 8rem;
			}
		}

		.backImage {
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: -1;
			img {
				height: 100%;
				width: 100%;
				filter: blur(75px);
			}
		}
		.chooseusImage {
			height: 70%;
			width: 100%;
			top: 0;
			left: 0;
			@media (orientation: portrait) {
				height: 30vh;
				width: 100%;
			}
			img {
				height: 100%;
				width: 100%;
				position: absolute;
				z-index: -1;
				@media (orientation: portrait) {
					height: 30vh;
					width: 100%;
				}
			}
			.asset {
				height: 100%;
				width: 100%;
				@media (orientation: portrait) {
					height: 100vh;
					width: 100%;
					position: absolute;
					top: 0;
				}
				img {
					height: 100%;
					width: 100%;
					@media (orientation: portrait) {
						height: 100vh;
						width: 100%;
					}
				}
			}
		}

		.content {
			height: 30%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			gap: 1rem;
			padding: 0 15rem;
			width: max-content;
			position: relative;
			@media (orientation: portrait) {
				padding: 0;
				width: max-content;
			}
			.EditTextIcon {
				position: absolute;
				height: 4rem;
				width: 4rem;
				right: 2rem;
				@media (orientation: portrait) {
					height: 3rem;
					width: 3rem;
					top: 1rem;
				}
			}
			h2 {
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 3.59794rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 2.25rem;
				}
			}
			.chooseusText {
				width: 60.24394rem;
				height: 7.14513rem;
				flex-shrink: 0;
				color: #fff;
				font-family: Poppins;
				font-size: 1.375rem;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				@media (orientation: portrait) {
					width: 85vw;
					height: auto;
					color: #fff;
					font-family: Poppins;
					font-size: 1rem;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
				}
			}
		}
	}
	.Container4 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;

		.backImage {
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: -1;
			img {
				height: 100%;
				width: 100%;
				filter: blur(75px);
			}
		}

		.topSection {
			flex: 1;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			padding: 0 5rem;
			@media (orientation: portrait) {
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				gap: 0.2rem;
			}
			h2 {
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 3.59794rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 2rem;
				}
			}
			.viewAll {
				display: flex;
				align-items: center;
				gap: 1rem;
				p {
					color: #fff;
					font-family: Poppins;
					font-size: 1.375rem;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					cursor: pointer;
					@media (orientation: portrait) {
						font-size: 1.375rem;
					}
				}
				span {
					color: #fff;
					font-family: Bebas Neue;
					font-size: 3.403rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					cursor: pointer;
					@media (orientation: portrait) {
						font-size: 3.403rem;
					}
				}
			}
		}

		.carousel {
			height: 85%;
			width: 100%;
		}
	}
	.Container5 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		position: relative;

		.backgroundImage {
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: -1;
			img {
				height: 100%;
				width: 100%;
				filter: blur(75px);
			}
		}

		.topSection {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 8rem;
			@media (orientation: portrait) {
				padding: 0 4rem;
				flex-direction: column;
				align-items: start;
				height: 10vh;
			}
			h2 {
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 3.59794rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 2rem;
				}
			}
			h4 {
				color: #fff;
				font-family: Poppins;
				font-size: 1.375rem;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 1.375rem;
				}
			}
		}

		.carouselB8 {
			height: 70vh;
			width: 95%;
			@media (orientation: portrait) {
				height: 85vh;
			}
		}

		.thankYouDesk {
			position: absolute;
			bottom: 0;
			color: #fff;
			text-align: center;
			font-family: Oooh Baby;
			font-size: 3.99725rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			@media (orientation: portrait) {
				display: none;
			}
		}
	}

	.productContainer {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		.productImage {
			margin-right: 15rem;
			margin-top: -2rem;
			width: 54.20919rem;
			height: 45.42856rem;
			height: 100%;

			// border-radius: 0.686rem;
			position: relative;
			// z-index: 1;
			@media (orientation: portrait) {
				width: 100%;
				height: 23.16631rem;
				height: 48%;
				margin: 0;
				margin-top: 20%;
			}

			img {
				height: 100%;
				width: 100%;
				object-fit: contain;
			}

			.details {
				width: 46.33213rem;
				height: 28.52769rem;
				border-radius: 1.372rem;
				background: linear-gradient(98deg, #1e1e1e 7.97%, #292929 93.58%);
				position: absolute;
				right: -15rem;
				bottom: -1rem;
				z-index: -1;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: center;
				gap: 3rem;
				@media (orientation: portrait) {
					width: 80%;
					height: 16.455rem;
					right: 10%;
					bottom: -50%;
					align-items: flex-start;
					padding-left: 2rem;
					gap: 1.2rem;
				}

				.productName {
					width: 19.3335rem;
					color: #fcba77;
					font-family: Poppins;
					font-size: 2.6875rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (orientation: portrait) {
						width: 11.44563rem;
						font-size: 1.591rem;
					}
				}
				.actions {
					display: flex;
					align-items: center;
					width: 19.3335rem;
					@media (orientation: portrait) {
						width: 11.44563rem;
						gap: 0.4rem;
					}
					.like {
						width: 3.41838rem;
						height: 3.25713rem;
						@media (orientation: portrait) {
							width: 1.52819rem;
							height: 1.45606rem;
						}
					}
					.comment {
						width: 3.59844rem;
						height: 3.07181rem;
						@media (orientation: portrait) {
							width: 1.60869rem;
							height: 1.37325rem;
						}
					}
					.share {
						width: 4.49219rem;
						height: 2.64925rem;
						@media (orientation: portrait) {
							width: 2.00819rem;
							height: 1.18431rem;
						}
					}
					.like,
					.comment,
					.share {
						img {
							height: 100%;
							width: 100%;
						}
					}
				}
			}
		}
	}

	.galleryCard {
		height: 100%;
		width: 98%;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 2.00225rem;

		.backImage {
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: -1;
			border-radius: 2.00225rem;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 2.00225rem;
			}
		}

		.profile {
			display: flex;
			align-items: center;
			gap: 0.3rem;
			padding: 2rem;
			.profilePic {
				width: 3.29456rem;
				height: 3.29456rem;
				border-radius: 50%;
				img {
					height: 100%;
					width: 100%;
				}
			}
			.detail {
				display: flex;
				flex-direction: column;
				gap: 0.1rem;
				h3 {
					color: #fff;
					font-family: Poppins;
					font-size: 1.00669rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
				h4 {
					color: #fff;
					font-family: Poppins;
					font-size: 0.54913rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}
		.details {
			width: 100%;
			height: 5.69688rem;
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(3.843660831451416px);
			display: flex;
			align-items: center;
			padding: 0 2rem;
			.desc {
				color: #fff;
				font-family: Poppins;
				font-size: 1.48725rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 1.83525rem */
				width: 28.347rem;
				height: 3.60938rem;
				flex-shrink: 0;
			}
			.actions {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 3rem;
				.like {
					width: 1.4675rem;
					height: 1.39825rem;
				}
				.comment {
					width: 2.13381rem;
					height: 1.82131rem;
				}
				.share {
					width: 2.66781rem;
					height: 1.57331rem;
				}
				.like,
				.comment,
				.share {
					display: flex;
					align-items: center;
					gap: 0.1rem;
					p {
						color: #fff;
						font-family: Poppins;
						font-size: 1.46006rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 1.80175rem */
					}
					img {
						height: 100%;
						width: 100%;
					}
				}
			}
		}
	}

	.thankYou {
		color: #fff;
		text-align: center;
		font-family: Oooh Baby;
		font-size: 2rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 2rem;
	}

	.MobileGallery {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		position: relative;

		.navigation {
			position: absolute;
			top: -3rem;
			right: 2rem;
			display: flex;
			align-items: center;
			gap: 0.2rem;

			.leftArrow {
				width: 1.85744rem;
				height: 1.85744rem;
				// border: 1px solid lime;
				background: url("./assets/leftArrow.svg");
				background-size: cover;
				background-position: center;
			}

			.rightArrow {
				width: 1.85744rem;
				height: 1.85744rem;
				// border: 1px solid rgb(47, 0, 255);
				background: url("./assets/rightArrow.svg");
				background-size: cover;
				background-position: center;
			}
		}

		.activeItem {
			position: relative;
			width: 23.18644rem;
			width: 90vw;
			height: 20.62156rem;
			height: 38vh;
			flex-shrink: 0;
			border-radius: 1.417rem;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.backImage {
				height: 100%;
				width: 100%;
				position: absolute;
				border-radius: 1.417rem;
				z-index: -1;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: 1.417rem;
				}
			}

			.profile {
				display: flex;
				align-items: center;
				gap: 0.4rem;
				padding: 0.5rem 1rem;

				.profilePic {
					width: 1.6415rem;
					height: 1.6415rem;
					border-radius: 50%;

					img {
						width: 1.6415rem;
						height: 1.6415rem;
						border-radius: 50%;
					}
				}

				.detail {
					display: flex;
					flex-direction: column;
					gap: 0.2rem;

					h3 {
						color: #fff;
						font-family: Poppins;
						font-size: 0.50156rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					h4 {
						color: #fff;
						font-family: Poppins;
						font-size: 0.27356rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}

			.details {
				display: flex;
				align-items: center;
				padding: 0 1rem;
				height: 2.83844rem;
				background: rgba(255, 255, 255, 0.2);
				backdrop-filter: blur(1.9151055812835693px);

				.desc {
					width: 14.12388rem;
					height: 1.79838rem;
					color: #fff;
					font-family: Poppins;
					font-size: 0.741rem;
					font-style: normal;
					font-weight: 400;
					line-height: 123.4%; /* 0.91444rem */
				}

				.actions {
					display: flex;
					align-items: center;
					gap: 2rem;

					.like {
						width: 0.73119rem;
						height: 0.69669rem;
					}

					.comment {
						width: 1.06325rem;
						height: 0.9075rem;
					}

					.share {
						width: 1.32925rem;
						height: 0.78394rem;
					}

					.like,
					.comment,
					.share {
						p {
							color: #fff;
							font-family: Poppins;
							font-size: 0.7275rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%; /* 0.89775rem */
						}

						display: flex;
						align-items: center;
						gap: 0.2rem;

						img {
							height: 100%;
							width: 100%;
						}
					}
				}
			}
		}

		.allItems {
			height: 30vh;
			width: 90vw;
			display: flex;
			align-items: center;
			overflow-y: scroll;
			scroll-behavior: smooth;

			&::-webkit-scrollbar {
				display: none;
			}

			.smallCard {
				height: 28vh;
				width: 43vw;
				min-width: 43vw;
				margin-right: 4vw;
				img {
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}
