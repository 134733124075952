@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.AboutMeWrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	backdrop-filter: blur(5px);
	background: rgba(0, 0, 0, 0.4);

	.AboutMe {
		width: 340px;
		min-height: 310px;
		border-radius: 13px;
		background: #2d2c2c;
		position: relative;
		box-shadow: 5px 8px 18px 5px rgba(0, 0, 0, 0.3);
		display: flex;
		flex-direction: column;
		gap: 15px;
		padding: 20px;

		.EditTextIcon {
			width: 3rem;
			height: 3rem;
			position: absolute;
			top: 5%;
			right: 5%;
			cursor: pointer;
		}

		.Row {
			p {
				color: #fefefe;
				font-family: "Poppins";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 99.5%; /* 13.93px */
				margin-bottom: 14px;
			}

			input {
				width: 100%;
				padding: 10px;
				color: #fefefe;
				font-family: "Poppins";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 99.5%; /* 13.93px */
				background: transparent;
				border: none;
				outline: none;
			}
		}

		h1 {
			color: #fefefe;
			font-family: "Poppins";
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 99.5%; /* 21.89px */
			padding: 10px;
		}
	}
}
