#carousel14 {
	height: 52.82825rem;
	max-height: 80vh;

	.alice-carousel__prev-btn {
		display: none;
	}

	.alice-carousel__next-btn {
		display: none;
	}

	.alice-carousel__dots {
		display: none;
	}
}
