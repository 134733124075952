@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bellefair&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chilanka&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bellefair&family=Italiana&family=Playball&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500;1,600&display=swap");

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "poppins", sans-serif;
}

:root {
	--base-font-size: 100%;
	font-size: var(--base-font-size);

	--font-Poppins: "Poppins", sans-serif;
	--font-Inter: "Inter", sans-serif;
	--font-Chilanka: "Chilanka", cursive;
	--font-Roboto: "Roboto", sans-serif;
	--font-Bellefair: "Bellefair", serif;
	--font-Noto: "Noto Serif", serif;

	// Template_10 colors
	--color-text: #fff;
	--color-t09-text1: #000;
	--color-t09-text2: #525252;
	--color-t09-text3: #959595;
	--color-t09-text4: #202020;
	--color-t09-text5: #8c8c8c;
	--color-t09-text6: #262626;
	--color-t09-text7: #454545;
	--color-t09-icon1: #161111;
	--color-t09-background1: #7a7a7a;
	--color-t09-background2: #fff;
	--color-t09-background3: #f7ba1f;
	--color-t09-background4: #e7e7e7;
}

#root {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.Toastify {
	position: absolute;
	z-index: 99999;
	font-size: 16px;

	.Toastify__toast-theme--colored.Toastify__toast--default,
	.Toastify__toast-theme--colored.Toastify__toast--success,
	.Toastify__toast-theme--colored.Toastify__toast--error,
	.Toastify__toast-theme--colored.Toastify__toast--warning,
	.Toastify__toast-theme--colored.Toastify__toast--info {
		border-radius: 10px !important;
		background: linear-gradient(134deg, rgba(30, 30, 30, 0.58) 0%, rgba(51, 51, 51, 0.58) 100%) !important;
		backdrop-filter: blur(2px) !important;
		border: 2px solid rgba(9, 225, 212, 1) !important;
	}

	.Toastify__toast-theme--colored.Toastify__toast--success {
		.Toastify__toast-icon {
			svg {
				fill: rgb(0, 255, 0) !important;
			}
		}
	}

	.Toastify__toast-theme--colored.Toastify__toast--error {
		.Toastify__toast-icon {
			svg {
				fill: rgb(255, 0, 0) !important;
			}
		}
	}

	.Toastify__toast-theme--colored.Toastify__toast--warning {
		.Toastify__toast-icon {
			svg {
				fill: rgb(225, 255, 0) !important;
			}
		}
	}

	.Toastify__toast-theme--colored.Toastify__toast--info {
		.Toastify__toast-icon {
			svg {
				fill: #3498db !important;
			}
		}
	}

	.Toastify__toast-body {
		background: linear-gradient(156deg, #09e1d4 0%, #00feef 100%) !important;
		background-clip: text !important;
		-webkit-background-clip: text !important;
		-webkit-text-fill-color: transparent !important;
	}
}

.react-transform-wrapper {
	width: 100% !important;
	height: 100% !important;

	.react-transform-component {
		width: 100% !important;
		height: 100% !important;

		img {
			// object-fit: fill !important;
		}
	}
}

// @media screen and (max-aspect-ratio: 1/1) {
	// when width less than height then this media query triggered
// }
