#carousel15 {
	// width: 73.235rem;
	height: 42.35569rem;
	border-radius: 2.38981rem;

	.alice-carousel__prev-btn {
		display: none;
	}

	.alice-carousel__next-btn {
		display: none;
	}

	.alice-carousel__dots {
		display: none;
	}
}
