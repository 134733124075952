.TemplateWrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template11 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		background: #1e1e1e;
		margin: 0 auto;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	// background-color: rgb(179, 111, 111);
	display: flex;
	overflow: hidden;
	position: relative;
	@media (max-width: 930px) and (min-height: 600px) {
		flex-direction: column;
		gap: 3rem;
	}
	.background {
		position: absolute;
		top: 0;
		left: 0;
		min-height: 100vh;
		width: 100vw;
		max-height: 150vh;
		z-index: 1;
		object-fit: cover;
		filter: grayscale(5);
	}

	.leftSection {
		background-color: transparent;
		z-index: 2;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 0 3rem;
		@media (max-width: 930px) and (min-height: 600px) {
			flex: 3;
			justify-content: flex-start;
			align-items: flex-end;
			padding-left: 10vw;
		}

		.hero {
			border-width: 3.123px;
			border-style: solid;
			border-color: #ffc701 transparent;
			width: 30.28394rem;
			max-width: 30vw;
			height: 55.22775rem;
			max-height: 80vh;
			position: relative;
			z-index: 1;
			//borders

			.leftOne {
				position: absolute;
				height: 5rem;
				width: 3.123px;
				left: -2.2px;
				top: -2px;
				background-color: #ffc701;
			}
			.leftTwo {
				position: absolute;
				// 55.22775rem
				height: calc(100% - 15rem);
				width: 3.123px;
				left: -2.2px;
				bottom: -2px;
				background-color: #ffc701;
			}

			.rightOne {
				position: absolute;
				height: calc(100% - 10.2rem);
				width: 3.123px;
				right: -2.2px;
				top: -2px;
				background-color: #ffc701;
			}
			.rightTwo {
				position: absolute;
				// 55.22775rem
				height: 4.5rem;
				width: 3.123px;
				right: -2.2px;
				bottom: -2px;
				background-color: #ffc701;
			}

			//border ends

			@media (max-width: 930px) and (min-height: 600px) {
				width: 19.39613rem;
				min-width: 75vw;
				height: 35.372rem;
				min-height: 60vh;
			}
			&::before {
				content: "";
				height: 22.0865rem;
				width: 13.66175rem;
				background-image: url("../Template12/assets/dotSvg.svg");
				background-position: center;
				background-size: 100% 100%;
				position: absolute;
				top: -6rem;
				right: -5rem;
				z-index: -1;
				@media (max-width: 1420px) {
					@media (max-height: 580px) {
						height: 12.0865rem;
						width: 9.66175rem;
						top: -3.5rem;
						right: -4rem;
					}
				}
				@media (max-width: 930px) and (min-height: 600px) {
					background-image: url("./assets/mobileDots.svg");
					height: 6.60694rem;
					width: 5.48963rem;
					top: -4rem;
					right: -2rem;
				}
			}
			&::after {
				content: "";
				height: 22.0865rem;
				width: 13.66175rem;
				background-image: url("../Template12/assets/dotSvg.svg");
				background-position: center;
				background-size: 100% 100%;
				position: absolute;
				bottom: -6rem;
				left: -5rem;
				z-index: -1;
				@media (max-width: 1420px) {
					@media (max-height: 580px) {
						height: 12.0865rem;
						width: 9.66175rem;
						bottom: -3.5rem;
						left: -4rem;
					}
				}
				@media (max-width: 930px) and (min-height: 600px) {
					background-image: url("./assets/mobileDots.svg");
					height: 6.60694rem;
					width: 5.48963rem;
					bottom: -3.8rem;
					left: -3rem;
				}
			}
			.coverChangeIcon {
				position: absolute;
				top: 2rem;
				right: 2rem;
				height: 3.5rem;
				width: 3.5rem;
			}

			.name {
				color: #fff;
				font-family: Secular One;
				font-size: 2.75131rem;
				font-style: normal;
				font-weight: 400;
				line-height: 103.4%; /* 2.84488rem */
				width: 10.25669rem;
				position: absolute;
				top: 5rem;
				left: -5rem;
				display: flex;
				flex-direction: column;

				span {
					font-family: Secular One;
					font-size: 2.75131rem;
					font-style: normal;
					font-weight: 400;
					line-height: 103.4%;
				}
			}

			.follower {
				position: absolute;
				bottom: 5rem;
				right: -4.3rem;
				.followerCount {
					color: #fff;
					font-family: Poppins;
					font-size: 4.49569rem;
					font-style: normal;
					font-weight: 600;
					line-height: 76.4%;
					text-align: center;
				}
				.textFollower {
					color: #fff;
					font-family: Poppins;
					font-size: 0.84681rem;
					font-style: normal;
					font-weight: 400;
					line-height: 76.4%;
					letter-spacing: 0.56313rem;
				}
			}
			.RatingContainer {
				width: 5.52rem;
				height: 5.52rem;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: -22%;
				bottom: -4%;
				cursor: pointer;
				@media (max-width: 930px) and (min-height: 600px) {
					right: -19%;
				}
				@media (max-width: 930px) and (max-height: 600px) {
					right: -25%;
					bottom: -10%;
				}

				span {
					position: absolute;
					color: #fff;
					font-family: "Poppins";
					font-size: 0.99213rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}

			img {
				height: 100%;
				width: 100%;
				// object-fit: contain;
			}
		}
	}
	.rightSection {
		z-index: 2;
		background-color: transparent;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 3rem;
		margin: auto;
		position: relative;
		padding-top: 13rem;
		@media (max-width: 930px) and (min-height: 600px) {
			padding-top: 0;
		}

		.TextareaWrapper {
			width: max-content;
			height: max-content;
			position: relative;
			.coverText {
				width: 99.91525rem;
				max-width: 40vw;
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 1.34175rem;
				font-style: normal;
				font-weight: 300;
				line-height: 174%;
				// display: -webkit-box;
				// -webkit-box-orient: vertical;
				// -webkit-line-clamp: 5;
				// overflow: hidden;
				position: relative;

				@media (max-width: 930px) and (min-height: 600px) {
					max-width: 80vw;
					// -webkit-line-clamp: 4;
				}
			}
			.EditTextIcon {
				z-index: 3;
				height: 3.5rem;
				aspect-ratio: 1/1;
				position: absolute;
				left: 0rem;
				top: -4rem;
				@media (max-width: 930px) and (min-height: 600px) {
					left: -3rem;
					top: 2rem;
				}
			}
		}

		.buttons {
			width: 99.91525rem;
			max-width: 40vw;
			display: flex;
			align-items: flex-end;
			justify-content: space-around;
			button {
				color: #fff;
				font-family: Poppins;
				font-size: 2.45669rem;
				font-style: normal;
				font-weight: 600;
				line-height: 76.4%; /* 1.87694rem */
				border: none;
				outline: none;
				background: transparent;
				cursor: pointer;
			}
			@media (max-width: 930px) and (min-height: 600px) {
				max-width: 80vw;
			}
		}
	}
}

.Container2 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	// background-color: rgb(122, 204, 159);
	display: flex;
	position: relative;
	@media (max-width: 930px) and (min-height: 600px) {
		flex-direction: column;
	}

	.leftSection {
		height: 100%;
		width: 100%;
		flex: 1;
		// background-color: #ffc701;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		gap: 3rem;
		flex-direction: column;
		padding-left: 8rem;
		// position: relative;
		// @media(max-width)
		@media (max-width: 1420px) {
			@media (max-height: 570px) {
				gap: 1rem;
				height: 100%;
			}
		}

		@media (max-width: 930px) and (min-height: 600px) {
			padding-left: 0;
			align-items: center;
		}

		.myGallery {
			position: absolute;
			bottom: 4rem;
			left: 8rem;
			z-index: 2;
			@media (max-width: 930px) and (min-height: 600px) {
				left: 10vw;
			}
			.editGalleryText {
				position: absolute;
				height: 4rem;
				aspect-ratio: 1/1;
				top: 1vh;
				left: 40rem;
				@media (max-width: 930px) and(min-height:600px) {
					bottom: 17vh;
					left: 80%;
				}
			}

			h1 {
				color: #fff;
				font-family: Secular One;
				font-size: 4.67925rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			.galleryText {
				width: 48.95063rem;
				max-width: 80vw;
				color: #fff;
				font-family: Poppins;
				font-size: 1.14788rem;
				font-style: normal;
				font-weight: 300;
				line-height: 174%; /* 1.99725rem */
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
			}
		}

		.myIntro {
			color: #fff;
			font-family: Secular One;
			font-size: 5.43775rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.TextWrapper {
			width: max-content;
			height: max-content;
			position: relative;
			.editIntroText {
				position: absolute;
				height: 4rem;
				aspect-ratio: 1/1;
				top: -4.5rem;
				right: 2rem;
				@media (max-width: 930px) and(min-height:600px) {
					right: 0;
				}
			}

			.introText {
				// border: 1px solid red;
				width: 40.10113rem;
				max-width: 80vw;
				color: #fff;
				font-family: Poppins;
				font-size: 1.33394rem;
				font-style: normal;
				font-weight: 300;
				line-height: 174%;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				overflow: hidden;
				@media (max-width: 930px) and (min-height: 600px) {
					min-width: 75vw;
				}
			}
		}

		.buttons {
			display: flex;
			gap: 4rem;
			button {
				color: #fff;
				font-family: Noto Serif;
				font-size: 2.88875rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				border: none;
				outline: none;
				background-color: transparent;
				cursor: pointer;
			}
			@media (max-width: 930px) and (min-height: 600px) {
				display: none;
			}
		}
	}

	.rightSection {
		// background-color: aqua;
		flex: 1.3;
		position: relative;
		@media (max-width: 930px) and (min-height: 600px) {
			flex: 3;
		}

		.introImages {
			height: 70%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 2rem;
			margin-top: 4rem;
			// @media (max-width: 1420px) {
			// 	@media (max-height: 580px) {
			// 		height: 90%;
			// 	}
			// }
			.introImg1,
			.introImg2 {
				width: 18.52819rem;
				height: 32.10113rem;
				max-height: 55vh;
				max-width: 25vw;
				border: 1.832px solid #ffc701;
				@media (max-width: 930px) and(min-height:600px) {
					max-width: 40vw;
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
			.introImg1 {
				margin-bottom: 4rem;
				position: relative;
				z-index: 1;
				.intro1ChangeIcon {
					position: absolute;
					bottom: -6%;
					left: -10%;
					width: 3.5rem;
					height: 3.5rem;
				}
				button {
					position: absolute;
					bottom: -5rem;
					left: 10%;
					color: #fff;
					font-family: Noto Serif;
					font-size: 2.88875rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					border: none;
					outline: none;
					background-color: transparent;
					cursor: pointer;
					white-space: nowrap;
					display: none;
					z-index: 1;
					&::before {
						content: "";
						width: 0.84738rem;
						height: 0.84738rem;
						border-radius: 50%;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						background-color: #ffaf14;
						z-index: -1;
					}
					@media (max-width: 930px) and (min-height: 600px) {
						display: block;
					}
				}

				&::before {
					content: "";
					height: 22.0865rem;
					max-height: 63.54%;
					width: 13.66175rem;
					max-width: 68.03%;
					background-image: url("../Template12/assets/dotSvg.svg");
					background-position: center;
					background-size: 100% 100%;
					position: absolute;
					top: -4rem;
					left: -4.5rem;
					z-index: -1;

					@media (max-width: 1420px) {
						@media (max-height: 580px) {
							height: 12.0865rem;
							width: 9.66175rem;
							top: -3.5rem;
							left: -4rem;
						}
					}
					@media (max-width: 930px) and (min-height: 600px) {
						background-image: url("./assets/mobileDots.svg");
						height: 6.60694rem;
						width: 5.48963rem;
						top: -4rem;
						left: -2rem;
					}
				}
			}
			.introImg2 {
				position: relative;
				z-index: 1;
				.intro2ChangeIcon {
					position: absolute;
					bottom: -6%;
					left: -10%;
					width: 3.5rem;
					height: 3.5rem;
				}

				&::before {
					content: "";
					height: 22.0865rem;
					width: 13.66175rem;
					background-image: url("../Template12/assets/dotSvg.svg");
					background-position: center;
					background-size: 100% 100%;
					position: absolute;
					bottom: -4rem;
					right: -4.5rem;
					z-index: -1;
					@media (max-width: 1420px) {
						@media (max-height: 580px) {
							height: 12.0865rem;
							width: 9.66175rem;
							bottom: -3.5rem;
							right: -4rem;
						}
					}
					@media (max-width: 930px) and (min-height: 600px) {
						background-image: url("./assets/mobileDots.svg");
						height: 6.60694rem;
						width: 5.48963rem;
						bottom: -4rem;
						right: -2rem;
					}
				}
			}
		}
	}
}

.Container3 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.myGallery {
		position: relative;
		padding-left: 9rem;
		@media (max-width: 930px) and (min-height: 600px) {
			padding-left: 4rem;
		}
		.editGalleryText {
			position: absolute;
			height: 4rem;
			aspect-ratio: 1/1;
			top: 1vh;
			left: 40rem;
			@media (max-width: 930px) and(min-height:600px) {
				bottom: 17vh;
			}
		}

		h1 {
			color: #fff;
			font-family: Secular One;
			font-size: 4.67925rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		.galleryText {
			width: 48.95063rem;
			max-width: 80vw;
			color: #fff;
			font-family: Poppins;
			font-size: 1.14788rem;
			font-style: normal;
			font-weight: 300;
			line-height: 174%; /* 1.99725rem */
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
	}

	.galleryItems {
		height: 80%;
		width: 100%;
		display: flex;
		@media (max-width: 930px) and(min-height:600px) {
			flex-direction: column;
		}
		.leftSection {
			// background-color: #5c7c9b;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (max-width: 930px) and(min-height:600px) {
				flex: 3;
			}
			.mainImage {
				width: 23.08525rem;
				height: 42.398rem;
				max-height: 90%;
				border-radius: 112.69056rem;
				border: 1.657px solid #ffaf14;
				position: relative;
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: 112.69056rem;
				}
				@media (max-width: 930px) and (min-height: 600px) {
					min-height: 60vh;
					min-width: 60vw;
				}

				.leftImg {
					width: 8.25475rem;
					height: 16.93294rem;
					border-radius: 40.29563rem;
					border: 1.657px solid #ffaf14;
					position: absolute;
					left: -20%;
					top: 20%;
					@media (max-width: 930px) and (min-height: 600px) {
						min-height: 20vh;
						min-width: 20vw;
					}
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
						border-radius: 40.29563rem;
					}
				}
				.rightImg {
					width: 8.25475rem;
					height: 16.93294rem;
					border-radius: 40.29563rem;
					border: 1.657px solid #ffaf14;
					position: absolute;
					right: -20%;
					bottom: 20%;
					@media (max-width: 930px) and (min-height: 600px) {
						min-height: 20vh;
						min-width: 20vw;
					}
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
						border-radius: 40.29563rem;
					}
				}
			}
		}
		.rightSection {
			// background-color: blueviolet;
			flex: 1.3;
			display: flex;
			flex-direction: column;
			gap: 2vh;
			align-items: center;
			justify-content: center;
			.postContainer {
				display: grid;
				grid-template-columns: repeat(4, 5.62294rem);
				grid-gap: 10px;
				grid-row-gap: 10px;
				justify-content: center;
				align-content: center;
				@media (max-width: 930px) {
					// grid-template-columns: repeat(4, 7.62294rem);
					// grid-template-rows: 1fr;
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					gap: 2vw;
					overflow: scroll;
					width: 69vw;
					padding-left: 2vw;
					.smallCards {
						img {
							width: 15vw;
							height: 15vw;
							border-radius: 50%;
						}
					}
					&::-webkit-scrollbar {
						display: none;
					}
				}
			}

			.ViewAll {
				width: max-content;
				align-self: flex-end;
				padding-right: 10rem;

				p {
					cursor: pointer;
					color: #686868;
					font-size: 1.3rem;
				}
			}
		}
	}
}

.Container4 {
	height: auto;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	// background-color: rgb(156, 60, 124);
	padding: 2rem 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	position: relative;

	h1 {
		color: #eee;
		font-family: Italiana;
		font-size: 4.2895rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.TextWrapper {
		width: max-content;
		height: max-content;
		position: relative;

		.shortMessage {
			width: 47.26275rem;
			color: #686868;
			text-align: center;
			font-family: Poppins;
			font-size: 1.90856rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
		.editShortText {
			width: 3.5rem;
			height: 3.5rem;
			aspect-ratio: 1/1;
			position: absolute;
			top: -4rem;
			left: 4rem;
		}
	}
	.thankYou {
		color: #686868;
		text-align: center;
		font-family: Noto Serif;
		font-size: 1.986rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}

.smallCards {
	img {
		width: 5.62294rem;
		height: 5.62294rem;
		border-radius: 5.62294rem;
		// @media (max-width: 930px) and (min-height: 600px) {
		// 	width: 7.62294rem;
		// 	height: 7.62294rem;
		// }
	}
}
