.InfoButtonContainer {
	position: relative;
	width: max-content;
	height: max-content;
	position: absolute;
	top: 7rem;
	left: 2rem;
	z-index: 100;
	@media screen and (max-width: 1000px) {
		display: none;
	}
}

.InfoButton {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #1e1e1e;
	border: 1px solid #00a498;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 0.5rem;
	position: relative;

	// @media screen and (max-width: 1000px) {
	// 	display: none;
	// }

	@media screen and (max-width: 800px) {
		top: 5rem;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.Tooltip {
	position: absolute;
	top: -1.2rem;
	left: 4rem;
	background: #1e1e1e;
	box-shadow: 0px 2px 7px 2px #00a498;
	padding: 2rem;
	padding-right: 3rem;
	border-radius: 1rem;
	z-index: 1000;
	width: 25vw;
	p {
		color: #fff;
		font-family: "Poppins";
		font-size: 0.8vw;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		line-clamp: 3;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
	z-index: 1;
	border-radius: 0.2rem;

	opacity: 0;
	transform: scale(0);
	transition: all ease-in-out 0.3s;
	transform-origin: 0% 3%;

	display: flex;
	flex-direction: column;
	gap: 0.2rem;

	@media (max-width: 930px) and (orientation: portrait) {
		width: 60vw;
	}

	&.Active {
		opacity: 1;
		transform: scale(1);
	}

	&::before {
		content: "";
		height: 4rem;
		width: 6rem;
		transform: rotate(45deg);
		background: #1e1e1e;
		position: absolute;
		left: -0.5rem;
		top: 1.8rem;
		z-index: -1;
	}

	.Cross {
		position: absolute;
		right: 1rem;
		top: 1rem;
		cursor: pointer;
		transition: all ease-in-out 0.3s;
		&:hover {
			svg {
				path {
					fill: #00a498;
				}
			}
		}
	}

	a {
		color: #00a498;

	}
}
