.TemplateWrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}
	textarea {
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template15 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	height: 100vh;
	width: 100vw;
	max-height: 100vh;
	max-width: 100vw;
	position: relative;
	background: var(--radialasa, radial-gradient(90.01% 40.59% at 50% 50%, #ff07ba 55.66%, #2605f3 100%));
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (orientation: portrait) {
	}
	.background {
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
		mix-blend-mode: lighten;
		img {
			height: 100%;
			width: 100%;
		}
	}

	.heroSection {
		width: 31.66088rem;
		height: 53.16444rem;
		border-radius: 1.79163rem;
		background: radial-gradient(54.36% 71.71% at 50% 50%, #ed00d5 23.44%, #0e0257 89.06%);
		box-shadow: 18.633px 21.499px 21.499px 0px rgba(0, 0, 0, 0.45);
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		justify-content: flex-end;
		z-index: 1;
		@media (orientation: portrait) {
			width: 22.0895rem;
			height: 37.09238rem;
			border-radius: 1.25rem;
		}

		.background {
			height: 100%;
			width: 100%;
			background: url("./assets/heroFrame.png");
			background-size: cover;
			background-position: center;
			mix-blend-mode: lighten;
			border-radius: 1.79163rem;
			position: absolute;
			z-index: -1;
			@media (orientation: portrait) {
				border-radius: 1.25rem;
			}
		}

		.heroImage {
			width: 24.56644rem;
			height: 33.81019rem;
			position: relative;
			z-index: 1;
			@media (orientation: portrait) {
				width: 19.27438rem;
				height: 26.52688rem;
			}

			.coverChangeIcon {
				height: 3.5rem;
				width: 3.5rem;
				position: absolute;
				bottom: 50%;
				left: 30%;
				@media (orientation: portrait) {
					height: 2.5rem;
					width: 2.5rem;
				}
			}

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

			.firstLetter {
				position: absolute;
				z-index: -1;
				top: -36%;
				left: 50%;
				transform: translateX(-50%);
				color: #fff;
				font-family: Poppins;
				font-size: 36.31169rem;
				font-style: normal;
				font-weight: 500;
				line-height: 99.5%;
				@media (orientation: portrait) {
					font-size: 26rem;
					top: -13%;
				}
			}

			.nameContainer {
				position: absolute;
				bottom: 1rem;
				left: 50%;
				transform: translateX(-50%);
				width: max-content;
				max-width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				@media (orientation: portrait) {
				}
				.iam {
					color: #fff;
					font-family: Poppins;
					font-size: 2.31169rem;
					font-style: normal;
					font-weight: 500;
					line-height: 99.5%; /* 2.30013rem */
					@media (orientation: portrait) {
						font-size: 1.07481rem;
					}
				}
				.firstName {
					color: #fff;
					font-family: Poppins;
					font-size: 5.79106rem;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					@media (orientation: portrait) {
						font-size: 1.71825rem;
					}
				}
				.lastName {
					align-self: flex-end;
					color: #fff;
					font-family: Poppins;
					font-size: 2.58075rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					@media (orientation: portrait) {
						font-size: 0.76575rem;
					}
				}
			}
		}

		.actions {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			margin-bottom: 2rem;
			@media (orientation: portrait) {
				margin-bottom: 0;
			}

			.buttons {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				button {
					color: #fff;
					font-family: Poppins;
					font-size: 1.51181rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					cursor: pointer;
					background-color: transparent;
					border: none;
					@media (orientation: portrait) {
						font-size: 1.00056rem;
					}
				}
			}

			.followers {
				display: flex;
				flex-direction: column;
				align-items: center;
				h4 {
					color: #fff;
					font-family: Poppins;
					font-size: 1.88869rem;
					font-style: normal;
					font-weight: 800;
					line-height: normal;
					@media (orientation: portrait) {
						font-size: 1.25rem;
					}
				}
				h5 {
					color: #fff;
					font-family: Poppins;
					font-size: 0.94438rem;
					font-style: normal;
					font-weight: 400;
					line-height: 165%; /* 1.55819rem */
					letter-spacing: 0.28331rem;
					@media (orientation: portrait) {
						font-size: 0.625rem;
						letter-spacing: 0.1875rem;
					}
				}
			}
		}
		.RatingContainer {
			width: 5.52rem;
			height: 5.52rem;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom: -5rem;
			right: -2rem;
			cursor: pointer;

			span {
				position: absolute;
				color: #fff;
				font-family: "Poppins";
				font-size: 0.99213rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.Container2 {
	height: 100vh;
	width: 100vw;
	max-height: 100vh;
	max-width: 100vw;
	position: relative;
	background: var(--radialasa, radial-gradient(90.01% 40.59% at 50% 50%, #ff07ba 55.66%, #2605f3 100%));
	z-index: 3;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	@media (orientation: portrait) {
	}
	.background {
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
		mix-blend-mode: lighten;
		img {
			height: 100%;
			width: 100%;
		}
	}
	.myIntro {
		color: #fff;
		font-family: Poppins;
		font-size: 4.50981rem;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		position: absolute;
		top: 2rem;
		left: 4rem;
		@media (orientation: portrait) {
			font-size: 1.71825rem;
			margin-top: 2rem;
		}
	}
	.introText {
		width: 45.02556rem;
		height: 40.16663rem;
		position: relative;
		border-radius: 1.79163rem;
		z-index: 1;
		@media (orientation: portrait) {
			width: 22.0895rem;
			height: 19.70575rem;
			border-radius: 1.25rem;
			margin-bottom: 4rem;
		}

		.introImage {
			position: absolute;
			z-index: 1;
			left: 50%;
			top: -75%;
			transform: translateX(-50%);
			width: 63.27169rem;
			height: 43.09238rem;
			@media (orientation: portrait) {
				top: -100%;
				width: 32.45738rem;
				height: 22.10569rem;
			}
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				z-index: -1;
			}

			.introChangeIcon {
				height: 3.5rem;
				width: 3.5rem;
				position: absolute;
				top: 40%;
				right: 25%;
				@media (orientation: portrait) {
					height: 2.5rem;
					width: 2.5rem;
				}
			}
		}

		.backgroundWrapper {
			background: radial-gradient(54.36% 71.71% at 50% 50%, #ed00d5 23.44%, #0e0257 89.06%);
			z-index: 2;
			position: absolute;
			height: 100%;
			width: 100%;
			border-radius: 1.79163rem;
			@media (orientation: portrait) {
				border-radius: 1.25rem;
			}
			.EditTextIcon {
				position: absolute;
				top: 2rem;
				left: 2rem;
				height: 3.5rem;
				width: 3.5rem;
				@media (orientation: portrait) {
					height: 2.5rem;
					width: 2.5rem;
					top: 0;
				}
			}
			.background1 {
				height: 100%;
				width: 100%;
				border-radius: 1.79163rem;
				background: url("./assets/introFrame.png");
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				mix-blend-mode: screen;
				border-radius: 1.79163rem;
				@media (orientation: portrait) {
					border-radius: 1.25rem;
				}
			}
		}

		.text {
			width: 38.02394rem;
			height: 23.40494rem;
			color: #fff;
			text-align: center;
			font-family: Poppins;
			font-size: 1.27806rem;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
			overflow-y: scroll;
			position: absolute;
			bottom: 5rem;
			left: 50%;
			transform: translateX(-50%);
			@media (orientation: portrait) {
				width: 18.6545rem;
				height: 11.48244rem;
				font-size: 0.627rem;
			}
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	.buttons {
		display: flex;
		justify-content: space-between;
		width: 80%;
		margin-bottom: 3rem;

		button {
			color: #fff;
			font-family: Poppins;
			font-size: 2.68575rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			background-color: transparent;
			border: none;
			cursor: pointer;
			@media (orientation: portrait) {
				font-size: 1.00056rem;
			}
		}
	}
}

.Container3 {
	height: 100vh;
	width: 100vw;
	max-height: 100vh;
	max-width: 100vw;
	position: relative;
	background: var(--radialasa, radial-gradient(90.01% 40.59% at 50% 50%, #ff07ba 55.66%, #2605f3 100%));
	z-index: 1;
	.background {
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
		mix-blend-mode: lighten;
		img {
			height: 100%;
			width: 100%;
		}
	}

	.introImages {
		height: 100%;
		width: 100%;
		display: flex;
		gap: 2rem;
		align-items: center;
		justify-content: center;
		@media (orientation: portrait) {
			gap: 0.8rem;
		}
		.left {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			@media (orientation: portrait) {
				gap: 0.8rem;
			}
			.introImg2 {
				width: 31.5rem;
				height: 25.5625rem;
				border-radius: 2.38981rem;
				position: relative;
				@media (orientation: portrait) {
					width: 10.49719rem;
					height: 20.03319rem;
					border-radius: 1.875rem;
				}
				img {
					height: 100%;
					width: 100%;
					border-radius: 2.38981rem;
					@media (orientation: portrait) {
						border-radius: 1.875rem;
					}
				}
				.introChangeIcon {
					height: 3.5rem;
					width: 3.5rem;
					position: absolute;
					top: 1rem;
					left: 2rem;
					img {
						border-radius: 0;
					}
					@media (orientation: portrait) {
						height: 2.5rem;
						width: 2.5rem;
					}
				}
			}
			.introImg3 {
				width: 31.5rem;
				height: 39.75rem;
				border-radius: 2.38981rem;
				position: relative;
				@media (orientation: portrait) {
					width: 10.49719rem;
					height: 31.17588rem;
					border-radius: 1.875rem;
				}
				img {
					height: 100%;
					width: 100%;
					border-radius: 2.38981rem;
					@media (orientation: portrait) {
						border-radius: 1.875rem;
					}
				}
				.introChangeIcon {
					height: 3.5rem;
					width: 3.5rem;
					position: absolute;
					bottom: 5rem;
					left: 2rem;
					img {
						border-radius: 0;
					}
					@media (orientation: portrait) {
						height: 2.5rem;
						width: 2.5rem;
					}
				}
			}
		}
		.right {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			@media (orientation: portrait) {
				gap: 0.8rem;
			}
			.introImg4 {
				width: 31.5rem;
				height: 44.75rem;
				border-radius: 2.38981rem;
				position: relative;
				@media (orientation: portrait) {
					width: 10.49719rem;
					height: 35.08769rem;
					border-radius: 1.875rem;
				}
				img {
					height: 100%;
					width: 100%;
					border-radius: 2.38981rem;
					@media (orientation: portrait) {
						border-radius: 1.875rem;
					}
				}
				.introChangeIcon {
					height: 3.5rem;
					width: 3.5rem;
					position: absolute;
					top: 1rem;
					left: 2rem;
					img {
						border-radius: 0;
					}
					@media (orientation: portrait) {
						height: 2.5rem;
						width: 2.5rem;
					}
				}
			}
			.introImg5 {
				width: 31.5rem;
				height: 20.375rem;
				border-radius: 2.38981rem;
				position: relative;
				@media (orientation: portrait) {
					width: 10.49719rem;
					height: 16.00281rem;
					border-radius: 1.875rem;
				}
				img {
					height: 100%;
					width: 100%;
					border-radius: 2.38981rem;
					@media (orientation: portrait) {
						border-radius: 1.875rem;
					}
				}
				.introChangeIcon {
					height: 3.5rem;
					width: 3.5rem;
					position: absolute;
					bottom: 5rem;
					left: 2rem;
					img {
						border-radius: 0;
					}
					@media (orientation: portrait) {
						height: 2.5rem;
						width: 2.5rem;
					}
				}
			}
		}
	}
}

.Container4 {
	height: auto;
	min-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	position: relative;
	background: var(--radialasa, radial-gradient(90.01% 40.59% at 50% 50%, #ff07ba 55.66%, #2605f3 100%));
	z-index: 1;
	.background {
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
		mix-blend-mode: lighten;
		img {
			height: 100%;
			width: 100%;
		}
	}

	.content {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		background: rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(7.794426441192627px);
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4rem;

		h2 {
			color: #fff;
			text-align: center;
			font-family: Poppins;
			font-size: 3.46344rem;
			font-style: normal;
			font-weight: 800;
			line-height: normal;
			margin-top: 2rem;
			@media (orientation: portrait) {
				font-size: 2.01731rem;
			}
		}

		.carousel {
			width: 73.235rem;
			height: 42.35569rem;
			border-radius: 2.38981rem;
			@media (orientation: portrait) {
				min-height: 88vh;
				max-height: 150vh;
			}
		}

		.shortMessage {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@media (orientation: portrait) {
			}

			h3 {
				color: #fff;
				font-family: Poppins;
				font-size: 3.06563rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 1.96656rem;
				}
			}

			.TextWrapper {
				width: max-content;
				height: max-content;
				position: relative;

				.EditTextIcon {
					position: absolute;
					height: 3.5rem;
					width: 3.5rem;
					top: -4rem;
					left: -1rem;
					@media (orientation: portrait) {
						top: -3rem;
						left: -1.2rem;
						height: 2.5rem;
						width: 2.5rem;
					}
				}
				.message {
					width: 29.58188rem;
					color: #f9f9f9;
					text-align: center;
					font-family: Poppins;
					font-size: 1.16919rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (orientation: portrait) {
						width: 18.97631rem;
						font-size: 0.75rem;
					}
				}
			}
		}
		.thankYou {
			color: #fff;
			text-align: center;
			font-family: Noto Serif;
			font-size: 1.7525rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding-bottom: 2rem;
			@media (orientation: portrait) {
				font-size: 1rem;
				padding-bottom: 3rem;
			}
		}
	}
}

.galleryCard {
	width: 73.235rem;
	height: 100%;
	min-height: 42.35569rem;
	border-radius: 2.38981rem;
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 2.00225rem;

	.backImage {
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
		border-radius: 2.38981rem;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 2.38981rem;
		}
		video {
			height: 100%;
			width: 100%;
			border-radius: 2.38981rem;
		}
	}

	.profile {
		display: flex;
		align-items: center;
		gap: 1rem;
		padding: 2rem 3rem;
		.profilePic {
			width: 4.56956rem;
			height: 4.56956rem;
			border-radius: 50%;
			img {
				height: 100%;
				width: 100%;
				border-radius: 50%;
			}
		}
		.detail {
			display: flex;
			flex-direction: column;
			gap: 0.1rem;
			h3 {
				color: #fff;
				font-family: Poppins;
				font-size: 1.65813rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			h4 {
				color: #fff;
				font-family: Poppins;
				font-size: 0.87369rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
	.details {
		width: 100%;
		height: 8.37431rem;
		border-radius: 0rem 0rem 2.82519rem 3.5315rem;
		background: rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(3.822908401489258px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 4rem;
		.desc {
			color: #fff;
			font-family: Poppins;
			font-size: 1.37063rem;
			font-style: normal;
			font-weight: 400;
			line-height: 123.4%; /* 1.69131rem */
			width: 19.62738rem;
			height: 3.32631rem;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
		.actions {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 2rem;
			.like {
				display: flex;
				align-items: center;
				justify-content: center;

				.likeIcon {
					// width: 4.32rem;
					width: 4rem;
					aspect-ratio: 1/1;
					display: flex;
					align-items: center;
					cursor: pointer;

					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}

					.likeCount {
						color: #fff;
						font-family: Poppins;
						font-size: 2.6rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%;
					}
				}
			}
			.comment {
				width: 3.33563rem;
				height: 2.84744rem;
			}
			.share {
				width: 4.17081rem;
				height: 2.45969rem;
			}

			.comment,
			.share {
				display: flex;
				align-items: center;
				gap: 0.1rem;
				p {
					color: #fff;
					font-family: Poppins;
					font-size: 2.28269rem;
					font-style: normal;
					font-weight: 600;
					line-height: 123.4%; /* 2.81681rem */
				}
				img {
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}

.MobileGallery {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	position: relative;

	.activeItem {
		position: relative;
		width: 23.18644rem;
		width: 90vw;
		height: 20.62156rem;
		height: 58vh;
		flex-shrink: 0;
		border-radius: 1.417rem;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.backImage {
			height: 100%;
			width: 100%;
			position: absolute;
			border-radius: 1.417rem;
			z-index: -1;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 1.417rem;
			}
		}

		.profile {
			display: flex;
			align-items: center;
			gap: 0.4rem;
			padding: 0.5rem 1rem;

			.profilePic {
				width: 1.6415rem;
				height: 1.6415rem;
				border-radius: 50%;

				img {
					width: 1.6415rem;
					height: 1.6415rem;
					border-radius: 50%;
				}
			}

			.detail {
				display: flex;
				flex-direction: column;
				gap: 0.2rem;

				h3 {
					color: #fff;
					font-family: Poppins;
					font-size: 0.50156rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				h4 {
					color: #fff;
					font-family: Poppins;
					font-size: 0.27356rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}

		.details {
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding: 0 1rem;
			height: 2.83844rem;
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(1.9151055812835693px);

			.desc {
				width: 14.12388rem;
				height: 1.79838rem;
				color: #fff;
				font-family: Poppins;
				font-size: 0.741rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 0.91444rem */
			}

			.actions {
				display: flex;
				align-items: center;
				gap: 2rem;

				.like {
					display: flex;
					align-items: center;
					justify-content: center;

					.likeIcon {
						// width: 4.32rem;
						width: 1.42rem;
						aspect-ratio: 1/1;
						display: flex;
						align-items: center;
						cursor: pointer;

						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}

						.likeCount {
							color: #fff;
							font-family: Poppins;
							font-size: 1rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%;
						}
					}
				}

				.comment {
					width: 1.06325rem;
					height: 0.9075rem;
				}

				.share {
					width: 1.32925rem;
					height: 0.78394rem;
				}

				.comment,
				.share {
					p {
						color: #fff;
						font-family: Poppins;
						font-size: 0.7275rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 0.89775rem */
					}

					display: flex;
					align-items: center;
					gap: 0.2rem;

					img {
						height: 100%;
						width: 100%;
					}
				}
			}
		}
	}

	.allItems {
		height: 30vh;
		width: 96vw;
		display: flex;
		align-items: center;
		overflow-y: scroll;
		scroll-behavior: smooth;

		&::-webkit-scrollbar {
			display: none;
		}

		.smallCard {
			height: 20vh;
			width: 31vw;
			min-width: 31vw;
			margin-right: 2vw;
			border-radius: 1.875rem;
			img {
				height: 100%;
				width: 100%;
				border-radius: 1.875rem;
			}
		}
	}
}
