.Gallery {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: #020305;

	.Top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 3rem 2rem 3.2rem;

		@media screen and (max-width: 450px) {
			padding: 1rem;
		}

		.Left {
			h1 {
				color: #fff;
				font-family: "Poppins";
				font-size: 4.18rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 1.8rem;
			}

			.ButtonWrapper {
				display: flex;
				align-items: center;
				gap: 1.4rem;

				@media screen and (max-width: 450px) {
					gap: 1rem;
				}

				button {
					// min-width: 10rem;
					height: 3.5rem;
					padding: 0rem 2.5rem;
					border: none;
					cursor: pointer;
					border-radius: 4rem;
					background: rgba(255, 255, 255, 0.05);
					color: #666;
					font-family: "Poppins";
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					transition: all 0.3s ease-in-out;

					&:hover {
						color: #fff;
						background: rgba(255, 255, 255, 0.1);
					}
					&.activeBtn {
						color: #fff;
						background: rgba(255, 255, 255, 0.1);
					}
				}
			}
		}

		.Right {
			width: 3rem;
			height: 3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}

	.Container {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		background: rgba(217, 217, 217, 0.05);
		padding: 3.1rem 4.5rem;
		row-gap: 0.6rem;
		column-gap: 0.6rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		
		@media screen and (max-width: 550px) {
			padding: 3.1rem 2rem;
		}
		
		@media screen and (max-width: 850px) {
			grid-template-columns: repeat(2, 1fr);
		}
		
		@media screen and (max-width: 500px) {
			grid-template-columns: repeat(1, 1fr);
		}

		&::-webkit-scrollbar {
			display: none;
		}

		h1 {
			color: #fff;
			font-size: 3rem;
		}

		.postWrapper {
			width: 100%;
			height: 21rem;
			overflow: hidden;
			background: #000000;
			display: flex;
			align-items: center;
			justify-content: center;
			
					
			.Media {
				width: 100%;
				height: 100%;
				object-fit: contain;
				// width: 33rem;
				// height: 21rem;
				// max-width: 100%;
				// min-height: 20rem;
				// max-height: 55rem;
			}
		}
	}
}
