.TemplateWrapper {
	height: 100%;
	height: 100%;
	background: #ffffff;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template7 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		// border: 1px solid #f00;
		background: #000;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}

	.Container1 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		z-index: 1;

		.background {
			width: 100%;
			height: 100%;
			position: absolute;
			background: linear-gradient(0deg, rgba(212, 168, 9, 0.52) 0%, rgba(212, 168, 9, 0.52) 100%), lightgray 50% / cover no-repeat;
			filter: blur(13.5px);
			top: 0;
			left: 0;
			z-index: -1;
			img {
				height: 100%;
				width: 100%;
			}
		}

		.mainSection {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-around;
			@media (orientation: portrait) {
				flex-direction: column;
				justify-content: center;
				gap: 3rem;
				padding-top: 6rem;
			}
			.section1 {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 7rem;
				@media (orientation: portrait) {
					order: 2;
				}
				.logo {
					width: 12.5rem;
					height: 7.5rem;
					border: 1px dashed #fff;
					@media (orientation: portrait) {
						width: 6.25rem;
						height: 3.75rem;
						position: absolute;
						top: 2rem;
						left: 2rem;
					}
					img {
						height: 100%;
						width: 100%;
					}
				}

				.socials {
					width: 4.25rem;
					height: 31.125rem;
					flex-shrink: 0;
					border-radius: 1.375rem;
					background: rgba(0, 0, 0, 0.05);
					backdrop-filter: blur(10px);
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-around;
					@media (orientation: portrait) {
						width: 15.9375rem;
						height: 2.875rem;
						border-radius: 1.25rem;
						background: rgba(0, 0, 0, 0.08);
						backdrop-filter: blur(10px);
						flex-direction: row;
					}
					.whatsapp,
					.mail,
					.location,
					.store,
					.phone {
						img {
							width: 2.53494rem;
							height: 2.53494rem;
							cursor: pointer;
							@media (orientation: portrait) {
								width: 1.2105rem;
								height: 1.2105rem;
							}
						}
					}
				}
			}
			.section2 {
				.heroSection {
					width: 73.74381rem;
					height: 53.36906rem;
					box-shadow: -10px 10px 30px 0px rgba(0, 0, 0, 0.25);
					position: relative;
					z-index: 1;
					display: flex;
					@media (orientation: portrait) {
						width: 24.89356rem;
						height: 29.82906rem;
					}
					.landingChangeIcon {
						height: 4rem;
						width: 4rem;
						position: absolute;
						cursor: pointer;
						top: 2rem;
						left: 2rem;
						@media (orientation: portrait) {
							height: 3rem;
							width: 3rem;
						}
					}
					.backImage {
						height: 100%;
						width: 100%;
						position: absolute;
						z-index: -1;
						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}
					.details {
						align-self: flex-end;
						display: flex;
						align-items: center;
						justify-content: space-around;
						width: 100%;
						height: 14.77744rem;
						flex-shrink: 0;
						background: linear-gradient(180deg, rgba(254, 214, 28, 0) 0%, #fed61c 61.09%);
						position: relative;
						@media (orientation: portrait) {
							flex-direction: column;
							height: 8.41069rem;
						}
						.EditTextIcon {
							height: 4rem;
							width: 4rem;
							position: absolute;
							top: -1rem;
							left: 15%;
							@media (orientation: portrait) {
								height: 3rem;
								width: 3rem;
								top: -4rem;
								left: 45%;
							}
						}
						.businessName {
							color: #000;
							font-family: Playfair Display;
							font-size: 4.431rem;
							font-style: normal;
							font-weight: 400;
							line-height: 123.5%; /* 5.47231rem */
							@media (orientation: portrait) {
								font-size: 2.625rem;
							}
						}
						.buttons {
							display: flex;
							flex-direction: column;
							gap: 1rem;
							@media (orientation: portrait) {
								flex-direction: row;
								justify-content: space-around;
								width: 100%;
							}
							button {
								color: #000;
								font-family: Poppins;
								font-size: 1.61888rem;
								font-style: normal;
								font-weight: 400;
								line-height: 123.5%; /* 1.99931rem */
								border: none;
								background: transparent;
								cursor: pointer;
								@media (orientation: portrait) {
									font-size: 1rem;
								}
							}
						}
					}
				}
			}
			.section3 {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 3rem;
				position: relative;
				@media (orientation: portrait) {
					flex-direction: row;
					order: 3;
				}
				.posts,
				.subscribers {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 0.2rem;
					.avatar {
						width: 3.1875rem;
						height: 3.1875rem;
						flex-shrink: 0;
						border-radius: 50%;
						background-color: #dde4d7;
						display: flex;
						align-items: center;
						justify-content: center;
						@media (orientation: portrait) {
							width: 1.59731rem;
							height: 1.59731rem;
						}
						img {
							height: 1.5rem;
							width: 1.5rem;
							@media (orientation: portrait) {
								width: 0.75169rem;
								height: 0.75169rem;
							}
						}
					}
					p {
						color: #fff;
						text-align: center;
						font-family: Poppins;
						font-size: 1.54394rem;
						font-style: normal;
						font-weight: 500;
						line-height: 118.5%; /* 1.82956rem */
						letter-spacing: 0.14669rem;
						text-transform: uppercase;
						@media (orientation: portrait) {
							font-size: 0.77369rem;
						}
					}
				}

				.RatingContainer {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 7rem;
					height: 7rem;
					cursor: pointer;
					position: relative;
					@media (orientation: portrait) {
						width: 3.99925rem;
						height: 3.99925rem;
					}

					img {
						width: 100%;
						height: 100%;
					}

					p {
						color: #fff;
						font-family: Poppins;
						font-size: 1.31556rem;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						position: absolute;
						@media (orientation: portrait) {
							color: #fff;
							leading-trim: both;
							text-edge: cap;
							font-family: Poppins;
							font-size: 0.71819rem;
						}
					}
				}
			}
		}
		.adds {
			height: 4.74rem;
			width: 100%;
			display: flex;
			align-items: center;
			background: #2f2f30;
			padding: 0.2rem;
			@media (max-width: 930px) and (min-height: 600px) {
				height: 9.51vh;
			}
			.logos {
				display: flex;
				align-items: center;
				.cnbc {
					// width: 4.55769rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 16.46vw;
						height: 4.267vh;
					}
				}
				.bbc {
					// width: 6.4685rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 16.46vw;
						height: 4.267vh;
					}
				}
				.time {
					// width: 6.4685rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 70.644px;
						height: 4.267vh;
					}
				}
				.newyorkTimes {
					// width: 9.69175rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 24.65vw;
						height: 4.267vh;
					}
				}
			}
		}
	}

	.Container2 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		@media (orientation: portrait) {
			flex-direction: column;
		}

		.leftSection {
			width: 50%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (orientation: portrait) {
				width: 100%;
			}
			.aboutusImage {
				width: 34.02531rem;
				height: 40.30338rem;
				flex-shrink: 0;
				position: relative;
				z-index: 1;
				@media (orientation: portrait) {
					width: 18.14188rem;
					height: 21.48925rem;
				}
				.aboutusChangeIcon {
					height: 4rem;
					width: 4rem;
					position: absolute;
					cursor: pointer;
					top: 2rem;
					left: 2rem;
					@media (orientation: portrait) {
						height: 3rem;
						width: 3rem;
					}
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					top: -5rem;
					right: -4rem;
					height: 10rem;
					width: 10rem;
					background: url("./assets/dots.svg") center/cover no-repeat;
					@media (orientation: portrait) {
						width: 5.93631rem;
						height: 5.8475rem;
						top: -2.5rem;
						right: -2.3rem;
						background: url("./assets/smallDots.svg") center/cover no-repeat;
					}
				}
				&::after {
					content: "";
					position: absolute;
					z-index: -1;
					bottom: -5rem;
					left: -4rem;
					height: 10rem;
					width: 10rem;
					background: url("./assets/dots.svg") center/cover no-repeat;
					@media (orientation: portrait) {
						width: 5.93631rem;
						height: 5.8475rem;
						bottom: -2.5rem;
						left: -2.3rem;
						background: url("./assets/smallDots.svg") center/cover no-repeat;
					}
				}

				.yellowBox {
					width: 24.86663rem;
					height: 28.33594rem;
					flex-shrink: 0;
					background: #ffd71c;
					position: absolute;
					right: -8rem;
					bottom: -5rem;
					z-index: -1;
					@media (orientation: portrait) {
						width: 13.25856rem;
						height: 15.10838rem;
						right: -3rem;
						bottom: -2rem;
					}
				}
			}
		}
		.rightSection {
			width: 50%;
			height: 100%;
			padding: 2rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 2rem;
			justify-content: center;
			position: relative;
			@media (orientation: portrait) {
				width: 100%;
			}

			.EditTextIcon {
				height: 4rem;
				width: 4rem;
				position: absolute;
				right: 14rem;
				top: 32rem;
				@media (max-width: 930px) and (min-height: 600px) {
					height: 3rem;
					width: 3rem;
					right: 1rem;
					top: 9rem;
				}
			}
			h2 {
				color: #fefefe;
				text-align: center;
				font-family: Playfair Display;
				font-size: 5.28144rem;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 2.3325rem;
				}
			}
			.aboutusText {
				width: 40.04469rem;
				color: #dedede;
				font-family: Poppins;
				font-size: 1.625rem;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 0.97369rem;
					width: 22.76163rem;
				}
			}
			button {
				display: inline-flex;
				padding: 1.125rem 2.5rem;
				justify-content: center;
				align-items: center;
				gap: 0.625rem;
				border: 3px solid #ffd71c;
				color: #ffd71c;
				font-family: Poppins;
				font-size: 1.625rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				background-color: transparent;
				cursor: pointer;
				@media (orientation: portrait) {
					font-size: 0.875rem;
				}
			}
		}
	}
	.Container3 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		@media (orientation: portrait) {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 5rem;
		}

		.leftSection {
			width: 60%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (orientation: portrait) {
				width: 100%;
			}
			.chooseusChangeIcon {
				height: 4rem;
				width: 4rem;
				cursor: pointer;
				position: absolute;
				top: 2rem;
				right: 2rem;
				@media (max-width: 930px) and (min-height: 600px) {
					height: 3rem;
					width: 3rem;
				}
			}
			.content {
				width: 62.30869rem;
				height: 62.30869rem;
				position: relative;
				overflow: hidden;
				z-index: 1;
				@media (orientation: portrait) {
					width: 24.25713rem;
					height: 24.25713rem;
				}
				.backImg {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;

					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
				.frame {
					width: 100%;
					height: 100%;
					flex-shrink: 0;
					stroke-width: 7.939px;
					stroke: #000;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.rightSection {
			width: 40%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 3rem;
			margin: auto;
			position: relative;
			@media (orientation: portrait) {
				width: 100%;
				padding: 0 2rem;
			}

			.EditTextIcon {
				height: 4rem;
				width: 4rem;
				position: absolute;
				top: 22rem;
				right: 4rem;
				@media (max-width: 930px) and (min-height: 600px) {
					height: 3rem;
					width: 3rem;
					top: 6rem;
				}
			}
			h2 {
				color: #fefefe;
				text-align: center;
				font-family: Playfair Display;
				font-size: 4.25rem;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				@media (orientation: portrait) {
					font-size: 2.65981rem;
				}
			}
			.chooseusText {
				width: 36.41188rem;
				height: 30.63469rem;
				flex-shrink: 0;
				color: #dedede;
				font-family: Poppins;
				font-size: 1.75713rem;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				@media (orientation: portrait) {
					width: 22.78794rem;
					height: 19.17238rem;
					font-size: 1.09969rem;
				}
			}
		}
	}
	.Container4 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		.topSection {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 2rem 10rem;
			@media (orientation: portrait) {
				padding: 0;
				flex-direction: column;
				gap: 2rem;
				align-items: flex-start;
				padding-left: 2rem;
			}
			h2 {
				color: #fff;
				font-family: Playfair Display;
				font-size: 3.64588rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.5%;
				position: relative;
				z-index: 1;
				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					top: -3rem;
					left: -1.3rem;
					width: 6.24175rem;
					height: 6.14819rem;
					background: url("./assets/smallDots.svg") center/cover no-repeat;
					@media (orientation: portrait) {
						width: 3.46106rem;
						height: 3.40919rem;
						top: -1.7rem;
						left: -0.8rem;
						background: url("./assets/smallDots.svg") center/cover no-repeat;
					}
				}
				@media (orientation: portrait) {
					font-size: 2.02169rem;
				}
			}
			p {
				color: #fff;
				font-family: Playfair Display;
				font-size: 1.34894rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.5%;
				@media (orientation: portrait) {
					font-size: 1.34894rem;
				}
			}
		}

		.products {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 2rem;
			flex-wrap: wrap;
			width: 80rem;
			@media (orientation: portrait) {
				width: 27rem;
				row-gap: 3rem;
			}
			.productCard {
				display: flex;
				flex-direction: column;
				gap: 0.3rem;
				img {
					width: 22.69294rem;
					height: 17.08488rem;
					@media (orientation: portrait) {
						width: 10.61731rem;
						height: 7.9935rem;
					}
				}
				h3 {
					width: 100%;
					color: #fff;
					font-family: Poppins;
					font-size: 1.591rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (orientation: portrait) {
						font-size: 0.74438rem;
					}
				}
				.actions {
					display: flex;
					gap: 0.5rem;
					.like {
						width: 1.52819rem;
						height: 1.45606rem;
						@media (orientation: portrait) {
							width: 0.715rem;
							height: 0.68125rem;
						}
					}
					.comment {
						width: 1.60869rem;
						height: 1.37325rem;
						@media (orientation: portrait) {
							width: 0.75263rem;
							height: 0.6425rem;
						}
					}
					.share {
						width: 2.00819rem;
						height: 1.18431rem;
						@media (orientation: portrait) {
							width: 0.93956rem;
							height: 0.55413rem;
						}
					}

					.like,
					.comment,
					.share {
						cursor: pointer;
						img {
							height: 100%;
							width: 100%;
						}
					}
				}
			}
		}
	}
	.Container5 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;

		.topSection {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 0 10rem;
			@media (orientation: portrait) {
				flex-direction: column;
				gap: 1.2rem;
				padding: 0;
				align-items: flex-start;
				padding-left: 2rem;
			}
			h2 {
				color: #fff;
				font-family: Playfair Display;
				font-size: 6.96344rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.5%;
				position: relative;
				z-index: 1;
				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					top: -1rem;
					left: -3.3rem;
					width: 6.24175rem;
					height: 6.14819rem;
					background: url("./assets/smallDots.svg") center/cover no-repeat;
					@media (orientation: portrait) {
						width: 3.46106rem;
						height: 3.40919rem;
						top: -1.7rem;
						left: -0.8rem;
						background: url("./assets/smallDots.svg") center/cover no-repeat;
					}
				}
				@media (orientation: portrait) {
					font-size: 2.02169rem;
				}
			}
			p {
				color: #fff;
				font-family: Playfair Display;
				font-size: 1.3915rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.5%;
				cursor: pointer;
			}
		}

		.carousel {
			width: 90%;
			height: 30.7145rem;
		}

		.thankYou {
			color: #fff;
			text-align: center;
			font-family: NotoSerifTamilSlanted;
			font-size: 2.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			@media (orientation: portrait) {
				font-size: 2.0075rem;
			}
		}
	}

	.postMainContainer {
		width: 51.80419rem;
		width: 97%;
		// width: 25.67181rem;
		height: 30.7145rem;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 1;
		border-radius: 2.1105rem;

		@media (max-width: 930px) and (min-height: 600px) {
			// width: 20.94394rem;
			width: 88%;
			height: 15.49106rem;
			flex-shrink: 0;
		}

		.media {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
			border-radius: 2.1105rem;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 2.1105rem;
			}
		}

		.profile {
			display: flex;
			align-items: center;
			gap: 1vw;
			padding: 1vh 2vw;
			img {
				width: 2.44494rem;
				height: 2.44494rem;
				border-radius: 50%;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 2.0175rem;
					height: 2.0175rem;
				}
			}
			.profileDetails {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-around;
				h4 {
					color: #fff;
					font-family: Poppins;
					font-size: 0.74706rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					@media (max-width: 930px) and (min-height: 600px) {
						font-size: 0.99063rem;
					}
				}
				h5 {
					color: #fff;
					font-family: Poppins;
					font-size: 0.4075rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (max-width: 930px) and (min-height: 600px) {
						font-size: 0.38575rem;
						letter-spacing: 0.06175rem;
					}
				}
			}
		}

		.details {
			display: flex;
			align-items: center;
			justify-content: space-around;
			width: 100%;
			height: 4.29156rem;
			padding: 0 1rem;
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(2.9533538818359375px);
			@media (max-width: 930px) and (min-height: 600px) {
				width: 100%;
				height: 1.735rem;
			}
			p {
				width: 21.03663rem;
				height: 2.67856rem;
				color: #fff;
				font-family: Poppins;
				font-size: 1.10369rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 1.362rem */
				@media (max-width: 930px) and (min-height: 600px) {
					width: 5.83525rem;
					height: 0.743rem;
					font-size: 0.30613rem;
				}
			}

			.actions {
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 2rem;
				@media (max-width: 930px) and (min-height: 600px) {
					gap: 1rem;
				}
				.like,
				.comment,
				.share {
					display: flex;
					align-items: center;
					gap: 0.3rem;
					p {
						color: #fff;
						font-family: Poppins;
						font-size: 1.08356rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 1.33713rem */
						width: max-content;
						height: max-content;

						@media (max-width: 930px) and (min-height: 600px) {
							font-size: 0.74944rem;
						}
					}
				}

				.like {
					img {
						width: 1.089rem;
						height: 1.03769rem;
						@media (max-width: 930px) and (min-height: 600px) {
							width: 0.75319rem;
							height: 0.71769rem;
						}
					}
				}
				.comment {
					img {
						width: 1.5835rem;
						height: 1.35163rem;
						@media (max-width: 930px) and (min-height: 600px) {
							width: 1.09513rem;
							height: 0.93488rem;
						}
					}
				}
				.share {
					img {
						width: 1.97981rem;
						height: 1.16756rem;
						@media (max-width: 930px) and (min-height: 600px) {
							width: 1.36931rem;
							height: 0.80756rem;
						}
					}
				}
			}
		}
	}

	.mobileCarousel {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3rem;
		.activeItem {
			width: 20.65031rem;
			height: 18.36594rem;
			border-radius: 1.262rem;
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.backImg {
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: -1;
				img {
					height: 100%;
					width: 100%;
				}
			}

			.profile {
				display: flex;
				gap: 0.3rem;
				padding: 1rem;
				img {
					width: 1.58088rem;
					height: 1.58088rem;
					flex-shrink: 0;
					border-radius: 1.58088rem;
				}
				.nameDetail {
					display: flex;
					flex-direction: column;
					h3 {
						color: #fff;
						font-family: Poppins;
						font-size: 0.48306rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
					p {
						color: #fff;
						font-family: Poppins;
						font-size: 0.2635rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}

			.details {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 20.65031rem;
				height: 2.528rem;
				background: rgba(255, 255, 255, 0.2);
				backdrop-filter: blur(1.7056283950805664px);
				padding: 0 1rem;
				.desc {
					width: 11.60219rem;
					height: 1.73194rem;
					flex-shrink: 0;
					color: #fff;
					font-family: Poppins;
					font-size: 0.71369rem;
					font-style: normal;
					font-weight: 400;
					line-height: 123.4%; /* 0.88063rem */
				}

				.actions {
					display: flex;
					align-items: center;
					gap: 1rem;
					.like,
					.comment,
					.share {
						display: flex;
						align-items: center;
						p {
							color: #fff;
							font-family: Poppins;
							font-size: 0.70063rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%; /* 0.86456rem */
						}
					}
					.like {
						img {
							width: 0.70419rem;
							height: 0.67094rem;
						}
					}
					.comment {
						img {
							width: 1.02388rem;
							height: 0.87394rem;
						}
					}
					.share {
						img {
							width: 1.28013rem;
							height: 0.75494rem;
						}
					}
				}
			}
		}

		.allItems {
			display: flex;
			align-items: flex-start;
			gap: 1rem;
			width: 21rem;
			height: 12.03863rem;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				display: none;
			}

			.postCards {
				width: 10rem;
				min-width: 10rem;
				height: 12.03863rem;
				border-radius: 0.82719rem;
				position: relative;
				z-index: 1;

				.backImage {
					width: 100%;
					height: 100%;
					position: absolute;
					z-index: -1;
					img {
						height: 100%;
						width: 100%;
					}
				}
				.profile {
					display: flex;
					gap: 0.3rem;
					padding: 1rem;
					img {
						width: 1.03963rem;
						height: 1.03963rem;
						flex-shrink: 0;
						border-radius: 1.03963rem;
					}
					.nameDetail {
						display: flex;
						flex-direction: column;
						h3 {
							color: #fff;
							font-family: Poppins;
							font-size: 0.31769rem;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
						p {
							color: #fff;
							font-family: Poppins;
							font-size: 0.17325rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
				}
			}
		}
	}
}
