.ImageUpload {
	position: absolute;
	z-index: 50 !important;
	width: 3.5rem;
	height: 3.5rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	.Icon {
		width: 100% !important;
		height: 100% !important;
	}
}
