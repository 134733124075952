.Template10Wrapper {
	height: 100vw;
	height: 100vh;
	scrollbar-width: none;
	background: var(--frsd, radial-gradient(50% 50% at 50% 50%, #0e2138 0%, #0a1b30 100%));
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template10 {
		// width: 1728px;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	width: 100%;
	height: 100vh;
	position: relative;
	scroll-behavior: smooth;

	.RatingCon {
		width: 5.52rem;
		height: 5.52rem;
		position: absolute;
		left: 2.6%;
		top: 6%;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		span {
			color: #fff;
			font-family: "Poppins";
			font-size: 1.1rem;
			position: absolute;
			text-align: center;

			@media screen and (min-width: 3840px) {
				font-size: 3rem;
				position: absolute;
				left: 43%;
			}
			@media screen and (min-width: 2048px) and (max-width: 3000px) {
				font-size: 2rem;
				position: absolute;
				left: 43%;
			}
			@media screen and (min-width: 1920px) and (max-height: 1440px) {
				font-size: 2rem;
			}
			@media screen and (min-width: 1856px) and (max-height: 1392px) {
				font-size: 1.9rem;
			}
			@media screen and (min-width: 1792px) and (max-height: 1344px) {
				font-size: 1.8rem;
			}
		}

		img {
			width: 100%;
			height: 100%;
		}

		@media screen and (min-width: 3840px) {
			width: 15rem;
			height: 15rem;
			position: absolute;
			left: 3.5%;
			top: 6%;
			z-index: 10;
		}
		@media screen and (min-width: 2048px) and (max-width: 3000px) {
			width: 10rem;
			height: 10rem;
			position: absolute;
			left: 3.5%;
			top: 6%;
			z-index: 10;
		}
		@media screen and (min-width: 1920px) and (max-height: 1440px) {
			width: 10rem;
			height: 10rem;
			position: absolute;
			top: 5%;
		}
		@media screen and (min-width: 1856px) and (max-height: 1392px) {
			width: 8rem;
			height: 8rem;
			position: absolute;
			top: 5%;
		}
		@media screen and (min-width: 1792px) and (max-height: 1344px) {
			width: 8rem;
			height: 8rem;
			position: absolute;
			top: 5%;
		}
	}

	.CoverUploadBtn {
		top: 15%;
		right: 10%;
	}

	.ImageContainer img {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		z-index: 1;
	}

	.BlurOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		backdrop-filter: blur(5px);
		z-index: 2;

		@media screen and (min-width: 3840px) {
			backdrop-filter: blur(1.8rem);
		}
		@media screen and (min-width: 2048px) and (max-width: 3000px) {
			backdrop-filter: blur(1.5rem);
		}
	}

	// .DarkOverlay1{
	//   position: absolute;
	//   top: 0;
	//   left: 0%;
	//   width: 50%;
	//   height: 100%;
	//   z-index: 2;
	//   background: linear-gradient(to right, transparent, transparent 50%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.5));

	//   @media screen and (max-width:1956px) {
	//     left: 0.010%;
	//   }
	//   @media screen and(min-width:1400px) and (max-width:1401px) {
	//     left: 0.050%;
	//   }
	// }

	.DarkOverlay2 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background: linear-gradient(to left, transparent, transparent 50%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.5));
	}

	.BorderBox {
		border: 8px solid white;
		border-radius: 0px 95px 0px 95px;
		z-index: 2;
		width: 96%;
		height: 93%;
		position: absolute;
		top: 3%;
		left: 1.5%;

		@media screen and (min-width: 3840px) {
			border: 1.8rem solid white;
			border-radius: 0px 400px 0px 400px;
		}
		@media screen and (min-width: 2048px) and (max-width: 3000px) {
			border: 1.2rem solid white;
			border-radius: 0px 250px 0px 250px;
		}
		@media screen and (min-width: 1920px) and (max-height: 1440px) {
			border: 1rem solid white;
			border-radius: 0px 250px 0px 250px;
		}
		@media screen and (min-width: 1920px) and (max-height: 1200px) {
			border: 0.8rem solid white;
			border-radius: 0px 220px 0px 220px;
		}
		@media screen and (min-width: 1920px) and (max-height: 1080px) {
			border: 0.8rem solid white;
			border-radius: 0px 200px 0px 200px;
		}
		@media screen and (min-width: 1856px) and (max-height: 1392px) {
			border: 0.8rem solid white;
			border-radius: 0px 200px 0px 200px;
		}
		@media screen and (min-width: 1792px) and (max-height: 1344px) {
			border: 0.8rem solid white;
			border-radius: 0px 200px 0px 200px;
		}
	}

	.ContentBox {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		width: 95%;
		height: 83%;
		position: absolute;
		left: 4%;
		bottom: 8%;

		.left {
			display: flex;
			justify-content: space-between;
			font-size: 1.5rem;
			width: 31%;

			@media screen and (max-width: 800px) {
				width: 100%;
			}
		}

		.left button {
			background: transparent;
			border: none;
			color: #fff;
			font-family: "Poppins", sans-serif;
			font-size: 120%;
			font-style: italic;
			cursor: pointer;
			height: 100%;
			padding: 0rem 1rem;
			letter-spacing: 0.2rem;

			@media screen and (min-width: 3840px) {
				font-size: 5rem;
				letter-spacing: 0.5rem;
			}
			@media screen and (min-width: 2048px) and (max-width: 3000px) {
				font-size: 2.5rem;
				letter-spacing: 0.5rem;
			}
		}

		.right h1 {
			font-style: italic;
		}

		.right {
			color: white;
			font-size: 1.4rem;
			letter-spacing: 0.05rem;
			font-weight: 600;
			text-align: right;

			@media screen and (min-width: 3840px) {
				font-size: 3.5rem;
				letter-spacing: 0.3rem;
			}
			@media screen and (min-width: 2048px) and (max-width: 3000px) {
				font-size: 2rem;
				letter-spacing: 0.3rem;
			}
			@media screen and (max-width: 800px) {
				position: absolute;
				left: 88%;
				bottom: 30%;
			}
			@media screen and (max-width: 650px) {
				position: absolute;
				left: 80%;
				bottom: 30%;
			}
			@media screen and (max-width: 450px) {
				font-size: 1rem;
			}
		}

		@media screen and (min-width: 3840px) {
			width: 93%;
		}
		@media screen and (min-width: 2048px) and (max-width: 3000px) {
			width: 93%;
		}
	}
}

.NameBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transform: rotate(270deg);
	height: 100%;
	padding-bottom: 100px;
	&::-webkit-scrollbar {
		display: none;
	}
	@media screen and (max-height: 500px) {
		padding-bottom: 50px;
	}
}
.nameCon1 {
	display: flex;
	flex-direction: row;
	&::-webkit-scrollbar {
		display: none;
	}
}
.TextWidth {
	font-size: 400%;
	color: white;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	padding-right: 6px;
	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: 3000px) {
		font-size: 800%;
	}
	@media screen and (max-width: 800px) {
		font-size: 500%;
	}
	@media screen and (max-width: 450px) {
		font-size: 400%;
	}
	@media screen and (max-height: 500px) {
		font-size: 300%;
	}
}
.TextWidth2 {
	font-size: 400%;
	color: white;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: 3000px) {
		font-size: 800%;
	}
	@media screen and (max-width: 800px) {
		font-size: 500%;
	}
	@media screen and (max-width: 450px) {
		font-size: 400%;
	}
	@media screen and (max-height: 500px) {
		font-size: 300%;
	}
}
.HR1 {
	width: 150px;
	color: white;
	font-weight: 500;
	height: 5px;
	background-color: white;

	@media screen and (min-width: 3000px) {
		width: 300px;
	}
	@media screen and (max-width: 800px) {
		width: 100px;
		height: 3px;
	}
	@media screen and (max-width: 1200px) {
		width: 100px;
		height: 3px;
	}
	@media screen and (max-width: 550px) {
		height: 2px;
		width: 100px;
	}
	@media screen and (max-width: 350px) {
		height: 2px;
		width: 80px;
	}
	@media screen and (max-height: 600px) {
		width: 100px;
	}
	@media screen and (max-height: 500px) {
		width: 80px;
	}
}
.HR1flex {
	display: flex;
	justify-content: flex-start;
	width: 44%;

	@media screen and (min-width: 1200px) {
		width: 38%;
	}

	@media screen and (max-width: 1200px) {
		width: 45%;
	}
	@media screen and (max-width: 800px) {
		width: 70%;
	}
	@media screen and (max-width: 600px) {
		width: 80%;
	}
	@media screen and (max-width: 550px) {
		width: 90%;
	}
	@media screen and (max-width: 450px) {
		width: 95%;
	}
	@media screen and (max-width: 350px) {
		width: 120%;
	}
	@media screen and (max-height: 500px) {
		width: 50%;
	}
}
.HR2flex {
	display: flex;
	justify-content: flex-end;
	width: 40%;
	@media screen and (min-width: 3000px) {
		margin-top: 30px;
	}

	@media screen and (max-width: 1200px) {
		width: 58%;
	}
	@media screen and (max-width: 800px) {
		width: 78%;
	}
	@media screen and (max-width: 600px) {
		width: 80%;
	}
	@media screen and (max-width: 550px) {
		width: 105%;
	}
	@media screen and (max-height: 600px) {
		width: 70%;
	}
	@media screen and (max-height: 500px) {
		width: 40%;
	}
}
.HR2 {
	width: 200px;
	color: white;
	font-weight: 500;
	height: 5px;
	background-color: white;
	position: absolute;
	top: 50%;

	@media screen and (min-width: 3000px) {
		width: 300px;
	}
	@media screen and (max-width: 800px) {
		height: 3px;
		width: 150px;
	}
	@media screen and (max-width: 1200px) {
		height: 3px;
		width: 150px;
	}
	@media screen and (max-width: 350px) {
		height: 2px;
		width: 150px;
	}
	@media screen and (max-height: 600px) {
		width: 100px;
	}
	@media screen and (max-height: 500px) {
		width: 80px;
	}
}

/////////CONTAINER 2//////////////

.contain2 {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: black;
	scroll-behavior: smooth;
}
.imageContainer2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;

}
.Con2Background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 2;
}

.introImg1Btn {
	top: 8%;
	right: 41%;
}

.introImg2Btn {
	top: 50%;
	left: 50%;
}
.introImg1mBtn {
	top: 4%;
	right: 40%;
}

.introImg2mBtn {
	top: 55%;
	left: 55%;
}
.EditTextIcon {
	width: 4rem;
	height: 4rem;
	position: absolute;
	top: 33%;
	left: 8%;
	cursor: pointer;
	z-index: 2;
}
.EditTextIconM {
	width: 4rem;
	height: 4rem;
	position: absolute;
	top: 35%;
	left: 8%;
	cursor: pointer;
	z-index: 2;
}
.Con2Image1 {
	object-fit: cover;
	z-index: -1;
	display: flex;
	height: 45rem;
	width: 100%;
	margin-left: 20px;
	
	@media screen and (min-width: 3000px) {
		height: 130rem;
	}
	
	@media (min-width: 2000px) and (max-width: 2900px) {
		height: 60rem;
	}

	@media (min-width: 1000px) and (max-width: 2000px) {
		height: 60rem;
	}

	// @media screen and (min-width: 600px) {
	//   height: 85rem;
	//    }
	@media screen and (max-width: 800px) {
		// height: 90vh;
		width: 120%;
		margin-left: 0px;
	}
	@media screen and (min-height: 1200px) {
		height: 90rem;
	}
	@media screen and (min-width: 800px) and (max-width: 900px) {
		height: 80rem;
	}
	@media screen and (max-height: 1000px) {
		height: 70rem;
	}
}
.Con2Image2 {
	// height: 40rem;
	width: 100%;
	position: absolute;
	left: 3.7%;
	top: 45%;
	// clip-path: polygon(0 0, 50% 1%, 100% 90%, 100% 70%, 100% 100%, 0 100%, 0% 70%, 0% 30%);

	@media screen and (min-height: 1200px) {
		height: 90rem;
	}
	@media screen and (max-height: 1000px) {
		height: 70rem;
	}
	@media screen and (min-width: 3000px) {
		height: 120rem;
	}
	@media (min-width: 2000px) and (max-width: 2900px) {
		height: 60rem;
		clip-path: polygon(0 0, 50% 1%, 100% 100%, 100% 70%, 100% 100%, 0 100%, 0% 70%, 0% 30%);
	}

	@media (min-width: 1700px) and (max-width: 2000px) {
		height: 50rem;
		top: 45.5%;
		clip-path: polygon(0 0, 50% 1%, 100% 100%, 100% 70%, 100% 100%, 0 100%, 0% 70%, 0% 30%);
	}
	// @media screen and (min-width:400px) {
	//   height: 60rem;
	// }
	// @media screen and (min-width: 800px) and (max-width: 900px) {
	// 	height: 80rem;
	// }
	// @media screen and (min-width: 800px) {
	// 	// height: 60vh;
	// 	width: 100%;
	// 	left: 2%;
	// 	top: 0%;

	// 	clip-path: polygon(0 0, 50% 1%, 100% 90%, 100% 70%, 100% 100%, 0 100%, 0% 70%, 0% 30%);
	// }
	@media screen and (max-width: 800px) {
		// height: 60vh;
		width: 150%;
		left: 0%;
		top: 0%;

		clip-path: polygon(0 0, 50% 1%, 100% 50%, 100% 80%, 100% 100%, 0 100%, 0% 70%, 0% 30%);
	}
}

.h4 {
	color: #f5eded;
	font-family: "Poppins", sans-serif;
	font-size: 300%;
	font-weight: bold;
	margin-bottom: 2.88rem;
	z-index: 3;
	box-sizing: border-box;
	position: absolute;
	// bottom: 1550px;
	top: 55%;
	left: 70%;
	transform: rotate(318deg) translate(0px, 0px);

	@media screen and (min-width: 3000px) {
		font-size: 650%;
		width: 45%;
		top: 33%;
		left: 68%;
		transform: rotate(320deg) translate(0px, 0px);
	}
	@media (min-width: 2000px) and (max-width: 2900px) {
		font-size: 400%;
		width: 45%;
		top: 33%;
		left: 65%;
		transform: rotate(305deg) translate(0px, 0px);
	}

	@media screen and (min-width: 1700px) and (max-width: 2000px) {
		transform: rotate(309deg) translate(0px, 0px);
		font-size: 350%;
	}
	@media screen and (min-width: 1120px) {
		transform: rotate(318deg) translate(0px, 0px);
	}
	// @media screen and (min-height:900px) {
	//   transform: rotate(312deg) translate(0px, 0px);
	// }
	@media screen and (max-width: 1120px) {
		transform: rotate(315deg) translate(0px, 0px);
	}
	@media screen and (max-width: 950px) {
		transform: rotate(308deg) translate(0px, 0px);
	}
	@media screen and (max-width: 800px) {
		top: 88%;
		left: 74%;
		transform: rotate(335deg) translate(0px, 0px);
		font-size: 200%;
	}
	@media screen and (max-width: 350px) {
		top: 88%;
		left: 74%;
		transform: rotate(330deg) translate(0px, 0px);
		font-size: 200%;
	}
	@media screen and (max-width: 450px) {
		top: 88%;
		left: 74%;
		transform: rotate(318deg) translate(0px, 0px);
		font-size: 200%;
	}
}

.Con2Text {
	height: 100%;
	width: 100%;
	z-index: 3;
}
.IntroContain2 {
	width: 100%;

	width: 35%;
	min-height: 20rem;
	color: #f8f5f5;
	font-family: "Poppins", sans-serif;
	font-size: 150%;
	font-style: normal;
	font-weight: 300;
	margin-bottom: 2rem;
	position: absolute;
	top: 40%;
	z-index: 3;
	left: 100px;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: 3000px) {
		font-size: 300%;
		width: 45%;
		min-height: 30rem;
	}

	@media (min-width: 2000px) and (max-width: 2900px) {
		font-size: 200%;
		min-height: 30rem;
	}
	@media screen and (max-width: 800px) {
		font-size: 100%;
		min-height: 35rem;
		left: 30px;
	}
}
.ButtonCont2 {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	padding-bottom: 1rem;
	z-index: 3;
	position: absolute;
	top: 80%;
	left: 70%;
	color: white;
	width: 20%;

	@media screen and (max-width: 800px) {
		top: 65%;
		left: 10%;
		flex-direction: column;
		height: 10%;
	}
	button {
		display: block;
		color: #ffffff;
		font-family: "Poppins", sans-serif;
		font-size: 1.4rem;
		font-weight: 500;
		cursor: pointer;
		background: transparent;
		border: none;
		position: relative;
		font-style: italic;

		@media screen and (min-width: 3000px) {
			font-size: 300%;
		}

		@media (min-width: 2000px) and (max-width: 2900px) {
			font-size: 200%;
		}
		@media screen and (max-width: 800px) {
			letter-spacing: 4px;
			font-size: 150%;
		}
	}
}

.TopImgSection{
	width: 100%;
	height: 50vh;
}

.BottomImgSection{
	width: 100%;
	height: 50vh;

}

///////////CONTAINER 3//////////////////
.contain3 {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	scroll-behavior: smooth;

	.IntroImageBtn {
		top: 20%;
		right: 30%;
	}
}
.BackgroundCon3 {
	z-index: 1;
	position: absolute;
	top: -1px;
	left: -1px;
	width: 101%;
	height: 100vh;
	background-image: url(./images/Con3Bg.png);
	background-size: cover;
	border-bottom: 4px solid white;
	@media screen and (max-width: 800px) {
		background-image: url(./images/Con3Mobile.png);
		border-bottom: 4px solid rgba(255, 255, 255, 0);
		height: 101vh;
	}
}
.ImageCon3 {
	z-index: -1;
	width: 99%;
	height: 150vh;
	// clip-path: polygon(13% 0, 84% 0, 76% 18%, 100% 70%, 100% 98%, 0 100%, 0% 70%, 21% 19%);
}
///////////CONTAINER 4///////////////

.Con4Bg {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	background-color: #040911;
	scroll-behavior: smooth;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
.sliderContainer {
	width: 100%;
}
.top {
	display: flex;
	justify-content: space-around;
	padding-top: 2%;
	font-family: "Poppins", sans-serif;
}
.topText1 {
	color: #ffffff;
	font-size: x-large;

	@media screen and (min-width: 3000px) {
		font-size: 500%;
	}

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		font-size: 300%;
	}
	@media screen and (max-width: 800px) {
		font-size: 200%;
	}
}
.topText2 {
	color: #6d6d6d;
	cursor: pointer;

	@media screen and (min-width: 3000px) {
		font-size: 300%;
	}

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		font-size: 200%;
	}
}
.middle {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 3%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 800px) {
		// padding-left: 10%;
		// padding-right: 11%;
	}
}
.bottom {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	.ShortEditTextIcon {
		width: 4rem;
		height: 4rem;
		position: absolute;
		top: 15%;
		left: 20%;
		cursor: pointer;
		z-index: 2;
	}
	.ShortEditTextIconM {
		width: 4rem;
		height: 4rem;
		position: absolute;
		top: 10%;
		left: 10%;
		cursor: pointer;
		z-index: 2;
	}
}
.footerText1 {
	color: white;
	flex-wrap: wrap;
	font-family: "Poppins", sans-serif;
	text-align: center;
	// padding-left: 10%;
	// padding-right: 10%;
	font-weight: 300;
	padding-top: 2%;
	width: 70%;
	height: 100%;
	font-size: 120%;
	padding-left: 10%;
	padding-right: 10%;
	margin-bottom: 3rem;
	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-height: 1000px) {
		padding-top: 10%;
	}

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		font-size: 150%;
	}
	@media screen and (min-width: 3000px) {
		font-size: 300%;
	}
	@media screen and (max-width: 800px) {
		margin-bottom: 2rem;
	}
}
.footerText2 {
	color: white;
	font-family: "Playball", cursive;
	text-align: center;
	font-size: 150%;
	margin-bottom: 3rem;
	height: auto;

	@media screen and (min-width: 3000px) {
		font-size: 400%;
	}

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		font-size: 250%;
	}
	@media screen and (min-height: 1000px) {
		padding-top: 10%;
	}
}
.imageSlider {
	height: 300px;
	width: 510px;
	border-radius: 5%;
	position: relative;
	right: 50%;
	// background-color: #0a1b30;

	@media screen and (min-width: 800px) {
		left: 20%;
	}

	@media screen and (max-width: 800px) {
		height: 200px;
		width: 95%;
		left: 0%;
		right: 0%;
	}
	@media screen and (min-width: 550px) {
		height: 300px;
		width: 95%;
		left: 0%;
		right: 0%;
	}

	@media screen and (min-width: 800px) {
		left: -50%;
		height: 300px;
		width: 500px;
	}
	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		width: 900px;
		height: 450px;
	}

	@media screen and (min-width: 3000px) {
		height: 1000px;
		width: 2000px;
	}

	@media screen and (max-width: 350px) {
		height: 150px;
		width: 95%;
		left: 0%;
		right: 0%;
	}
}

// .sliderContainer{
//   padding-left: 30%;
//   padding-right: 10%;
// }

.sliderContainer {
	width: 100%;
	height: 100%;
}

:root {
	--swiper-pagination-color: white;
	--swiper-pagination-bullet-size: 8px;
	--swiper-pagination-bullet-width: 20px;
	--swiper-pagination-bullet-height: 4px;
	--swiper-pagination-bullet-inactive-color: #fff9f9;
	--swiper-pagination-bullet-inactive-opacity: 0.1;
	--swiper-pagination-bullet-opacity: 1;
	--swiper-pagination-bullet-horizontal-gap: 4px;
	--swiper-pagination-bullet-vertical-gap: 6px;
	--swiper-pagination-bullet-border-radius: 2px;
	--swiper-pagination-bottom: 10px;

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		--swiper-pagination-bullet-width: 60px;
		--swiper-pagination-bullet-horizontal-gap: 10px;
		--swiper-pagination-bullet-height: 8px;
	}
	@media screen and (min-width: 3000px) {
		--swiper-pagination-bullet-width: 100px;
		--swiper-pagination-bullet-height: 12px;
		--swiper-pagination-bullet-horizontal-gap: 10px;
	}
}
.bulletheight {
	height: 30px;

	@media screen and (min-height: 900px) {
		height: 100px;
	}
}
.ImageFooter {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 500px;
	position: absolute;
	left: -50%;
	top: 80.7%;
	height: 45px;
	border-radius: 0px 0px 10px 10px;
	-webkit-border-radius: 0px 0px 10px 10px;
	-moz-border-radius: 0px 0px 10px 10px;
	padding-top: 3%;
	padding-left: 4%;
	padding-right: 4%;
	background-color: #00000082;
	align-items: center;

	@media screen and (min-width: 800px) {
		left: 20%;
	}

	@media screen and (max-width: 800px) {
		top: 75%;
		height: 50px;
		width: 95%;
		left: 0%;
		right: 0%;
		align-items: center;
		text-align: center;
	}
	@media screen and (max-width: 350px) {
		top: 70%;
		height: 50px;
		width: 95%;
		left: 0%;
		right: 0%;
		align-items: center;
		text-align: center;
	}
	@media screen and (min-width: 550px) {
		top: 80%;
		height: 58px;
		width: 95%;
		left: 0%;
		right: 0%;
		align-items: center;
		text-align: center;
	}
	@media screen and (min-width: 800px) {
		top: 85%;
		left: -50%;
		width: 500px;
		height: 45px;
	}
	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		top: 80%;
		width: 900px;
		height: 90px;
		border-radius: 0px 0px 30px 30px;
		-webkit-border-radius: 0px 0px 30px 30px;
		-moz-border-radius: 0px 0px 30px 30px;
	}
	@media screen and (min-width: 3000px) {
		width: 2000px;
		height: 180px;
		padding-left: 2%;
		padding-right: 2%;
		border-radius: 0px 0px 50px 50px;
		-webkit-border-radius: 0px 0px 50px 50px;
		-moz-border-radius: 0px 0px 50px 50px;
	}
	@media screen and (max-width: 350px) {
		top: 70%;
		height: 50px;
		width: 95%;
		left: 0%;
		right: 0%;
		align-items: center;
		text-align: center;
	}
}
.RightPart {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 50%;
}
.Iconcontainer {
	display: flex;
	flex-direction: row;
	margin-right: 5%;
	align-items: center;
	gap: .5rem;
	cursor: pointer;
}
.LeftPart {
	color: white;
	font-size: 80%;
	width: 50%;
	font-family: "Poppins", sans-serif;

	@media screen and (max-width: 500px) {
		font-size: 30%;
	}

	@media screen and (min-width: 800px) {
		font-size: 50%;
	}
	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		font-size: 110%;
	}
	@media screen and (min-width: 3000px) {
		font-size: 200%;
	}
}
.styleiconLike {
	height: 22px;

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		height: 44px;
	}
	@media screen and (min-width: 3000px) {
		height: 80px;
	}
	@media screen and (max-width: 800px) {
		height: 20px;
	}
	@media screen and (max-width: 500px) {
		height: 12px;
	}
}
.styleiconComment {
	height: 24px;
	@media screen and (max-width: 800px) {
		height: 20px;
	}

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		height: 46px;
	}
	@media screen and (max-width: 500px) {
		height: 12px;
	}
	@media screen and (min-width: 3000px) {
		height: 85px;
	}
}
.styleiconShare {
	height: 25px;

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		height: 50px;
	}
	@media screen and (min-width: 3000px) {
		height: 100px;
	}
	@media screen and (max-width: 800px) {
		height: 24px;
	}
	@media screen and (max-width: 500px) {
		height: 14px;
	}
}
.count {
	color: white;
	font-family: "Poppins", sans-serif;
	font-size: 100%;

	@media screen and (min-width: 2000px) and (max-width: 3000px) {
		font-size: 200%;
	}
	@media screen and (min-width: 3000px) {
		font-size: 300%;
	}
}

//////////////container4m////////////
