.TemplateWrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;
	box-sizing: border-box;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Btemplate2 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	background: #fcf4ee;
	display: flex;
	flex-direction: column;

	.topSection {
		flex: 1;
		height: 87.2%;
		width: 100%;
		z-index: 1;
		position: relative;

		@media (max-width: 930px) and (min-height: 600px) {
			height: 90.49vh;
			// padding-bottom: 43vh;
		}

		.contents {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: flex-start;
			gap: 6vh;
			padding-left: 5vw;
			@media (max-width: 930px) and (min-height: 600px) {
				height: 42.88vh;
				width: 100%;
				gap: 4vh;
				padding: 2vh 0vw;
				padding-left: 6vw;
			}

			.logo {
				justify-self: flex-start;
				display: flex;
				width: 11.57vw;
				height: 10.74vh;
				justify-content: center;
				align-items: center;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 23.256vw;
					height: 6.43vh;
				}
				img {
					width: 100%;
					height: 100%;
				}
			}

			.mid {
				display: flex;
				flex-direction: column;
				gap: 6vh;
				position: relative;
				@media (max-width: 930px) and (min-height: 600px) {
					gap: 3vh;
				}
				.EditTextIcon {
					height: 4rem;
					width: 4rem;
					position: absolute;
					right: -5rem;
					@media (max-width: 930px) and (min-height: 600px) {
						height: 3rem;
						width: 3rem;
					}
				}
				.name {
					color: #323232;
					font-family: Radley;
					font-size: 4.6vw;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-transform: uppercase;
					@media (max-width: 930px) and (min-height: 600px) {
						font-size: 9.09vw;
					}
				}
				.buttons {
					display: flex;
					gap: 1vw;
					button {
						display: flex;
						width: 15.366vw;
						height: 8.17vh;
						padding: 2.3vh 3.834vw;
						justify-content: center;
						align-items: center;
						flex-shrink: 0;
						font-family: Inter;
						font-size: 1.012vw;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
						letter-spacing: 0.268vw;
						text-transform: uppercase;
						border: none;
						cursor: pointer;
						border-radius: 0.504vw;
						@media (max-width: 930px) and (min-height: 600px) {
							width: 32.83vw;
							height: 4.42vh;
							padding: 1.25vh 8.11vw;
							font-size: 2.16vw;
						}
						&:nth-child(1) {
							color: #fff;
							background: #c0a181;
						}
						&:nth-child(2) {
							color: #000;
							background: #fff;
							border: 0.063vw solid #000;
						}
					}
				}
			}

			.ratingSubs {
				justify-self: flex-end;
				display: flex;
				align-items: center;
				gap: 3vw;

				.RatingContainer {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 7vw;
					height: 7vw;
					cursor: pointer;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 18vw;
						height: 18vw;
					}

					img {
						width: 100%;
						height: 100%;
					}

					p {
						color: #fff;
						font-family: Poppins;
						font-size: 1.123vw;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						position: absolute;
						@media (max-width: 930px) and (min-height: 600px) {
							font-size: 2.3vw;
						}
					}
				}

				.subscribers,
				.posts {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 0.2vh;
					.addsub,
					.reel {
						width: 2.5vw;
						aspect-ratio: 1/1;
						flex-shrink: 0;
						fill: #c0a181;
						background-image: url("../../assets/business/svg/business2bgsec2.svg");
						background-size: cover;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 2vh;
						@media (max-width: 930px) and (min-height: 600px) {
							width: 6.79vw;
							aspect-ratio: 1/1;
						}
						svg {
							width: 1.176vw;
							aspect-ratio: 1/1;
							@media (max-width: 930px) and (min-height: 600px) {
								width: 2.72vw;
								aspect-ratio: 1/1;
							}
						}
					}
					p {
						color: #000;
						font-family: Poppins;
						font-size: 1.212vw;
						font-style: normal;
						font-weight: 500;
						line-height: 118.5%;
						letter-spacing: 0.115vw;
						text-transform: uppercase;
						@media (max-width: 930px) and (min-height: 600px) {
							font-size: 2.81vw;
							letter-spacing: 0.27vw;
						}
					}
				}
			}
		}

		.heroImage {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 50.566vw;
			height: 77.74vh;

			.landingChangeIcon {
				height: 4rem;
				width: 4rem;
				cursor: pointer;
				position: absolute;
				right: 2rem;
				top: 2rem;
				@media (max-width: 930px) and (min-height: 600px) {
					height: 3rem;
					width: 3rem;
				}
			}

			@media (max-width: 930px) and (min-height: 600px) {
				width: 100%;
				height: 48.61vh;
				flex-shrink: 0;
			}
			img {
				width: 100%;
				height: 100%;
				// object-fit: cover;
			}

			.socials {
				position: absolute;
				right: 4vw;
				bottom: 4vh;
				display: flex;
				width: 36.705vw;
				height: 13.98vh;
				padding: 0.89vh 0.579vw;
				justify-content: center;
				align-items: center;
				background: #fff;
				gap: 3.628vw;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 90.16vw;
					height: 10.24vh;
					padding: 0.66vh 1.42vw;
					justify-content: space-around;
					right: 0;
				}

				img {
					width: 2.35vw;
					aspect-ratio: 1/1;
					cursor: pointer;
					@media (max-width: 930px) and (min-height: 600px) {
						width: 3.721vw;
						aspect-ratio: 1/1;
					}
				}
			}
		}
	}
	.bottomSection {
		height: 12.8%;
		width: 100%;
		display: flex;
		gap: 7vw;
		align-items: center;
		background: #fff;
		padding: 0.2rem;
		@media (max-width: 930px) and (min-height: 600px) {
			height: 9.51vh;
		}

		img {
			height: 7vh;
			object-fit: contain;
			margin-right: 3vw;
			@media (max-width: 930px) and (min-height: 600px) {
				margin-right: 5vw;
				height: 5vh;
			}
		}
	}
}

.Container2 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	background-color: #fff;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 1vh 2vw;
	@media (max-width: 930px) and (min-height: 600px) {
		flex-direction: column;
	}

	.leftSection {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		@media (max-width: 930px) and (min-height: 600px) {
			height: 50%;
			width: 100%;
			align-items: center;
		}
		.companyDetails {
			display: flex;
			flex-direction: column;
			gap: 2vh;
			padding: 4vh 0;
			position: relative;
			@media (max-width: 930px) and (min-height: 600px) {
				// align-items: center;
			}
			h2 {
				color: #000;
				font-family: Radley;
				font-size: 3.125vw;
				font-style: normal;
				font-weight: 400;
				line-height: 160.5%;
				letter-spacing: 0.595vw;
				text-transform: uppercase;
				@media (max-width: 930px) and (min-height: 600px) {
					font-size: 6.36vw;
				}
			}
			.aboutusText {
				width: 28.266vw;
				height: 35.31vh;
				color: #3f3f3f;
				font-family: Poppins;
				font-size: 1.176vw;
				font-style: normal;
				font-weight: 300;
				line-height: 155.5%;
				letter-spacing: 0.312vw;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 57.34vw;
					height: 18.12vh;
					font-size: 2.39vw;
				}
			}

			.EditTextIcon {
				height: 4rem;
				width: 4rem;
				position: absolute;
				right: -5rem;
				top: 8rem;
				@media (max-width: 930px) and (min-height: 600px) {
					height: 3rem;
					width: 3rem;
				}
			}
		}
		button {
			display: inline-flex;
			height: 9.08vh;
			padding: 2.02vh 3.325vw;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			border-radius: 0.524vw;
			background: #ffcd9a;
			color: #323232;
			font-family: Inter;
			font-size: 1.168vw;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 0.309vw;
			text-transform: uppercase;
			@media (max-width: 930px) and (min-height: 600px) {
				height: 4.65vh;
				width: max-content;
				padding: 9.684px 6.75vw;
				border-radius: 0.9vw;
				font-size: 2.27vw;
				margin-right: 30vw;
			}
		}
	}

	.rightSection {
		.heroImage {
			width: 21.513vw;
			min-width: 350px;
			height: 72.53vh;
			border-radius: 0px 10.768vw;
			border: 0.49vw solid #fff;
			flex-shrink: 0;
			position: relative;
			.aboutImageChangeIcon {
				height: 4rem;
				width: 4rem;
				position: absolute;
				right: 2rem;
				bottom: 2rem;
				img {
					border-radius: 0;
				}
				@media (max-width: 930px) and (min-height: 600px) {
					height: 3rem;
					width: 3rem;
					right: 1rem;
					bottom: 1rem;
				}
			}

			@media (max-width: 930px) and (min-height: 600px) {
				width: 46.91vw;
				min-width: 100px;
				height: 39.96vh;
				flex-shrink: 0;
				border-radius: 0px 23.45vw;
				border: 1.07vw solid #fff;
			}
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 0px 10.768vw;
			}

			&::before {
				content: "";
				width: 12.716vw;
				aspect-ratio: 1/1;
				flex-shrink: 0;
				border-radius: 50%;
				border: 0.869vw solid #fff;
				background: #fcfcfc url("../../assets/business/svg/twostars.svg") center;
				background-size: 100% 100%;
				position: absolute;
				bottom: -9vh;
				left: -9vw;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 30.44vw;
					aspect-ratio: 1/1;
					fill: #fcfcfc;
					stroke-width: 1.6vw;
					stroke: #fff;
					bottom: 1vh;
					left: -16vw;
				}
			}
			&::after {
				content: "";
				width: 16.716vw; //30.53vh
				width: 12.716vw;
				aspect-ratio: 1/1;
				flex-shrink: 0;
				border-radius: 50%;
				border: 0.869vw solid #fff;
				background: #fcfcfc url("../../assets/business/svg/twostars.svg") center;
				background-size: 100% 100%;
				position: absolute;
				top: -9vh;
				right: -9vw;
				@media (max-width: 930px) and (min-height: 600px) {
					width: 30.44vw;
					aspect-ratio: 1/1;
					fill: #fcfcfc;
					stroke-width: 1.6vw;
					stroke: #fff;
					top: 1vh;
					right: -16vw;
				}
			}
		}
	}
}

.Container3 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	.upperSection {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		gap: 0.619vh;
		position: relative;

		.EditTextIcon {
			height: 4rem;
			width: 4rem;
			position: absolute;
			right: -3rem;
			top: 2rem;
			@media (max-width: 930px) and (min-height: 600px) {
				height: 3rem;
				width: 3rem;
				top: 3rem;
			}
		}
		h2 {
			color: #000;
			font-family: Radley;
			font-size: 2.714vw;
			font-style: normal;
			font-weight: 400;
			line-height: 160.5%;
			letter-spacing: 0.515vw;
			text-transform: uppercase;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 7.56vw;
				letter-spacing: 1.44vw;
			}
		}
		.chooseusText {
			width: 60.543vw;
			color: #3f3f3f;
			text-align: center;
			font-family: Poppins;
			font-size: 1.017vw;
			font-style: normal;
			font-weight: 300;
			line-height: 155.5%; /* 32.297px */
			letter-spacing: 0.269vw;

			@media (max-width: 930px) and (min-height: 600px) {
				width: 83.94vw;
				max-height: 22.52vh;
				overflow: hidden;
				font-size: 3.21vw;
				letter-spacing: 0.85vw;
			}
		}
	}
	.lowerSection {
		.frame {
			width: 22.729vw;
			height: 33.78vw;
			flex-shrink: 0;
			stroke-width: 1.851px;
			stroke: #fed3a7;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			background-image: url("../../assets/business/svg/con3frameb2.svg");
			background-size: 100% 100%;
			padding: 2vh 0;
			position: relative;
			@media (max-width: 930px) and (min-height: 600px) {
					width: 54.66vw;
					height: 50.91vh;
				}
			&::before{
				position: absolute;
				content:"";
				height: 4rem;
				width: 4rem;
				left: -3rem;
					bottom: 3rem;
				background: url("./images/star.svg");
				background-position: center;
				background-size: inherit;
				@media (max-width: 930px) and (min-height: 600px) {
						left: -4rem;
				bottom: 3rem;
					}
			}
						&::after {
							position: absolute;
							content: "";
							height: 4rem;
							width: 4rem;
							top: 1rem;
							right: -3rem;
							background: url("./images/star.svg");
							background-position: center;
							background-size: inherit;
							@media (max-width: 930px) and (min-height: 600px) {
									top: 0rem;
										right: -4rem;
								}
						}
			

			.chooseImageChangeIcon {
				height: 4rem;
				width: 4rem;
				cursor: pointer;
				@media (max-width: 930px) and (min-height: 600px) {
					height: 3rem;
					width: 3rem;
				}
			}

			.asset {
				position: absolute;
					left: 0rem;
					top: 2.3rem;
					width: 9vw;
					height: 9vw;

				@media (max-width: 930px) and (min-height: 600px) {
					width: 27vw;
					height: 29vw;
					left: -7vw;
					top: 5.466vh;
				}
				img {
					height: 100%;
					width: 100%;
				}
			}

			.image {
					width: 15.787vw;
						height: 30.78vw;
				border-radius: 9.351vw;

				@media (max-width: 930px) and (min-height: 600px) {
					width: 47.97vw;
					height: 46.84vh;
					border-radius: 24.97vw;
				}
				img {
					height: 100%;
					width: 100%;
					border-radius: 9.351vw;
					@media (orientation: portrait) {
						border-radius: 24.97vw;
					}
				}
			}
		}
	}
}

.Container4 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	background-color: #fff;

	// @media (max-width: 930px) and (min-height: 600px) {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	// }

	.top {
		display: flex;
		justify-content: space-between;
		padding: 0.2vh 3vw;

		@media (max-width: 930px) and (min-height: 600px) {
			flex-direction: column;
			justify-content: flex-start;
			padding: 0.2vh 2vw;
		}

		h2 {
			color: #000;
			font-family: Radley;
			font-size: 2.778vw;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.417vw;
			text-transform: uppercase;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 7.44vw;
				letter-spacing: 1.12vw;
			}
		}

		p {
			color: #545454;
			font-family: Radley;
			font-size: 1.852vw;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.278vw;
			text-transform: uppercase;
			cursor: pointer;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 3.49vw;
				letter-spacing: 0.52vw;
			}
		}
	}

	.products {
		.carousel {
			width: 95%;
			margin-left: 5vw;
			@media (max-width: 930px) and (min-height: 600px) {
				margin-left: 2vw;
				width: 100%;
			}
		}
	}
}

.Container5 {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
	max-width: 100vw;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	@media (max-width: 930px) and (min-height: 600px) {
		justify-content: space-around;
	}

	.topSection {
		display: flex;
		justify-content: space-between;
		padding: 0 6vw;

		@media (max-width: 930px) and (min-height: 600px) {
			flex-direction: column;
			justify-content: flex-start;
			gap: 2vh;
			padding: 0.1vh 2vw;
		}

		h2 {
			color: #000;
			font-family: Radley;
			font-size: 2.778vw;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.417vw;
			text-transform: uppercase;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 7.44vw;
				letter-spacing: 1.12vw;
			}
		}

		p {
			color: #545454;
			font-family: Radley;
			font-size: 1.852vw;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.278vw;
			text-transform: uppercase;
			cursor: pointer;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 3.49vw;
				letter-spacing: 0.52vw;
			}
		}
	}
	.carousel {
		width: 90%;
		margin-left: 10vw;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 930px) and (min-height: 600px) {
			width: 100%;
			margin-left: 1.2vw;
		}
	}

	.thankYou {
		color: #000;
		text-align: center;
		font-family: Playball;
		font-size: 48.319px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		@media (max-width: 930px) and (min-height: 600px) {
			font-size: 6.05vw;
		}
	}
}

.postMainContainer {
	width: 59.429vw;
	width: 60vw;
	height: 66.66vh;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 1;

	@media (max-width: 930px) and (min-height: 600px) {
		width: 97.39vw;
		height: 64.62vh;
	}

	.media {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.profile {
		display: flex;
		align-items: center;
		gap: 1vw;
		padding: 1vh 2vw;
		img {
			width: 2.97vw;
			aspect-ratio: 1/1;
			border-radius: 50%;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 8.39vw;
				aspect-ratio: 1/1;
			}
		}
		.profileDetails {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-around;
			h4 {
				color: #000;
				font-family: Poppins;
				font-size: 0.908vw;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				@media (max-width: 930px) and (min-height: 600px) {
					font-size: 2.56vw;
				}
			}
			h5 {
				color: #000;
				font-family: Poppins;
				font-size: 0.495vw;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@media (max-width: 930px) and (min-height: 600px) {
					font-size: 1.4vw;
				}
			}
		}
	}

	.details {
		width: 59.21vw;
		height: 7.78vh;
		background: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(0.216vw);
		display: flex;
		align-items: center;
		@media (max-width: 930px) and (min-height: 600px) {
			width: 96.97vw;
			height: 7.62vh;
			backdrop-filter: blur(0.36vw);
		}
		p {
			padding: 0 1vw;
			width: 25.567vw;
			height: 7.03vh;
			color: #fff;
			font-family: Poppins;
			font-size: 1.34vw;
			font-style: normal;
			font-weight: 400;
			line-height: 123.4%;
			@media (max-width: 930px) and (min-height: 600px) {
				width: 41.89vw;
				height: 2.98vh;
				font-size: 2.2vw;
			}
		}
	}
}

.ProductContainer {
	display: flex;
	flex-direction: column;
	gap: 3vh;
	img {
		width: 35.761vw;
		height: 46.25vh;
		flex-shrink: 0;
		@media (max-width: 930px) and (min-height: 600px) {
			width: 203.919px;
			height: 170.583px;
		}
	}

	h2 {
		color: #000;
		font-family: Radley;
		font-size: 2.99vw;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		@media (max-width: 930px) and (min-height: 600px) {
			font-size: 17.056px;
		}
	}

	.actions {
		display: flex;
		align-items: center;
		gap: 2vw;
		.heart {
			width: 49.495px;
			aspect-ratio: 1/0.9533;
			img {
				height: 100%;
				width: 100%;
			}
			@media (max-width: 930px) and (min-height: 600px) {
				width: 16.333px;
				height: 15.562px;
			}
		}
		.comment {
			width: 52.1px;
			aspect-ratio: 1/0.8543;
			img {
				height: 100%;
				width: 100%;
			}
			@media (max-width: 930px) and (min-height: 600px) {
				width: 17.192px;
				height: 14.677px;
			}
		}
		.share {
			width: 65.043px;
			aspect-ratio: 1/0.591;
			img {
				height: 100%;
				width: 100%;
			}
			@media (max-width: 930px) and (min-height: 600px) {
				width: 21.463px;
				height: 12.658px;
			}
		}
	}
}

.mobileCarousel {
	display: flex;
	flex-direction: column;
	gap: 4vh;
	.activeItem {
		display: flex;
		flex-direction: column;
		gap: 1vh;
		img {
			width: 96.15vw;
			height: 37.1vh;
			flex-shrink: 0;
		}
		h2 {
			color: #000;
			font-family: Radley;
			font-size: 8.04vw;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-transform: uppercase;
		}

		.actions {
			display: flex;
			align-items: center;
			gap: 2vw;
			.heart {
				width: 7.69vw;
				aspect-ratio: 1/0.9533;
				img {
					height: 100%;
					width: 100%;
				}
			}
			.comment {
				width: 8.1vw;
				aspect-ratio: 1/0.8543;
				img {
					height: 100%;
					width: 100%;
				}
			}
			.share {
				width: 10.13vw;
				aspect-ratio: 1/0.591;
				img {
					height: 100%;
					width: 100%;
				}
			}
		}
	}

	.allItems {
		min-width: 100vw;
		height: 30vh;
		display: flex;
		gap: 2vw;
		overflow: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		.Products {
			width: 47.41vw;
			min-width: 47.41vw;
			height: 18.31vh;
			min-height: 18.31vh;
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 1vh;

			img {
				width: 100%;
				height: 100%;
			}

			h2 {
				color: #000;
				font-family: Radley;
				font-size: 3.97vw;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
			}
			.actions {
				display: flex;
				gap: 2vw;
			}
		}
	}
}
