.TemplateWrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	background: #fff;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template2 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	width: 100%;
	height: 100vh;
	background: #fff;
	display: flex;
	align-items: center;
	padding: 4.5rem 8.125rem 7.125rem 5.625rem;
	position: relative;
	justify-content: space-between;
	gap: 1rem;

	@media screen and (max-width: 830px) and (min-height: 500px) {
		padding: 1rem 3rem 2rem 1rem;
	}

	@media screen and (max-width: 830px) and (min-height: 500px) {
		flex-direction: column-reverse;
		padding: 1rem;
	}

	@media screen and (max-aspect-ratio: 1/1) {
		flex-direction: column-reverse;
		padding: 1rem;
	}

	.Left {
		height: 100%;
		flex-basis: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;

		@media screen and (max-width: 830px) and (min-height: 500px) {
			flex-basis: 30%;
			width: 100%;
		}

		.EditTextIcon {
			width: 4rem;
			height: 4rem;
			position: absolute;
			top: 34%;
			left: 10%;
			cursor: pointer;

			@media screen and (max-width: 830px) and (min-height: 500px) {
				top: 20%;
				left: 20%;
			}

			@media screen and (max-width: 455px) {
				top: 20%;
				left: 10%;
			}
		}

		h3 {
			color: #ddad51;
			text-align: center;
			font-family: "Sedan SC";
			font-size: 1.8rem;
			font-style: normal;
			font-weight: 400;
			line-height: 174%; /* 55.68px */

			&::first-letter {
				font-size: 2.5rem;
			}
		}

		.h1 {
			width: 100%;
			height: 7rem;
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: #ddad51;
				text-align: center;
				font-family: "Sedan SC";
				// font-size: 3.5rem;
				font-style: normal;
				font-weight: 400;
				text-transform: uppercase;
				padding-left: 1rem;

				&::first-letter {
					font-size: 4.8rem;
				}
			}

			&::-webkit-scrollbar {
				display: none;
			}

			@media screen and (max-width: 830px) and (min-height: 500px) {
				height: 6rem;
			}
		}

		.p {
			width: 34.65175rem;
			height: 5.75rem;
			color: #000;
			text-align: center;
			font-family: "Poppins";
			font-size: 0.81256rem;
			font-style: normal;
			font-weight: 300;
			margin-bottom: 1rem;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.ButtonContainer {
			margin-top: 3.08rem;
			display: flex;
			align-items: center;
			position: relative;
			gap: 3.625rem;

			button {
				color: #000;
				font-family: "Poppins";
				font-size: 1.24219rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				width: 14.27106rem;
				height: 6.55619rem;
				border-radius: 1.63906rem;
				background: #fafafa;
				box-shadow: 1rem 1rem 1.5rem 0px rgba(0, 0, 0, 0.15);
				border: none;
				cursor: pointer;
			}

			.button {
				width: 10.35131rem;
				height: 10.35131rem;
				border-radius: 2.58781rem;
				background: #ddad51;
				box-shadow: 0.5rem 0.5rem 1.5rem 0px rgba(0, 0, 0, 0.25);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;

				@media screen and (max-width: 700px) {
					width: 9rem;
					height: 9rem;
					border-radius: 2rem;
				}

				h4 {
					color: #fff;
					font-family: "Poppins";
					font-size: 3.19844rem;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}

				h5 {
					color: #fff;
					font-family: "Poppins";
					font-size: 1.24219rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}
	}

	.Right {
		height: 100%;
		// width: 55rem;
		flex-basis: 50%;
		overflow: hidden;
		position: relative;
		flex-shrink: 0;

		@media screen and (max-aspect-ratio: 1/1) {
			flex-basis: 70%;
			max-width: 100%;
			min-width: 100%;
		}

		.coverImage {
			top: 5%;
			left: 24%;
		}

		.ImageContainer {
			width: 100%;
			height: 100%;
			margin: 0 auto;
			position: relative;
			overflow: hidden;

			@media screen and (max-width: 500px) {
				@media screen and (max-height: 930px) {
					width: 100% !important;
				}
			}

			@media screen and (max-aspect-ratio: 1/1) {
				width: 80%;
			}

			@media screen and (max-height: 830px) and (min-height: 500px) {
				width: 80%;
			}

			@media screen and (max-height: 830px) and (min-height: 500px) {
				width: 70%;
			}

			@media screen and (max-height: 600px) {
				width: 60%;
			}

			@media screen and (max-height: 500px) {
				width: 50%;
			}

			@media screen and (max-width: 1280px) {
				width: 80%;
			}

			.img1 {
				z-index: 2;
				position: absolute;
				top: 1%;
				left: 1%;
				right: 1%;
				bottom: 1%;
				width: 98%;
				height: 98%;
			}

			.img2 {
				width: 100%;
				height: 100%;
				z-index: 3;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	.RatingImg {
		width: 9.77025rem;
		height: 9.77025rem;
		position: absolute;
		bottom: 3%;
		right: 4%;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		@media screen and (max-width: 800px) {
			width: 7.5rem;
			height: 7.5rem;
			bottom: 1rem;
		}

		@media screen and (max-width: 830px) and (min-height: 500px) {
			bottom: 30%;
			left: 5%;
		}

		img {
			width: 100%;
			height: 100%;
		}

		span {
			position: absolute;
			color: #fff;
			font-family: "Poppins";
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			@media screen and (max-width: 800px) {
				font-size: 1.5rem;
			}
		}
	}
}

.Container2 {
	width: 100%;
	height: 100vh;
	display: flex;
	gap: 3rem;
	overflow: hidden;

	@media screen and (max-width: 830px) and (min-height: 500px) {
		flex-direction: column;
	}

	.Left {
		height: 100%;
		flex-basis: 60%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 2rem;

		@media screen and (max-width: 830px) and (min-height: 500px) {
			flex-basis: 55%;
			padding-top: 5rem;

			@media screen and (max-height: 750px) {
				padding-top: 1rem;
			}
		}

		.ImageMainContainer {
			width: 50rem;
			height: 50rem;
			position: relative;

			@media screen and (max-height: 740px) {
				width: 45rem;
				height: 45rem;
			}

			@media screen and (max-height: 665px) {
				width: 42rem;
				height: 42rem;
			}

			@media screen and (max-height: 640px) {
				width: 48rem;
				height: 48rem;
			}

			@media screen and (max-height: 600px) {
				width: 45rem;
				height: 45rem;
			}

			@media screen and (max-height: 580px) {
				width: 40rem;
				height: 40rem;
			}

			@media screen and (max-width: 425px) {
				width: 45rem;
				height: 45rem;
			}

			@media screen and (max-width: 570px) {
				@media screen and (max-height: 750px) {
					width: 45rem;
					height: 45rem;
				}
			}

			@media screen and (max-width: 470px) {
				@media screen and (max-height: 930px) {
					width: 40rem;
					height: 40rem;
				}
			}

			@media screen and (max-width: 1410px) {
				@media screen and (max-height: 670px) {
					width: 40rem;
					height: 40rem;
				}
				@media screen and (max-height: 600px) {
					width: 38rem;
					height: 38rem;
				}
			}

			.IntroImage {
				top: 40%;
				left: 10%;
			}

			.IntroImage1 {
				bottom: 0%;
				left: 0%;
			}

			.IntroImage2 {
				bottom: -10%;
				left: 45%;
			}

			.IntroImage3 {
				bottom: 12%;
				right: 4%;
			}

			.MainImage {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				// border: 1.2rem solid #ddad51;
				// box-shadow: 0px 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.25);
				overflow: hidden;
				background: #ddad51;
				padding: 1.2rem;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				&::after {
					content: "";
					position: absolute;
					background: rgb(255, 255, 255);
					bottom: 0rem;
					right: 0;
					width: 70%;
					height: 20rem;
					z-index: 1;
				}

				div {
					z-index: 2;
				}

				.pImg {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					z-index: 2;
					box-shadow: 0px 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.25);
					background: #ddad51;
				}
			}

			.subImage {
				width: 14rem;
				height: 14rem;
				border-radius: 50%;
				border: 0.6rem solid #ddad51;
				box-shadow: 1.25rem 1.25rem 1.93rem 0px rgba(0, 0, 0, 0.15);
				overflow: hidden;
				position: absolute;
				z-index: 3;
				background: #fff;

				@media screen and (max-width: 1410px) {
					@media screen and (max-height: 670px) {
						width: 12rem;
						height: 12rem;
					}
					@media screen and (max-height: 600px) {
						width: 10rem;
						height: 10rem;
					}
				}

				@media screen and (max-width: 470px) {
					@media screen and (max-height: 930px) {
						width: 10rem;
						height: 10rem;
					}
				}

				img {
					width: 100%;
					height: 100%;
				}

				&.subImage1 {
					bottom: -3.75rem;
					left: 6.875rem;

					@media screen and (max-width: 1410px) {
						@media screen and (max-height: 600px) {
							bottom: -2rem;
						}
					}
				}

				&.subImage2 {
					bottom: -3.75rem;
					right: 10rem;

					@media screen and (max-height: 740px) {
						bottom: -3.75rem;
						right: 6rem;
					}

					@media screen and (max-width: 1366px) {
						@media screen and (max-height: 665px) {
							bottom: -3.75rem;
							right: 4rem;
						}
					}

					@media screen and (max-width: 561px) {
						bottom: -3.75rem;
						right: 6rem;
					}

					@media screen and (max-width: 425px) {
						bottom: -3.75rem;
						right: 6rem;
					}

					@media screen and (max-width: 470px) {
						@media screen and (max-height: 700px) {
							bottom: -3.75rem;
							right: 2rem;
						}
					}

					@media screen and (max-width: 1410px) {
						@media screen and (max-height: 670px) {
							bottom: -3.75rem;
							right: 4rem;
						}
						@media screen and (max-height: 600px) {
							bottom: -2rem;
						}
					}
				}

				&.subImage3 {
					bottom: 11.25rem;
					right: -2.5rem;

					@media screen and (max-width: 400px) {
						right: -1.5rem;
					}
				}
			}
		}
	}

	.Right {
		height: 100%;
		flex-basis: 40%;
		padding-top: 20vh;
		padding-right: 1rem;
		position: relative;

		.EditTextIcon {
			width: 4rem;
			height: 4rem;
			position: absolute;
			top: 23%;
			right: 17%;
			cursor: pointer;

			@media screen and (max-width: 830px) {
				top: 20%;
				left: 20%;
			}

			@media screen and (max-width: 455px) {
				top: 20%;
				left: 10%;
			}
		}

		@media screen and (max-height: 720px) {
			padding-top: 15vh;
		}

		@media screen and (max-width: 830px) {
			flex-basis: 45%;
			padding-top: 0rem;
			padding-left: 2rem;
			padding-right: 2rem;
		}

		@media screen and (max-height: 640px) {
			padding-top: 18vh;

			@media screen and (max-width: 700px) {
				padding-top: 0vh;
			}
		}

		@media screen and (max-height: 600px) {
			padding-top: 15vh;

			@media screen and (max-width: 700px) {
				padding-top: 0vh;
			}
		}

		@media screen and (max-height: 580px) {
			padding-top: 10vh;

			@media screen and (max-width: 700px) {
				padding-top: 0vh;
			}
		}

		@media screen and (max-height: 550px) {
			padding-top: 0vh;

			@media screen and (max-width: 900px) {
				padding-top: 0vh;
			}
		}

		// @media screen and (max-width: 1410px) {
		// 	@media screen and (max-height: 670px) {
		// 		// padding-top: 10vh;
		// 	}
		// 	@media screen and (max-height: 600px) {
		// 		// padding-top: 10vh;
		// 	}
		// }

		@media screen and (max-width: 400px) {
			padding-top: 0vh;
		}

		h2 {
			color: #ddad51;
			font-family: "Sedan SC";
			font-size: 6rem;
			font-style: normal;
			font-weight: 400;
			line-height: 8rem;

			span {
				color: #ddad51;
				font-family: "Sedan SC";
				font-size: 4rem;
				font-style: normal;
				font-weight: 400;
				line-height: 8rem;
			}
		}

		.p {
			width: 100%;
			min-height: 25rem;
			color: #515151;
			font-family: "Poppins";
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 300;
			line-height: 2.5rem;

			&::-webkit-scrollbar {
				display: none;
			}

			@media screen and (max-width: 700px) {
				min-height: 15rem;
				font-size: 1.4rem;
			}

			@media screen and (max-width: 470px) {
				@media screen and (max-height: 700px) {
					min-height: 18rem;
					font-size: 1.4rem;
				}
			}
		}

		.ButtonContainer {
			margin-top: 1rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 5.5rem;
			gap: 5rem;

			@media screen and (max-width: 700px) {
				padding-right: 0rem;

				@media screen and (max-height: 700px) {
					margin-top: 1rem;
				}
			}

			@media screen and (max-height: 600px) {
				margin-top: 2rem;
			}

			@media screen and (max-height: 580px) {
				margin-top: 1rem;
			}

			.button {
				width: 17.88475rem;
				height: 7.18694rem;
				border-radius: 1.0015rem;
				background: #ddad51;
				box-shadow: 6.676736831665039px 6.676736831665039px 20.030210494995117px 0px rgba(0, 0, 0, 0.25);
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 4.125rem 0 2.437rem;
				cursor: pointer;

				h6 {
					color: #fff;
					font-family: "Poppins";
					font-size: 1.46588rem;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}

				span {
					width: 2.003rem;
					height: 2.003rem;
					color: #fff;
					font-family: "Poppins";
					font-size: 1.46588rem;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}
		}
	}
}

.Container3 {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0 4.81rem 0;

	.Top {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.562rem;
		margin-bottom: 1.687rem;
		position: relative;

		@media screen and (max-width: 460px) {
			padding-bottom: 2rem;
		}

		h1 {
			color: #ddad51;
			font-family: "Sedan SC";
			font-size: 3.85931rem;
			font-style: normal;
			font-weight: 400;
			line-height: 142%; /* 87.683px */
			text-transform: capitalize;

			@media screen and (max-width: 460px) {
				font-size: 2.5rem;
			}
		}

		svg {
			width: 16rem;
			height: 4rem;

			@media screen and (max-width: 460px) {
				width: 10rem;
			}
		}

		button {
			position: absolute;
			bottom: -10%;
			right: 8%;
			background: transparent;
			border: none;
			cursor: pointer;
			color: #ddad51;
			font-family: "Sedan SC";
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 400;
			line-height: 142%; /* 42.6px */
			text-decoration-line: underline;
			letter-spacing: 0.1rem;

			span {
				letter-spacing: 0.1rem;
				font-size: 2rem;
				color: #ddad51;
				font-family: "Sedan SC";
				font-weight: 400;
			}

			@media screen and (max-width: 460px) {
				bottom: -5%;
				right: 3%;
			}
		}
	}

	.Middle {
		width: 100%;
		height: 80vh;
		margin-bottom: 5.93rem;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: 725px) {
			height: 70vh;
		}

		@media screen and (max-width: 500px) {
			height: 60vh;
		}

		.CarouseWrapper {
			height: 100%;
			width: 90.2rem;
			display: flex;
			gap: 2.5rem;
			position: relative;
			overflow: hidden;

			@media screen and (max-width: 725px) {
				width: 100%;
			}

			input {
				display: none;
				position: absolute;
				top: 0;
				z-index: 10;

				&:nth-child(1) {
					left: 1rem;
				}
				&:nth-child(2) {
					left: 2rem;
				}
				&:nth-child(3) {
					left: 3rem;
				}
				&:nth-child(4) {
					left: 4rem;
				}
				&:nth-child(5) {
					left: 5rem;
				}
			}

			.ImageWrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.5s;
				
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}

				video {
					width: 100%;
					max-height: 100%;
				}

				.ImgWrapperActive {
					min-width: 35rem;
					max-width: 35rem;
					height: 100%;
					border-radius: 6.6rem;
					box-shadow: 14.4px 14.4px 21.6px 0px rgba(17, 17, 17, 0.15);
					overflow: hidden;
					transition: 0.5s;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #000;

					// img {
					// 	min-width: 35rem;
					// 	max-width: 35rem;
					// 	transition: 0.5s;
					// }
				}

				.ImgWrapperNormal {
					max-width: 25rem;
					min-width: 25rem;
					height: 80%;
					border-radius: 6.6rem;
					box-shadow: 14.4px 14.4px 21.6px 0px rgba(17, 17, 17, 0.15);
					overflow: hidden;
					transition: 0.5s;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #000;

					// img {
					// 	max-width: 25rem;
					// 	min-width: 25rem;
					// 	transition: 0.5s;
					// }
				}
			}
		}
	}

	.Bottom {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.EditTextIcon {
			width: 4rem;
			height: 4rem;
			position: absolute;
			top: -8%;
			left: 38%;
			cursor: pointer;

			@media screen and (max-width: 700px) {
				top: 20%;
				left: 20%;
			}

			@media screen and (max-width: 455px) {
				top: 20%;
				left: 10%;
			}
		}

		h3 {
			color: #ddad51;
			text-align: center;
			font-family: "Sedan SC";
			font-size: 1.98375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 103.9%; /* 32.978px */
			margin-bottom: 1.43rem;
		}

		.p {
			width: 30vw;
			min-height: 10rem;
			color: #848484;
			text-align: center;
			font-family: "Poppins";
			font-size: 17.313px;
			font-style: normal;
			font-weight: 300;
			line-height: 162.4%; /* 28.116px */
			margin-bottom: 1.7rem;
			
			@media screen and (max-width: 725px) {
				width: 90%;
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}

		h2 {
			color: #ddad51;
			font-family: " Noto Serif";
			font-size: 2.88919rem;
			font-style: normal;
			font-weight: 400;
			line-height: 101.4%; /* 46.874px */
		}
	}
}
