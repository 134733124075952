html {
	&::-webkit-scrollbar {
		display: none;
	}
}

.Template3Wrapper {
	height: 100vw;
	height: 100vh;
	scrollbar-width: none;
	background: var(--frsd, radial-gradient(50% 50% at 50% 50%, #0e2138 0%, #0a1b30 100%));
	scroll-behavior: smooth;
	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template3 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		position: relative;
		background: #fff;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.container1 {
	position: relative;
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	position: relative;

	.coverChangeIcon {
		position: absolute;
		top: 2%;
		left: 47%;
		height: 3.5rem;
		width: 3.5rem;
	}

	.coverImage {
		height: 100%;
		width: 100%;
	}

	.coverImage img {
		height: 100%;
		width: 100%;
		object-fit: contain;
		@media (max-width: 930px) and (min-height: 600px) {
			height: 70%;
			object-fit: cover;
		}
	}

	.coverFrame {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;

		@media (max-width: 930px) and (min-height: 600px) {
			height: 100%;
		}
	}

	.coverFrame img {
		height: 100%;
		width: 100%;
	}

	.RatingContainer {
		width: 5.52rem;
		aspect-ratio: 1/1;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 5%;
		right: 16%;
		z-index: 8;
		cursor: pointer;

		@media (max-width: 930px) and (min-height: 600px) {
			bottom: 22%;
			right: 10%;
			width: 6.52rem;
		}

		span {
			position: absolute;
			color: #fff;
			font-family: "Poppins";
			font-size: 0.99213rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	.coverName {
		position: absolute;
		top: 57%;
		right: 8%;
		height: 6rem;
		width: 40rem;
		max-width: 80vh;
		font-family: Poly;
		font-size: 4.61106rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		background: var(--gold, linear-gradient(179deg, #cc8d69 0%, #ed8a53 33.33%, #f86e42 63.02%, #e25629 92.19%));
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transform: rotate(-90deg);
		text-align: center;

		@media (max-width: 1410px) {
			@media (max-height: 562px) {
				right: 10%;
			}
		}
		@media (max-width: 930px) and (min-height: 600px) {
			top: 38%;
			right: -6%;
		}
		@media (max-width: 670px) {
			@media (max-height: 562px) {
				right: 6%;
			}
		}
		@media (max-width: 630px) and (min-height: 600px) {
			top: 38%;
			right: -21%;
		}

		@media (max-width: 400px) {
			top: 38%;
			right: -25%;
		}
	}

	.buttons {
		position: absolute;
		right: 2rem;
		top: 40%;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		z-index: 10;
		@media (max-width: 930px) and (min-height: 600px) {
			top: 83%;
			right: 4rem;
		}

		@media (max-width: 380px) {
			top: 81%;
		}

		.follow {
			color: #fff;
			font-family: Poppins;
			font-size: 1.625rem;
			font-style: normal;
			font-weight: 500;
			line-height: 94.5%;
			letter-spacing: 0.07313rem;
			text-transform: uppercase;
			padding: 1rem 5rem 1rem 2rem;
			border-radius: 0rem 1.5625rem 0rem 0rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.6rem;
			background: var(--gor, linear-gradient(117deg, rgba(255, 0, 0, 0.9) 0%, #ff9c09 63.54%, #ff3d00 100%));
			cursor: pointer;
			border: none;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 2.3rem;
				padding: 2rem 7rem 2rem 3rem;
			}
			@media (max-width: 380px) {
				font-size: 2.1rem;
				padding: 2rem 6rem 2rem 3rem;
			}
		}

		.message {
			color: #ffa41b;
			font-family: Poppins;
			font-size: 1.625rem;
			font-style: normal;
			font-weight: 500;
			line-height: 94.5%;
			letter-spacing: 0.07313rem;
			text-transform: uppercase;
			padding: 1.2rem 2rem 1.2rem 5rem;
			border-radius: 0rem 0rem 0rem 1.5625rem;
			border: 1px solid #ffa41b;
			cursor: pointer;
			@media (max-width: 930px) and (min-height: 600px) {
				font-size: 2.3rem;
				padding: 2rem 3rem 2rem 6rem;
			}
			@media (max-width: 380px) {
				font-size: 2.1rem;
				padding: 2rem 3rem 2rem 6rem;
			}
		}
	}

	.followerPost {
		position: absolute;
		top: 40%;
		left: 2rem;

		@media (max-width: 930px) and (min-height: 600px) {
			top: 78%;
			left: 4rem;
		}

		h2 {
			font-family: Inter;
			font-size: 3.45438rem;
			font-style: normal;
			font-weight: 800;
			line-height: 94.5%;
			background: var(--gor, linear-gradient(117deg, rgba(255, 0, 0, 0.9) 0%, #ff9c09 63.54%, #ff3d00 100%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			@media (max-width: 650px) {
				font-size: 3.9155rem;
			}
		}

		h3 {
			color: #000;
			font-family: Poppins;
			font-size: 1.5155rem;
			font-style: normal;
			font-weight: 500;
			line-height: 94.5%;
			/* 1.43213rem */
			letter-spacing: 0.13638rem;
			text-transform: uppercase;
			@media (max-width: 650px) {
				font-size: 1.9155rem;
			}
		}

		.follower {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.6rem;
			margin-bottom: 5rem;
			@media (max-width: 380px) {
				margin-bottom: 3rem;
			}
		}

		.post {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.6rem;
			margin-left: 6rem;
		}
	}
}

.container2 {
	position: relative;
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	position: relative;
	overflow: hidden;

	.leftDot {
		position: absolute;
		left: 1.22rem;
		top: 1.22rem;
		height: 3rem;
		width: 8rem;
		@media (max-width: 930px) and (min-height: 600px) {
			display: none;
		}
	}
	.leftDot img {
		height: 100%;
		width: 100%;
	}
	.rightDot {
		position: absolute;
		right: 1.22rem;
		bottom: 1.22rem;
		height: 3rem;
		width: 8rem;
		@media (max-width: 930px) and (min-height: 600px) {
			display: none;
		}
	}
	.rightDot img {
		height: 100%;
		width: 100%;
	}
	.myIntro {
		position: absolute;
		bottom: 5.42vh;
		right: 20rem;
		font-family: Poly;
		font-size: 5.70681rem;
		font-style: italic;
		font-weight: 400;
		line-height: normal;
		background: var(--gold, linear-gradient(179deg, #cc8d69 0%, #ed8a53 33.33%, #f86e42 63.02%, #e25629 92.19%));
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@media (max-width: 930px) and (min-height: 600px) {
			display: none;
		}
	}

	.image_container {
		height: 100%;
		width: 100%;
		display: flex;
		position: absolute;

		.frame1 {
			height: 100%;
			width: 100%;
			position: absolute;
			clip-path: polygon(40.5% 16%, 41% 16%, 29% 80%, 28.5% 80%);
			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(50.5% 16%, 51% 16%, 18% 65%, 17.5% 65%);
			}

			img {
				position: absolute;
				// bottom: 0;
				height: 98%;
				width: 60%;
			}
		}
		.frame2 {
			height: 100%;
			width: 100%;
			position: absolute;
			clip-path: polygon(71.5% 16%, 72% 16%, 62% 70%, 61.5% 70%);
			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(96.5% 16%, 97% 16%, 61.5% 70%, 61% 70%);
			}

			img {
				position: absolute;
				// bottom: 0;`
				height: 98%;
				width: 100%;
			}
		}
		.frame3 {
			height: 100%;
			width: 100%;
			position: absolute;
			clip-path: polygon(95.5% 42%, 96% 42%, 88.5% 81%, 78% 81%, 78% 80%, 88% 80%);

			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(92% 87%, 92.5% 87%, 85.2% 99.3%, 70% 99.3%, 70% 99%, 84.5% 99%);
			}

			img {
				position: absolute;
				// bottom: 0;
				height: 100%;
				width: 100%;
			}
		}
		.frame4 {
			height: 100%;
			width: 100%;
			position: absolute;
			clip-path: polygon(90% 86%, 90.5% 86%, 88% 98%, 9% 99%, 9% 97%, 87.5% 97%);
			@media (max-width: 930px) and (min-height: 600px) {
				display: none;
			}

			img {
				position: absolute;
				// bottom: 0;
				height: 98%;
				width: 100%;
			}
		}
		.frame5 {
			height: 100%;
			width: 100%;
			position: absolute;
			clip-path: polygon(13% 6%, 21% 6%, 21% 7%, 13.5% 7%, 5% 51%, 4.5% 51%);

			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(16% 1.3%, 35% 1.3%, 35% 1.5%, 16.5% 1.5%, 5% 20%, 4.5% 20%);
			}

			img {
				position: absolute;
				// bottom: 0;
				height: 98%;
				width: 100%;
			}
		}

		.introImage1 {
			height: 100%;
			width: 100%;
			position: absolute;
			clip-path: polygon(14% 8%, 41.5% 8%, 25% 96%, 0 96%, 0 81%);

			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(17% 2%, 58% 2%, 0 88%, 0 29%);
			}

		}
		.editIntro1Icon {
			position: absolute;
			top: 12%;
			left: 19%;
			height: 3.5rem;
			width: 3.5rem;
		}

		.introImage1 img {
			position: absolute;
			bottom: 0;
			height: 98%;
			width: 42%;

			// object-fit: cover;
			@media (max-width: 930px) and (min-height: 600px) {
				top: 2%;
				width: 58%;
				height: 87%;
			}
		}

		.introImage2 {
			height: 100%;
			width: 100%;
			position: absolute;
			clip-path: polygon(44% 5%, 73% 5%, 56% 96%, 27% 96%);
			background: #b8d3dc;

			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(63% 1%, 100% 1%, 100% 8%, 40% 100%, 0 100%, 0 93%);
			}

		}
		.editIntro2Icon {
			position: absolute;
			height: 3.5rem;
			width: 3.5rem;
			top: 13%;
			left: 49%;

			@media (max-width: 930px) and (min-height: 600px) {
				left: 58%;
			}
		}

		.introImage2 img {
			position: absolute;
			left: 15%;
			height: 100%;
			width: 64%;

			@media (max-width: 930px) and (min-height: 600px) {
				left: 0;
				width: 100%;
				top: 0%;
				height: 100%;
			}
		}

		.introImage3 {
			height: 100%;
			width: 100%;
			position: absolute;
			bottom: 0;
			clip-path: polygon(100% 0, 100% 12%, 87% 79%, 61.5% 79%, 76% 0);
			// clip-path: polygon(74% 0%, 100% 0%,94% 4%, 87% 87%, 60% 87%);

			@media (max-width: 930px) and (min-height: 600px) {
				clip-path: polygon(100% 14%, 100% 73%, 84% 98.5%, 44% 98.5%);
			}
		}
		.editIntro3Icon {
			height: 3.5rem;
			width: 3.5rem;
			position: absolute;
			top: 10%;
			left: 77%;

			@media (max-width: 930px) and (min-height: 600px) {
				top: 42%;
				left: 82%;
			}
		}

		.introImage3 img {
			position: absolute;
			right: 0;
			bottom: 14rem;
			height: 100%;
			width: 40%;

			@media (max-width: 930px) and (min-height: 600px) {
				width: 67%;
				height: 87%;
				right: -6rem;
				bottom: 0;
			}
		}
	}
}

.container3 {
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	position: relative;
	background-color: white;
	// background: #e25629;
	// display: none;
	@media (max-width: 930px) and (min-height: 600px) {
		min-height: 160vh;
		max-height: 200vh;
	}

	.EditTextIcon {
		position: absolute;
		top: 2vh;
		left: 48vw;
		height: 3.5rem;
		width: 3.5rem;
		z-index: 5;
		@media (max-width: 930px) and (min-height: 600px) {
			top: 99vh;
			left: 8vw;
		}
	}
	.bottomDot {
		position: absolute;
		bottom: 3%;
		right: 2%;
		height: 4rem;
		width: 8rem;
		@media (max-width: 930px) and (min-height: 600px) {
			right: 6%;
			bottom: 6%;
			width: 12rem;
			height: 5rem;
		}
	}
	.bottomDot img {
		height: 100%;
		width: 100%;
	}

	.myIntro {
		width: 100%;
		margin-top: 4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		z-index: 9;
		font-family: Poly;
		font-size: 5.70681rem;
		font-style: italic;
		font-weight: 400;
		line-height: normal;
		background: var(--gold, linear-gradient(179deg, #cc8d69 0%, #ed8a53 33.33%, #f86e42 63.02%, #e25629 92.19%));
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		display: none;
		@media (max-width: 930px) and (min-height: 600px) {
			display: flex;
		}
	}

	.galleryImage {
		height: 100%;
		width: 100%;
		position: relative;
		@media (max-width: 930px) and (min-height: 600px) {
			width: 100%;
			min-height: 100vh;
		}

		img {
			height: 100%;
			width: 50%;
			object-fit: contain;

			@media (max-width: 930px) and (min-height: 600px) {
				position: absolute;
				top: 0;
				height: 100%;
				max-height: 100vh;
				width: 100%;
			}
		}

	}
	.introChangeIcon {
		position: absolute;
		top: 2rem;
		left: 3rem;
		height: 3.5rem;
		width: 3.5rem;

		@media(max-width:930px) and (min-height:600px){
			top: 24rem;
			left: 28rem;
		}
	}

	.galleryFrame {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;

		img {
			height: 100%;
			width: 100%;
		}

		@media (max-width: 930px) and (min-height: 600px) {
			height: 100vh;
			max-height: 100vh;
			min-width: 100vw;
		}
	}

	.galleryDesc {
		position: absolute;
		right: 5%;
		top: 10%;
		color: black;
		width: 49.244rem;
		max-width: 50vw;
		font-family: Inter;
		font-size: 2rem;
		font-style: normal;
		font-weight: 500;
		line-height: 163.5%;
		text-align: center;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 6;
		overflow: hidden;

		&::-webkit-scrollbar {
			display: none;
		}

		@media (max-width: 930px) and (min-height: 600px) {
			// border: 1px solid red;
			top: 103%;
			right: 10%;
			min-width: 80vw;
			-webkit-line-clamp: 11;
		}
	}

	.buttons {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 60%;
		width: 50rem;
		max-width: 50vw;
		right: 5rem;
		gap: 4rem;

		@media (max-width: 930px) and (min-height: 600px) {
			top: 128%;
			right: 4rem;
			min-width: 90vw;
		}

		button {
			span {
				cursor: pointer;
			}
			font-family: Inter;
			font-size: 4.23244rem;
			font-style: normal;
			font-weight: 700;
			line-height: 138%;
			border: none;
			position: relative;
			white-space: nowrap;
			/* 5.84075rem */
			background: var(--gor, linear-gradient(117deg, rgba(255, 0, 0, 0.9) 0%, #ff9c09 63.54%, #ff3d00 100%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			&:first-child {
				margin-right: 32%;
			}

			// &:last-child {
			// 	margin-left: 32%;
			// }

			&::before {
				content: "";
				position: absolute;
				bottom: -1rem;
				width: 25%;
				height: 0.1rem;
				border-top: 2px solid #323232;
			}
		}
	}
}

.container4 {
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	h1 {
		font-family: "Poly";
		font-size: 5.70681rem;
		font-style: italic;
		font-weight: 400;
		line-height: normal;
		background: var(--gold, linear-gradient(179deg, #cc8d69 0%, #ed8a53 33.33%, #f86e42 63.02%, #e25629 92.19%));
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		padding: 0 10rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: 700px) {
			padding: 0 5rem;
			flex-direction: column;
		}

		@media screen and (max-width: 550px) {
			padding: 0 1rem;
		}

		p {
			color: #000000;
			font-family: "Poly";
			font-size: 1.8rem;
			font-style: normal;
			font-weight: 400;
			line-height: 138%;
			/* 2.76rem */
			background: var(--gold, linear-gradient(179deg, #cc8d69 0%, #ed8a53 33.33%, #f86e42 63.02%, #e25629 92.19%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			cursor: pointer;
		}
	}

	.LeftArrow {
		position: absolute;
		top: 40%;
		left: 3%;
		width: 3.5rem;
		height: 3.5rem;
		font-size: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: none;
		cursor: pointer;

		@media screen and (max-width: 700px) {
			left: 1%;
		}

		@media screen and (max-width: 550px) {
			top: 6.5%;
			left: auto;
			right: 15%;
		}
	}

	.RightArrow {
		position: absolute;
		top: 40%;
		right: 3%;
		width: 3.5rem;
		height: 3.5rem;
		font-size: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: none;
		cursor: pointer;

		@media screen and (max-width: 700px) {
			right: 1%;
		}

		@media screen and (max-width: 550px) {
			top: 6.5%;
			left: auto;
			right: 6%;
		}
	}

	.sliderContainer {
		height: 100%;
		width: 100%;
		overflow: hidden;
		padding: 0 10rem;

		@media screen and (max-width: 700px) {
			padding: 0 5rem;
		}

		@media screen and (max-width: 550px) {
			padding: 0 1rem;
		}

		.CarouselWrapper {
			width: 100%;
			// height: 45rem;
			height: 100%;
			overflow: hidden;

			.CarouselContainer {
				height: 100%;
				width: 100%;
				// width: 500%;
				display: flex;
				position: relative;

				input {
					display: none;
				}
			}

			.ActiveImageContainer {
				position: relative;
				min-width: 50rem;
				max-width: 50rem;
				height: 100%;
				overflow: hidden;
				transition: 0.5s;
				z-index: 2;
				background: #000;
				display: flex;
				align-items: center;
				justify-content: center;

				@media screen and (max-width: 550px) {
					min-width: 100%;
					max-width: 100%;
				}

				img {
					// max-width: 100%;
					// max-height: 100%;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.ImageContainer {
				position: relative;
				min-width: 50rem;
				max-width: 50rem;
				height: 100%;
				overflow: hidden;
				transition: 0.5s;
				z-index: 1;
				margin-right: -20rem;
				margin-left: 0rem;
				background: #000;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					// max-width: 100%;
					// max-height: 100%;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.ActiveFloatingContainer {
				width: 46.53575rem;
				height: 5.69688rem;
				background: rgba(15, 15, 15, 0.6);
				backdrop-filter: blur(3.843660831451416px);
				position: absolute;
				bottom: 1.86rem;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				align-items: center;
				padding: 0 2.87rem 0 2.16rem;
				gap: 4rem;
				transition: 0.5s;

				@media screen and (max-width: 500px) {
					min-width: 100%;
					max-width: 100%;
				}

				.Left {
					flex: 1;
					color: #fff;
					font-family: "Poppins";
					font-size: 1.125rem;
					font-style: normal;
					font-weight: 400;
					line-height: 123.4%;
				}

				.Right {
					height: 2rem;
					display: flex;
					align-items: center;
					gap: 2rem;

					.Icon {
						height: 100%;
						display: flex;
						align-items: center;
						gap: 0.31rem;
						overflow: hidden;
						cursor: pointer;

						img {
							height: 100%;
							width: 100%;
							object-fit: contain;
						}

						p {
							width: 1.8rem;
							height: 1.8rem;
							color: #fff;
							font-family: "Poppins";
							font-size: 1.46006rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%;
							/* 1.80175rem */
						}
					}
				}
			}

			.DisableFloatingContainer {
				transition: 0.5s;
			}
		}
	}

	.shortMessage {
		width: 50vw;
		height: 10rem;
		max-height: 12vh;
		margin: auto;
		color: #323232;
		text-align: center;
		font-family: Poppins;
		font-size: 1.375rem;
		font-style: normal;
		font-weight: 400;
		// line-height: 172%;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	h4 {
		width: 100%;
		margin: 0 auto;
		color: #323232;
		font-family: Poppins;
		font-size: 2.625rem;
		font-style: normal;
		font-weight: 400;
		line-height: 94.5%;
		text-align: center;
		padding-bottom: 2rem;
	}
}
