.Component1 {
	width: 100%;
	height: 100vh;
	z-index: 2;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.backgroundImage1 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: -1;
		user-select: none;

		img {
			height: 100%;
		}
	}

	.divideContainer {
		position: relative;
		width: 100%;
		height: 55rem;
		display: flex;
		align-items: flex-end;
		justify-content: space-around;
		gap: 2.9rem;
		z-index: 2;
		overflow: hidden;

		.div1 {
			width: calc(20.32rem + 10.5rem + 12.5rem);
			height: 100%;
			overflow: hidden;
			padding-left: 10.5rem;
			padding-top: 7.1rem;
			position: relative;

			img {
				width: 12.5rem;
				height: 7.5rem;
				margin-bottom: 4.5rem;
			}

			h1 {
				font-family: Bellefair;
				font-weight: 400;
				font-size: 8rem;
				line-height: 87.02%;
				color: var(--color-t09-text1);
				margin-bottom: 1.99rem;
				width: 100%;
				max-height: 22rem;
			}

			.buttonContainer {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 0.5rem;
				position: absolute;
				width: 20.9375rem;
				z-index: 2;

				.btn1 {
					width: 100%;
					height: 4.375rem;
					background: var(--color-t09-background1);
					border-radius: 0.5rem;
					border: none;
					font-family: Inter;
					font-size: 1rem;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					letter-spacing: 0.265rem;
					text-transform: uppercase;
					cursor: pointer;
				}

				.btn2 {
					width: 100%;
					height: 4.375rem;
					background: var(--color-t09-background2);
					border-radius: 0.5rem;
					font-family: Inter;
					font-size: 1rem;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					letter-spacing: 0.265rem;
					text-transform: uppercase;
					color: #262626;
					border: 1px solid var(--color-t09-background1);
					cursor: pointer;
				}
			}
		}

		.div2 {
			width: 40rem;
			height: 100%;
			overflow: hidden;
			position: relative;
			filter: drop-shadow(15px 15px 25px rgba(0, 0, 0, 0.15));

			img {
				width: 100%;
				height: 100%;
			}
		}

		.div3 {
			width: 25rem;
			overflow: hidden;
			position: relative;
			padding-bottom: 2.5rem;

			.sub {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-right: 4.81rem;
				margin-bottom: 2.2rem;

				.title1 {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					.titleNumber {
						font-family: var(--font-Roboto);
						font-style: normal;
						font-weight: 500;
						font-size: 2.70856rem;
						color: var(--color-t09-text3);
					}

					.titleText {
						font-style: normal;
						font-weight: 400;
						font-size: 1.264rem;
						font-family: var(--font-Roboto);
						color: var(--color-t09-text3);
					}
				}
			}

			.Box2 {
				width: 100%;

				.b1 {
					h3 {
						width: 124px;
						height: 42px;
						font-family: var(--font-Bellefair);
						font-weight: 400;
						font-size: 2.31006rem;
						color: var(--color-t09-text4);
					}
					p {
						font-weight: 200;
						font-size: 1.01063rem;
						font-style: normal;
						color: #8c8c8c;
						font-family: var(--font-Roboto);
					}
				}

				.b2 {
					margin-top: 1.16rem;
					display: flex;
					align-items: center;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 1.19rem;
					padding-right: 3.18rem;

					div {
						width: 2.53494rem;
						height: 2.53494rem;
					}

					svg {
						width: 100%;
						height: 100%;
						cursor: pointer;
						path {
							fill: var(--color-t09-text2);
						}
						&:hover {
							path {
								fill: var(--color-t09-icon1);
							}
						}
					}
				}
			}
		}

		.RatingContainer {
			position: absolute;
			bottom: 0;
			left: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 7rem;
			height: 7rem;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}

			p {
				color: #fff;
				font-family: Poppins;
				font-size: 1.31556rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				position: absolute;
			}
		}
	}

	.logosContainer {
		width: 100%;
		height: 3.63725rem;
		overflow: hidden;

		.logos {
			width: 100%;
			height: 100%;

			img {
				height: 100%;
				margin-right: 3.81rem;
			}
		}
	}
}
