.TemplateWrapper {
	height: 100%;
	height: 100%;
	background: #ffffff;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template6 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		// border: 1px solid #f00;
		background: #fff;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}

	.Container1 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.mainContent {
			display: flex;
			flex-direction: column;
			padding: 2rem 0 0 2rem;
			.logo {
				display: flex;
				width: 12.5rem;
				height: 7.5rem;
				justify-content: center;
				align-items: center;
				border: 0.769px solid #000;
				margin-bottom: 2rem;
				@media (orientation: portrait) {
					width: 6.60788rem;
					height: 3.96469rem;
					border: 0.407px solid #000;
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}

			.heroSection {
				align-self: flex-end;
				width: 90.83369rem;
				height: 33.56369rem;
				position: relative;
				border-radius: 2.19656rem 0rem 0rem 2.19656rem;
				@media (orientation: portrait) {
					width: 23.36975rem;
					height: 19.16263rem;
					border-radius: 0.77738rem 0rem 0rem 0.77738rem;
				}
				.banner {
					width: 100%;
					height: 100%;
					flex-shrink: 0;
					border-radius: 2.19656rem 0rem 0rem 2.19656rem;
					@media (orientation: portrait) {
						border-radius: 0.77738rem 0rem 0rem 0.77738rem;
					}
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
						border-radius: inherit;
					}

					.CoverUploadBtn {
						width: 4rem;
						height: 4rem;
						cursor: pointer;
						position: absolute;
						top: 2rem;
						right: 2rem;
						@media (orientation: portrait) {
							width: 3rem;
							height: 3rem;
						}
					}
				}

				.postSubscriber {
					width: 12.18544rem;
					height: 18.41444rem;
					flex-shrink: 0;
					border-radius: 0.8645rem;
					border: 1.383px solid #5200ff;
					background: rgba(0, 0, 0, 0.4);
					box-shadow: 0px 5.533px 5.533px 0px rgba(0, 0, 0, 0.25);
					backdrop-filter: blur(20.74795913696289px);
					position: absolute;
					left: 0;
					top: 0;
					transform: translate(-50%, 50%);
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 2rem;
					@media (orientation: portrait) {
						width: 5.30219rem;
						height: 8.01263rem;
						border-radius: 0.37619rem;
						border: 1.5px solid #5200ff;
						background: rgba(0, 0, 0, 0.4);
						box-shadow: 0px 2.407px 2.407px 0px rgba(0, 0, 0, 0.25);
						backdrop-filter: blur(9.028002738952637px);
					}
					.posts,
					.subscribers {
						display: flex;
						flex-direction: column;
						align-items: center;
						h2 {
							color: #fff;
							font-family: Roboto;
							font-size: 2.99556rem;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							@media (orientation: portrait) {
								font-size: 1.30344rem;
							}
						}
						p {
							color: #fff;
							font-family: Roboto;
							font-size: 1.39794rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							@media (orientation: portrait) {
								font-size: 0.60825rem;
							}
						}
					}
				}
			}
		}

		.descSection {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 2rem 8rem 2rem 3rem;
			@media (orientation: portrait) {
				flex-direction: column;
				gap: 3rem;
				position: relative;
				padding: 0;
			}
			.leftSection {
				display: flex;
				align-items: center;
				gap: 4rem;
				.RatingContainer {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 7rem;
					height: 7rem;
					cursor: pointer;
					position: relative;
					@media (orientation: portrait) {
						width: 3.99925rem;
						height: 3.99925rem;
						flex-shrink: 0;
						position: absolute;
						bottom: 0;
					}

					img {
						width: 100%;
						height: 100%;
					}

					p {
						color: #fff;
						font-family: Poppins;
						font-size: 1.31556rem;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						position: absolute;
						@media (orientation: portrait) {
							color: #fff;
							leading-trim: both;
							text-edge: cap;
							font-family: Poppins;
							font-size: 0.71819rem;
						}
					}
				}

				.nameAction {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					position: relative;

					.EditTextIcon {
						height: 4rem;
						width: 4rem;
						position: absolute;
						top: -4rem;
						left: 5rem;
						@media (orientation: portrait) {
							height: 3rem;
							width: 3rem;
							left: 15rem;
						}
					}
					.name {
						color: #000;
						font-family: Bellefair;
						font-size: 4.69144rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						@media (orientation: portrait) {
							font-size: 3.60256rem;
							white-space: nowrap;
						}
					}
					.buttons {
						display: flex;
						align-items: center;
						gap: 3rem;

						button {
							font-family: Roboto;
							font-size: 2.01056rem;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							border: none;
							background: transparent;
							@media (orientation: portrait) {
								font-size: 0.9735rem;
							}
							&:nth-child(1) {
								color: #000;
							}
							&:nth-child(2) {
								color: rgba(0, 0, 0, 0.48);
							}
						}
					}
				}
			}

			.socialContainer {
				width: 23.75rem;
				height: 9.3125rem;
				border-radius: 1.47319rem;
				border-radius: 1.47319rem;
				background: var(--new-cad-gradient, linear-gradient(92deg, #e85bff 4.84%, #232197 95.29%));
				padding: 1rem;
				display: flex;
				flex-direction: column;
				gap: 0.1rem;
				@media (orientation: portrait) {
					width: 14.4375rem;
					height: 7.6875rem;
					align-self: flex-end;
				}
				h3 {
					color: #fff;
					font-family: Bellefair;
					font-size: 1.815rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (orientation: portrait) {
						font-size: 1.385rem;
					}
				}
				h4 {
					color: #fbfbfb;
					font-family: Roboto;
					font-size: 0.79406rem;
					font-style: normal;
					font-weight: 200;
					line-height: normal;
					@media (orientation: portrait) {
						font-size: 0.60594rem;
					}
				}
				.socials {
					margin-top: 0.5rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					height: 2rem;
					.whatsapp,
					.phone,
					.mail,
					.location,
					.store {
						width: 1.79175rem;
						height: 1.79175rem;
						@media (orientation: portrait) {
							width: 1.12981rem;
							height: 1.12981rem;
						}
						img {
							height: 100%;
							width: 100%;
						}
					}
				}
			}
		}

		.adds {
			height: 4rem;
			width: 100%;
			display: flex;
			align-items: center;
			background: #fff;
			padding: 0.2rem;
			@media (orientation: portrait) {
				height: 9.51vh;
			}
			.logos {
				display: flex;
				align-items: center;
				.cnbc {
					// width: 4.55769rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (orientation: portrait) {
						width: 16.46vw;
						height: 4.267vh;
					}
				}
				.bbc {
					// width: 6.4685rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (orientation: portrait) {
						width: 16.46vw;
						height: 4.267vh;
					}
				}
				.time {
					// width: 6.4685rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (orientation: portrait) {
						width: 70.644px;
						height: 4.267vh;
					}
				}
				.newyorkTimes {
					// width: 9.69175rem;
					height: 3.63856rem;
					opacity: 0.6;
					margin-right: 2vw;
					@media (orientation: portrait) {
						width: 24.65vw;
						height: 4.267vh;
					}
				}
			}
		}
	}

	.Container2 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.heroSection {
			width: 85.42406rem;
			min-width: 70vw;
			height: 32.23213rem;
			// border-radius: 2.19244rem;
			align-self: flex-end;
			transform: translate(5%, 5%);
			@media (orientation: portrait) {
				transform: translate(0%, 5%);
				width: 21.45181rem;
				height: 19.08531rem;
			}

			.aboutImg1 {
				width: 85.42406rem;
				width: 100%;
				// min-width: 70vw;
				height: 32.23213rem;
				border-radius: 2.19244rem;
				// border: 3.508px solid #001aff;
				background: lightgray 50% / cover no-repeat;
				position: relative;
				@media (orientation: portrait) {
					width: 100%;
					height: 100%;
					// border: 1.772px solid #001aff;
					border-radius: 0;
				}
				img {
					height: 100%;
					width: 100%;
					// object-fit: cover;
				}
				.aboutChange1 {
					position: absolute;
					height: 4rem;
					width: 4rem;
					top: 1rem;
					right: 7rem;
					@media (orientation: portrait) {
						right: 2rem;
						height: 3rem;
						width: 3rem;
						img {
							border-radius: 0;
						}
					}
				}

				.aboutImg2 {
					width: 48.12031rem;
					min-width: 40vw;
					height: 26.63075rem;
					flex-shrink: 0;
					border-radius: 2.32475rem;
					// border: 3.72px solid #e85bff;
					position: absolute;
					left: 0;
					bottom: 0;
					transform: translate(-30%, 30%);
					@media (orientation: portrait) {
						width: 18.0125rem;
						height: 13.37038rem;
						border-radius: 1.16719rem;
						// border: 1.867px solid #e85bff;
					}
					img {
						width: 100%;
						height: 100%;
						// object-fit: cover;
						// border-radius: 2.32475rem;
					}
					.aboutChange2 {
						height: 4rem;
						width: 4rem;
						position: absolute;
						top: 1rem;
						right: 3rem;
						@media (orientation: portrait) {
							height: 3rem;
							width: 3rem;
							img {
								border-radius: 0;
							}
						}
					}
				}
			}
		}
		.descSection {
			display: flex;
			justify-content: center;
			gap: 3rem;
			padding-bottom: 3rem;
			position: relative;
			@media (orientation: portrait) {
				flex-direction: column;
				padding: 2rem;
			}
			.EditTextIcon {
				height: 4rem;
				width: 4rem;
				position: absolute;
				top: -5rem;
				left: 50%;
				@media (orientation: portrait) {
					height: 3rem;
					width: 3rem;
					top: 45%;
					left: 85%;
				}
			}
			h2 {
				width: 19.73175rem;
				color: #424141;
				font-family: Bellefair;
				font-size: 4.5rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			.aboutDesc {
				width: 38.65638rem;
				height: 15.9515rem;
				flex-shrink: 0;
				color: #424141;
				font-family: Roboto;
				font-size: 2rem;
				font-style: normal;
				font-weight: 400;
				line-height: 120%; /* 2.4rem */
				@media (orientation: portrait) {
					width: 21.83606rem;
					height: 9.01063rem;
					font-size: 1.12975rem;
				}
			}
			button {
				align-self: flex-end;
				display: inline-flex;
				height: 6.33631rem;
				padding: 1.41444rem 4.24338rem;
				justify-content: center;
				align-items: center;
				gap: 0.70725rem;
				flex-shrink: 0;
				border-radius: 0.56581rem;
				border: 1px solid #000;
				background: #fff;
				color: #323232;
				font-family: Inter;
				font-size: 1.49088rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0.39506rem;
				text-transform: uppercase;
				@media (orientation: portrait) {
					height: 3.57925rem;
					padding: 0.799rem 2.397rem;
					border-radius: 0.31963rem;
					border: 0.565px solid #000;
					background: #fff;
					align-self: flex-start;
				}
			}
		}
	}
	.Container3 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		background-color: #296e2f;
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		.backImage {
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
			img {
				height: 100%;
				width: 100%;
			}

			.chooseusChangeIcon {
				height: 4rem;
				width: 4rem;
				cursor: pointer;
				position: absolute;
				top: 2rem;
				right: 2rem;
				@media (orientation: portrait) {
					height: 3rem;
					width: 3rem;
				}
			}
		}
		.details {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			position: relative;

			.EditTextIcon {
				width: 4rem;
				height: 4rem;
				position: absolute;
				right: -1rem;
				top: 1rem;
				@media (orientation: portrait) {
					height: 3rem;
					width: 3rem;
					right: -1rem;
					top: 1rem;
				}
			}
			h2 {
				color: #fff;
				font-family: Bellefair;
				font-size: 6.0695rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@media (orientation: portrait) {
					color: #fff;
					font-family: Bellefair;
					font-size: 3.25919rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
			.chooseusText {
				width: 72.10581rem;
				color: #fff;
				font-family: Roboto;
				font-size: 2rem;
				font-style: normal;
				font-weight: 400;
				line-height: 120%;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 6;
				overflow: hidden;
				@media (orientation: portrait) {
					width: 24.00506rem;
					// height: 21.66581rem;
					font-size: 1.625rem;
					-webkit-line-clamp: 10;
				}
			}
		}
	}
	.Container4 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		padding: 2rem 0;

		.upper {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 2rem;
			h2 {
				width: 21.96931rem;
				color: #0e0e0e;
				text-align: center;
				font-family: Bellefair;
				font-size: 4.40275rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
				position: relative;
				@media (orientation: portrait) {
					width: 11.73281rem;
					font-size: 2.35131rem;
				}
				&::after {
					content: "";
					width: 11.13619rem;
					height: 1.64125rem;
					stroke-width: 1.364px;
					stroke: #000;
					background-image: url("./assets/wave.svg");
					background-size: cover;
					position: absolute;
					top: 20%;
					left: -50%;
					@media (orientation: portrait) {
						width: 5.94731rem;
						height: 0.8765rem;
						flex-shrink: 0;
						stroke-width: 0.728px;
						stroke: #000;
					}
				}
				&::before {
					content: "";
					width: 11.13619rem;
					height: 1.64125rem;
					stroke-width: 1.364px;
					stroke: #000;
					background-image: url("./assets/wave.svg");
					background-size: cover;
					position: absolute;
					top: 20%;
					right: -50%;
					@media (orientation: portrait) {
						width: 5.94731rem;
						height: 0.8765rem;
						flex-shrink: 0;
						stroke-width: 0.728px;
						stroke: #000;
					}
				}
			}
			p {
				color: #000;
				text-align: center;
				font-family: Bellefair;
				font-size: 2.06131rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
				@media (orientation: portrait) {
					font-size: 1.10088rem;
				}
			}
		}

		.desktopCarousel {
			width: 100%;
			.imageSlider {
				width: 42.256rem;
				height: 33.18081rem;
				flex-shrink: 0;
				border-radius: 1.32813rem;
				border: 1.524px solid #ffaf38;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 28.13%, #000 70.31%),
					lightgray 50% / cover no-repeat;
			}
		}

		.mobileCarousel {
			align-self: flex-end;
			width: 98vw;
			margin: auto;
			margin-right: 0;
			.imageSliderMob {
				padding: 0;
				width: 18.74131rem;
				width: 70vw;
				// width: 100%;
				height: 30.15638rem;
				height: 60vh;
				border-radius: 0.58906rem;
				border: 0.676px solid #ffaf38;
			}
		}
	}
	.Container5 {
		height: 100vh;
		max-height: 100vh;
		width: 100vw;
		max-width: 100vw;
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		@media (orientation: portrait) {
			justify-content: space-around;
		}

		.upper {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 2rem;
			h2 {
				width: 21.96931rem;
				color: #0e0e0e;
				text-align: center;
				font-family: Bellefair;
				font-size: 4.40275rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
				position: relative;
				@media (orientation: portrait) {
					width: 11.73281rem;
					font-size: 2.35131rem;
				}
				&::after {
					content: "";
					width: 11.13619rem;
					height: 1.64125rem;
					stroke-width: 1.364px;
					stroke: #000;
					background-image: url("./assets/wave.svg");
					background-size: cover;
					position: absolute;
					top: 20%;
					left: -50%;
					@media (orientation: portrait) {
						width: 5.94731rem;
						height: 0.8765rem;
						flex-shrink: 0;
						stroke-width: 0.728px;
						stroke: #000;
					}
				}
				&::before {
					content: "";
					width: 11.13619rem;
					height: 1.64125rem;
					stroke-width: 1.364px;
					stroke: #000;
					background-image: url("./assets/wave.svg");
					background-size: cover;
					position: absolute;
					top: 20%;
					right: -50%;
					@media (orientation: portrait) {
						width: 5.94731rem;
						height: 0.8765rem;
						flex-shrink: 0;
						stroke-width: 0.728px;
						stroke: #000;
					}
				}
			}
			p {
				color: #000;
				text-align: center;
				font-family: Bellefair;
				font-size: 2.06131rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
				@media (orientation: portrait) {
					font-size: 1.10088rem;
				}
			}
		}

		.carousel {
			margin-left: 10vw;
			@media (orientation: portrait) {
				margin-left: 8%;
			}
		}

		.thankYou {
			color: #434343;
			font-family: Oooh Baby;
			font-size: 3.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-align: center;
			@media (orientation: portrait) {
				font-size: 6.05vw;
			}
		}
	}

	.postMainContainer {
		width: 51.80419rem;
		width: 97%;
		height: 38.31675rem;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 1;
		border-radius: 2.30594rem;

		@media (orientation: portrait) {
			// width: 20.94394rem;
			width: 88%;
			height: 15.49106rem;
			flex-shrink: 0;
		}

		.media {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
			border-radius: 2.30594rem;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 2.30594rem;
			}
		}

		.profile {
			display: flex;
			align-items: center;
			gap: 1vw;
			padding: 1vh 2vw;
			img {
				width: 4.99031rem;
				height: 4.99031rem;
				border-radius: 50%;
				@media (orientation: portrait) {
					width: 2.0175rem;
					height: 2.0175rem;
				}
			}
			.profileDetails {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-around;
				h4 {
					color: #fff;
					text-align: center;
					font-family: Bellefair;
					font-size: 2.45031rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-transform: uppercase;
					@media (orientation: portrait) {
						font-size: 0.99063rem;
					}
				}
				h5 {
					color: #fff;
					text-align: center;
					font-family: Roboto;
					font-size: 0.95413rem;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					letter-spacing: 0.15269rem;
					text-transform: uppercase;
					@media (orientation: portrait) {
						font-size: 0.38575rem;
						letter-spacing: 0.06175rem;
					}
				}
			}
		}

		.details {
			display: flex;
			align-items: center;
			justify-content: space-around;
			width: 100%;
			height: 4.29156rem;
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(2.9533538818359375px);
			@media (orientation: portrait) {
				width: 100%;
				height: 1.735rem;
			}
			p {
				width: 14.43325rem;
				height: 1.83775rem;
				flex-shrink: 0;
				color: #fff;
				font-family: Poppins;
				font-size: 0.75725rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 0.93444rem */
				@media (orientation: portrait) {
					width: 5.83525rem;
					height: 0.743rem;
					font-size: 0.30613rem;
				}
			}

			.actions {
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 2rem;
				@media (orientation: portrait) {
					gap: 1rem;
				}
				.like,
				.comment,
				.share {
					display: flex;
					align-items: center;
					gap: 0.3rem;
					p {
						height: max-content;
						width: max-content;
						color: #fff;
						font-family: Poppins;
						font-size: 1.85369rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 2.2875rem */
						@media (orientation: portrait) {
							font-size: 0.74944rem;
						}
					}
				}

				.like {
					img {
						width: 2.263rem;
						height: 2.17525rem;
						@media (orientation: portrait) {
							width: 0.75319rem;
							height: 0.71769rem;
						}
					}
				}
				.comment {
					img {
						width: 2.70888rem;
						height: 2.31231rem;
						@media (orientation: portrait) {
							width: 1.09513rem;
							height: 0.93488rem;
						}
					}
				}
				.share {
					img {
						width: 3.387rem;
						height: 1.9975rem;
						@media (orientation: portrait) {
							width: 1.36931rem;
							height: 0.80756rem;
						}
					}
				}
			}
		}
	}

	.Con5Mobile {
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
}
