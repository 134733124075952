.Template7Wrapper {
	height: 100%;
	height: 100%;
	scrollbar-width: none;
	scroll-behavior: smooth;
	overflow: hidden;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 3.5rem;
		height: 3.5rem;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template7 {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.Container1 {
	height: 100vh;
	width: 100vw;
	background-color: transparent;
	position: relative;
	overflow: hidden;

	.Top {
		height: 75%;
		width: 100%;
		position: relative;

		.backgroundImage {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			z-index: -1;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.coverImageIcon {
			top: 18%;
			right: 8%;
		}

		.MainWrapper {
			height: 100vh;
			width: 65rem;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			flex-direction: column;

			@media screen and (max-width: 660px) {
				width: 90%;
			}

			.MainTop {
				min-height: 35vh;
				max-height: 70vh;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				position: relative;

				.ImageWrapper {
					max-height: 100%;
					width: 30rem;
					overflow: hidden;
					position: absolute;
					bottom: 0;

					&::after {
						content: "";
						height: 4px;
						width: 100%;
						background: rgb(255, 255, 255);
						position: absolute;
						bottom: 11.8rem;
						left: 0;
						z-index: -1;
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
						position: relative;
						z-index: 2;
					}

					.coverPNGImageIcon {
						bottom: 0;
						left: 50%;
						transform: translateX(-60%);
						flex-direction: row;
					}
				}

				.TextWrapper {
					width: 30rem;
					height: 10rem;
					border-bottom: 4px solid #fff;
					border-left: 4px solid #fff;
					border-right: 4px solid #fff;
					position: absolute;
					bottom: 1.8rem;
					left: 50%;
					transform: translateX(-50%);
					z-index: 2;
					display: flex;
					align-items: center;
					justify-content: center;

					p {
						color: #fff;
						font-family: "Poppins";
						font-size: 4rem;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						text-align: center;
						text-transform: uppercase;
						letter-spacing: 1.5rem;
						display: flex;
						flex-wrap: wrap;

						span {
							&:nth-child(odd) {
								margin-top: -1rem;
							}
							&:nth-child(even) {
								margin-top: 1rem;
							}
						}
					}
				}
			}

			.MainMiddle {
				width: 100%;
				min-height: 20rem;
				max-height: 20rem;
				position: relative;

				@media screen and (max-height: 750px) {
					min-height: 25vh;
					max-height: 25vh;
				}

				@media screen and (max-height: 550px) {
					min-height: 30vh;
					max-height: 30vh;
				}

				.MainTopWrapper {
					width: 100%;
					height: 100%;
					border-radius: 1.43231rem;
					background: #fff;
					box-shadow: 17.18764px 17.18764px 11.45843px 11.45843px rgba(0, 0, 0, 0.25),
						0px 4.58337px 4.58337px 0px rgba(0, 0, 0, 0.25);
					overflow: hidden;
					position: relative;
					z-index: 2;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding-top: 2rem;

					@media screen and (max-height: 750px) {
						padding-top: 1rem;
					}

					p {
						color: #000;
						font-family: "Poppins";
						font-size: 2.57544rem;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
					}

					h1 {
						font-family: "Poppins";
						font-size: 4.17069rem;
						font-style: italic;
						font-weight: 700;
						line-height: 193.9%; /* 8.087rem */
						text-transform: uppercase;
						// background: url("./images//Rectangle\ 18.png");
						background-position: 100% 50%;
						background-clip: text;
						// -webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						width: 100%;
						text-align: center;
					}

					.FollowersWrapper1 {
						width: 24rem;
						height: 10rem;
						color: #7d7d7d;
						font-family: "Poppins";
						text-align: center;
						font-size: 13rem;
						font-style: normal;
						font-weight: 600;
						line-height: 76.4%; /* 10.08856rem */
						position: absolute;
						bottom: -5rem;
						left: 50%;
						transform: translateX(-50%);

						@media screen and (max-height: 750px) {
							font-size: 10rem;
							bottom: -6rem;
						}

						@media screen and (max-height: 550px) {
							font-size: 5rem;
							bottom: -8rem;
						}
					}
				}

				.FollowersWrapper2 {
					width: 24rem;
					height: 10rem;
					color: #303030;
					font-family: "Poppins";
					text-align: center;
					font-size: 13rem;
					font-style: normal;
					font-weight: 600;
					line-height: 76.4%; /* 10.08856rem */
					position: absolute;
					bottom: -5rem;
					left: 50%;
					transform: translateX(-50%);
					z-index: 1;

					@media screen and (max-height: 750px) {
						font-size: 10rem;
						bottom: -6rem;
					}

					@media screen and (max-height: 550px) {
						font-size: 5rem;
						bottom: -8rem;
					}

					.FollowersText {
						color: #303030;
						font-family: "Poppins";
						font-size: 2.49619rem;
						font-style: normal;
						font-weight: 400;
						line-height: 76.4%; /* 1.90706rem */
						letter-spacing: 1.65994rem;
						position: absolute;
						// bottom: -3rem;
						left: 50%;
						transform: translateX(-50%);

						@media screen and (max-height: 750px) {
							// bottom: -6rem;
						}

						@media screen and (max-width: 500px) {
							font-size: 2rem;
						}
					}
				}
			}

			.MainBottom {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				width: 100%;
				padding-bottom: 2rem;
				padding-top: 3rem;

				@media screen and (max-height: 930px) {
					// margin-top: 1rem;
					@media screen and (max-width: 660px) {
						margin-top: 7rem;
					}
				}

				button {
					background: transparent;
					padding: 1.17725rem;
					color: #252525;
					font-family: "Poppins";
					font-size: 1.88356rem;
					font-style: normal;
					font-weight: 400;
					line-height: 76.4%; /* 1.43906rem */
					cursor: pointer;
					border: none;
				}
			}
		}
	}

	.RatingContainer {
		position: absolute;
		bottom: 5%;
		right: 6%;
		width: 8rem;
		height: 8rem;
		background-image: url("../../assets/images/rating.png");
		background-size: 100% 100%;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		// overflow: hidden;

		@media screen and (max-height: 930px) {
			@media screen and (max-width: 500px) {
				right: auto;
				bottom: 7% !important;
				left: 5% !important;
			}
		}
		@media screen and (max-height: 670px) {
			@media screen and (max-width: 500px) {
				right: auto;
				bottom: 9% !important;
				left: 5% !important;
			}
		}

		@media screen and (max-width: 700px) {
			bottom: 9%;
			right: auto;
			left: 5%;
			width: 7rem;
			height: 7rem;
		}

		@media screen and (max-width: 500px) {
			bottom: 12%;
			right: auto;
			left: 2%;
			width: 5rem;
			height: 5rem;
		}

		p {
			color: #fff;
			leading-trim: both;
			text-edge: cap;
			font-family: "Poppins";
			font-size: 1.56913rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			@media screen and (max-width: 700px) {
				font-size: 1.2rem;
			}

			@media screen and (max-width: 500px) {
				font-size: 1rem;
			}
		}
	}
}

.Container2 {
	min-height: 100vh;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media screen and (max-width: 650px) {
		@media screen and (max-height: 930px) {
			height: 100vh;
		}
	}

	.IntroImageWrapper {
		height: 100vh;
		width: 100%;
		overflow: hidden;
		position: relative;

		@media screen and (max-width: 650px) {
			height: 100vh;

			@media screen and (max-height: 930px) {
				height: 50vh;
			}
		}

		.IntroImageIcon {
			top: 10%;
			left: 15%;
		}

		.introImage {
			height: 100%;
			width: 100%;
			filter: drop-shadow(-27.558460235595703px 41.33769226074219px 41.33769226074219px rgba(0, 0, 0, 0.25));
			clip-path: polygon(0 0, 50% 0, 100% 75%, 100% 100%, 60% 100%, 0 10%);

			@media screen and (max-width: 650px) {
				@media screen and (max-height: 930px) {
					clip-path: polygon(0 0, 70% 0, 100% 34%, 100% 100%, 60% 100%, 0 15%); //Mobile
				}
			}

			img {
				height: 100%;
				width: 100%;
			}
		}

		.introText {
			position: absolute;
			bottom: 2rem;
			left: 5rem;

			@media screen and (max-width: 650px) {
				@media screen and (max-height: 930px) {
					bottom: 1rem;
					left: 3rem;
				}
			}

			p {
				color: #000;
				text-align: center;
				font-family: "Poppins";
				font-size: 9.64006rem;
				font-style: normal;
				font-weight: 700;
				line-height: 76.4%; /* 7.365rem */

				@media screen and (max-width: 650px) {
					@media screen and (max-height: 930px) {
						font-size: 6rem;
					}
				}
			}
		}
	}

	.IntroTextWrapper {
		width: 100%;
		position: relative;

		.EditTextIcon {
			width: 3.5rem;
			height: 3.5rem;
			position: absolute;
			top: 5%;
			left: 10%;
			cursor: pointer;

			@media screen and (max-width: 750px) {
				@media screen and (max-height: 750px) {
					top: 0%;
					left: 2%;
				}
			}
		}

		.Text {
			width: 88.02031rem;
			min-height: 27rem;
			color: #626262;
			text-align: center;
			font-family: "	";
			font-size: 2.64688rem;
			font-style: normal;
			font-weight: 300;
			line-height: 218.5%; /* 5.7835rem */
			margin: 0 auto;
			margin-top: 9rem;

			&::-webkit-scrollbar {
				display: none;
			}

			@media screen and (max-width: 750px) {
				margin-top: 5rem;
				width: 90%;
				line-height: 140%; /* 5.7835rem */

				@media screen and (max-height: 750px) {
					margin-top: 4rem;
					line-height: 120%; /* 5.7835rem */
				}
			}
		}
	}

	.ButtonWrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 11rem;

		@media screen and (max-width: 750px) {
			padding: 0 5rem;
		}

		button {
			color: #000;
			text-align: center;
			font-family: "Poppins";
			font-size: 3.4375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 76.4%; /* 2.62625rem */
			padding: 1rem;
			border: none;
			background: transparent;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				bottom: -1rem;
				left: 50%;
				transform: translateX(-50%);
				width: 70%;
				height: 2px;
				background: #000;
			}
		}
	}
}

.Container3 {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;

	.MYGalleryWrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		h1 {
			color: #000;
			text-align: center;
			font-family: "Poppins";
			font-size: 7.07463rem;
			font-style: normal;
			font-weight: 300;
			line-height: 200%; /* 15.45813rem */
			position: relative;
			text-transform: uppercase;

			&::after {
				content: "";
				position: absolute;
				top: 3rem;
				left: 0;
				width: 40%;
				height: 4px;
				background: #c5c5c5;
			}

			&::before {
				content: "";
				position: absolute;
				bottom: 3rem;
				right: 0;
				width: 40%;
				height: 4px;
				background: #c5c5c5;
			}
		}
	}

	.CarouselWrapper {
		width: 100%;
		height: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		justify-content: space-between;
		overflow: hidden;

		.MainImageCarouselCont {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			overflow: hidden;
			position: relative;

			.MainImageWrapper {
				width: 80vw;
				height: 100%;
				background: #000;
				border-radius: 2.47731rem;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}

				video {
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}
			}
		}

		.SubImageCarouselCont {
			width: 100%;
			height: 30%;
			display: flex;
			justify-content: center;

			.InnerSubImageCarousel {
				width: 80vw;
				display: flex;
				padding: 0 5rem;
				overflow: hidden;

				@media screen and (max-width: 1200px) {
					width: 100vw;
				}

				@media screen and (max-width: 700px) {
					padding: 0 0rem 0 2rem;
				}

				.BoxWrapper {
					background: #000;
					margin-right: 1rem;
					min-height: 100%;
					max-height: 12rem;
					height: 12rem;
					width: 15rem;
					border-radius: 1.41594rem;
					overflow: hidden;

					img,
					video {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	.ViewAllWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 2rem;
		margin-top: 1rem;
		margin-bottom: 1rem;

		@media screen and (max-width: 600px) {
			justify-content: flex-end;
		}

		button {
			color: #252525;
			text-align: center;
			font-family: "Poppins";
			font-size: 2.33131rem;
			font-style: normal;
			font-weight: 400;
			line-height: 103.9%; /* 2.42219rem */
			padding: 0 1rem;
			border: none;
			background: transparent;
			cursor: pointer;
		}
	}
}

.Container4 {
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 5rem;
	padding-bottom: 5rem;
	position: relative;

	h1 {
		color: #252525;
		text-align: center;
		font-family: "Poppins";
		font-size: 4.01556rem;
		font-style: normal;
		font-weight: 600;
		line-height: 103.9%; /* 4.17213rem */
		margin-bottom: 5rem;

		@media screen and (max-width: 600px) {
			font-size: 3.8rem;
		}

		@media screen and (max-width: 400px) {
			font-size: 3rem;
		}
	}

	.EditTextIcon {
		width: 4rem;
		height: 4rem;
		position: absolute;
		top: 20%;
		left: 23%;
		cursor: pointer;

		@media screen and (max-width: 700px) {
			top: 20%;
			left: 10%;
		}
	}

	.ShortSMS {
		color: #252525;
		text-align: center;
		font-family: "Poppins";
		font-size: 2.88144rem;
		font-style: normal;
		font-weight: 300;
		line-height: 103.9%; /* 2.99381rem */
		width: 65.67769rem;
		height: 9rem;
		margin-bottom: 6rem;

		@media screen and (max-width: 600px) {
			width: 80%;
			font-size: 2rem;
		}

		@media screen and (max-width: 400px) {
			font-size: 1.8rem;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	h4 {
		color: #252525;
		font-family: "Noto Serif";
		font-size: 5.84838rem;
		font-style: normal;
		font-weight: 400;
		line-height: 101.4%; /* 5.93025rem */

		@media screen and (max-width: 600px) {
			font-size: 4rem;
		}

		@media screen and (max-width: 400px) {
			font-size: 3rem;
		}
	}
}
