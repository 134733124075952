.NavBar {
	width: 100vw;
	background: #000;
	display: flex;
	padding: 20px 63.5px 20px 46.5px;
	align-items: center;
	justify-content: space-between;
	position: relative;
	overflow: hidden;

	.ProgressBar{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: .4rem;
		border-radius: 1rem;
		overflow: hidden;
		
		.Bar{
			width: 100%;
			height: 100%;
			background: #00ff11;

		}
	}

	@media screen and (max-width: 900px) {
		flex-direction: column;
		gap: 64px;
		align-items: flex-start;
	}

	button {
		border-radius: 10px;
		background: #2d2d2d;
		color: #fff;
		font-family: "Poppins";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 99.5%;
		border: none;
		height: 48px;
		width: 150px;
		cursor: pointer;

		&.Publish {
			background: #0d48bb;
		}

		&.Icon {
			height: 48px;
			width: 48px;
		}
	}

	.Left {
		display: flex;
		gap: 33px;
		align-items: center;
	}
	.Right {
		display: flex;
		gap: 12px;
		align-items: center;

		@media screen and (max-width: 900px) {
			flex-direction: column;
			align-items: flex-start;
			width: 100%;

			button {
				width: 100%;
			}
		}
	}
}

.QRWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(4px);
	display: flex;
	align-items: center;
	justify-content: center;

	.QR {
		width: 300px;
		// height: 350px;
		background: #dadada;
		border-radius: 2rem;
		box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2);
		display: flex;
		align-items: center;
		// justify-content: center;
		flex-direction: column;
		overflow: hidden;
		padding-bottom:20px;

		.qr {
			width: 300px;
			height: 300px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #dadada;
			border-radius: 1rem;
		}

		.ButtonContainer {
			width: 100%;
			padding: 0 2rem;
			display: flex;
			align-items: center;
			gap: 2rem;

			button {
				width: 100%;
			}
		}
	}
}
