html {
	&::-webkit-scrollbar {
		display: none;
	}
}

.Template5Wrapper {
	height: 100vw;
	height: 100vh;
	scrollbar-width: none;
	background: var(--frsd, radial-gradient(50% 50% at 50% 50%, #0e2138 0%, #0a1b30 100%));
	scroll-behavior: smooth;
	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	.Setting {
		width: 48px;
		height: 48px;
		cursor: pointer;
		position: absolute;
		top: 45%;
		left: 5%;
		z-index: 10;
	}

	.Template5 {
		// width: 1728px;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		scroll-behavior: smooth;
		scroll-snap-type: y proximity;
		margin: 0 auto;
		position: relative;
		background: #fff;

		&::-webkit-scrollbar {
			display: none;
		}

		section {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}
}

.container1 {
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	background-color: transparent;
	position: relative;

	.star {
		position: absolute;
		top: 4%;
		left: 4%;
		width: 8.2565rem;
		height: 8.2565rem;
		border-radius: 50%;
		z-index: 15;
		background-image: url("../../assets/images/rating.png");
		background-size: cover;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-family: Poppins;
		font-size: 2.30219rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		cursor: pointer;

		@media (max-width: 930px) and (min-height: 600px) {
			width: 3.98419rem;
			height: 3.98419rem;
			min-width: 12vw;
			min-height: 12vw;
			top: 2%;
		}
		@media (max-width: 500px) and (min-height: 600px) {
			width: 3.98419rem;
			height: 3.98419rem;
			min-width: 16vw;
			min-height: 16vw;
			top: 2%;
		}
	}
	.follower {
		position: absolute;
		top: 75%;
		left: 6%;
		z-index: 6;
		max-width: fit-content;
		transform: rotate(-21.387deg);
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (max-width: 1410px) {
			@media (max-height: 570px) {
				transform: rotate(-14.995deg);
				left: 6%;
			}
		}
		@media (max-width: 1280px) {
			@media (max-height: 577px) {
				transform: rotate(-15.995deg);
				left: 7%;
			}
		}
		// @media (max-width: 850px) {
		// 	top: 5%;
		// 	left: 42%;
		// }
		@media (max-width: 930px) and (min-height: 600px) {
			top: 5%;
			left: 38%;
		}

		@media (max-width: 440px) {
			top: 5%;
			left: 38%;
			transform: rotate(-29.995deg);
		}

		.followerCount {
			width: 12rem;
			height: 4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			color: #010101;
			font-family: Poppins;
			font-size: 4.49138rem;
			font-size: minmax(2rem, 6rem);
			font-style: italic;
			font-weight: 700;
			line-height: normal;
		}

		.textFollower {
			color: #010101;
			font-family: Poppins;
			font-size: 1.37538rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
	.nameFrame {
		position: absolute;
		width: 55vw;
		height: 8vh;
		min-height: 4.3rem;
		border: 1px solid black;
		bottom: 17%;
		left: 25%;
		border-radius: 4rem;
		background-color: #140a29;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 5;

		@media (max-width: 1950px) {
			bottom: 18%;
		}
		@media (max-width: 1420px) {
			bottom: 16.7%;
		}
		@media (max-width: 1280px) {
			bottom: 18%;
		}
		@media (max-width: 1280px) {
			@media (max-height: 577px) {
				bottom: 16.5%;
			}
		}

		@media (max-width: 1024px) and (max-height: 500px) {
			bottom: 18.15%;
		}
		@media (max-width: 854px) and (max-height: 500px) {
			bottom: 17.55%;
		}
		@media (max-width: 754px) and (max-height: 500px) {
			bottom: 16.55%;
		}

		@media (max-width: 930px) and (min-height: 600px) {
			bottom: 10%;
			width: 70vw;
			height: 9.4vh;
			border-radius: 5rem;
		}
		@media (max-width: 440px) {
			height: 9vh;
			width: 75vw;
			bottom: 11%;
			left: 25%;
		}

		.coverName {
			width: 60vw;
			height: 4.844rem;
			color: #fff;
			font-family: Bebas Neue;
			font-size: 3.97088rem;
			font-style: normal;
			font-weight: 400;
			text-align: center;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
	button {
		color: #010101;
		font-family: Inter;
		font-size: 2.625rem;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
		letter-spacing: 0.525rem;
		text-transform: uppercase;
		transform: rotate(-18.995deg);
		cursor: pointer;
		border: none;
		background: transparent;
	}
	.message {
		position: absolute;
		top: 79%;
		right: 14%;
		z-index: 6;
		@media (max-width: 1420px) {
			@media (max-height: 570px) {
				right: 16%;
				top: 78%;
			}
		}
		@media (max-width: 1280px) {
			@media (max-height: 577px) {
				right: 16%;
				top: 78%;
			}
		}
		// @media (max-width: 850px) {
		// 	transform: rotate(-23.688deg);
		// 	top: 84%;
		// 	left: -40%;
		// }

		@media (max-width: 930px) and (min-height: 600px) {
			transform: rotate(-22.688deg);
			top: 84%;
			left: -45%;
		}
		@media (max-width: 530px) and (min-height: 600px) {
			transform: rotate(-35.688deg);
			top: 84%;
			left: -45%;
		}
		@media (max-width: 440px) {
			font-size: 2.6rem;
			top: 83%;
			left: -42%;
			transform: rotate(-31.688deg);
		}
		@media (max-width: 380px) {
			transform: rotate(-28.688deg);
			font-size: 2.4rem;
			top: 83%;
			left: -45%;
			font-size: 2.3rem;
		}
	}
	.follow {
		position: absolute;
		top: 80%;
		right: 2%;
		z-index: 6;

		@media (max-width: 1420px) {
			@media (max-height: 570px) {
				right: 2%;
				top: 76%;
			}
		}

		@media (max-width: 1280px) {
			@media (max-height: 577px) {
				right: 2%;
				top: 76%;
			}
		}

		// @media (max-width: 850px) {
		// 	transform: rotate(-24.688deg);
		// 	top: 89%;
		// 	right: 5%;
		// }
		@media (max-width: 930px) and (min-height: 600px) {
			transform: rotate(-21.688deg);
			top: 87%;
			right: 3%;
		}
		@media (max-width: 530px) and (min-height: 600px) {
			transform: rotate(-35.688deg);
			top: 89%;
			right: 5%;
		}

		@media (max-width: 440px) {
			transform: rotate(-33.688deg);
			top: 88%;
			right: 2%;
			font-size: 2.6rem;
		}
		@media (max-width: 380px) {
			transform: rotate(-30.688deg);
			top: 87%;
			right: 2%;
			font-size: 2.2rem;
		}
	}

	.coverChangeIcon {
		position: absolute;
		height: 3.5rem;
		width: 3.5rem;
		top: 10%;
		left: 50%;
		z-index: 5;
		filter: contrast(3);
	}

	.editImageIcon img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.coverImage {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		overflow: hidden;
		@media (max-width: 650px) {
			height: 100%;
			width: 100%;
		}
	}

	.coverImage img {
		height: 100%;
		width: 100%;
		// object-fit: contain;
	}

	.frame {
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		@media (max-width: 930px) and (min-height: 600px) {
			height: 100%;
			width: 100%;
		}

		.desktopFrame {
			@media (max-width: 930px) and (min-height: 600px) {
				display: none;
			}
		}

		.mobileFrame {
			display: none;
			@media (max-width: 930px) and (min-height: 600px) {
				display: block;
			}
		}
	}

	.frame img {
		height: 100%;
		width: 100%;
	}
}

.container2 {
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	display: flex;

	@media (max-width: 930px) and (min-height: 600px) {
		flex-direction: column;
	}

	.hero {
		width: 45%;
		height: 100%;
		// background-color: #14a34b;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 930px) and (min-height: 600px) {
			min-width: 95%;
			min-height: 65vh;
			max-height: 70vh;
			margin: auto;
		}

		.leftImage {
			position: relative;
			width: 73.91375rem;
			max-width: 95%;
			max-height: 95%;
			height: 82.37925rem;
			border-radius: 2.92685rem;
			// padding: 1rem;
			background-color: #fff;
			// outline: 0.951175rem solid #000;
			overflow: hidden;

			&::after {
				content: "";
				position: absolute;
				top: 15%;
				left: 19%;
				width: 46%;
				aspect-ratio: 1/1;
				border: 41.136px solid rgba(255, 255, 255, 0.29);
				transform: rotate(-45deg);

				@media (max-width: 1950px) {
					left: 20.5%;
					top: 15%;
				}
				@media (max-width: 1420px) {
					@media (max-height: 570px) {
						width: 42%;
						left: 23%;
						top: 19%;
						border: 27.598px solid rgba(255, 255, 255, 0.29);
					}
				}
				@media (max-width: 1280px) {
					width: 44%;
					border: 33.598px solid rgba(255, 255, 255, 0.29);
				}

				@media (max-width: 1280px) {
					@media (max-height: 577px) {
						width: 44%;
						left: 24%;
						top: 18%;
						border: 23.598px solid rgba(255, 255, 255, 0.29);
					}
				}
				@media (max-width: 1080px) {
					border: 26.598px solid rgba(255, 255, 255, 0.29);
					width: 44%;
					left: 22%;
				}
				@media (max-width: 1080px) and (max-height: 500px) {
					border: 18.598px solid rgba(255, 255, 255, 0.29);
					width: 38%;
					left: 26%;
				}
				// @media (max-width: 850px) {
				// 	border: 26.598px solid rgba(255, 255, 255, 0.29);
				// 	width: 44%;
				// 	left: 23%;
				// 	top: 17%;
				// }

				@media (max-width: 930px) and (min-height: 600px) {
					width: 45%;
					top: 16%;
					left: 24%;
					border: 23.598px solid rgba(255, 255, 255, 0.29);
				}
				@media (max-width: 530px) and (min-height: 600px) {
					width: 45%;
					top: 15%;
					left: 21%;
					border: 23.598px solid rgba(255, 255, 255, 0.29);
				}
			}
			&::before {
				content: "";
				box-sizing: border-box;
				position: absolute;
				top: 2.5%;
				left: 2.5%;
				width: 95%;
				height: 95%;
				z-index: 1;
				border-radius: 3.2685rem;
				border: 19.175px solid #fff;
				@media (max-width: 930px) and (min-height: 600px) {
					border-radius: 1.875rem;
					border: 11px solid #fff;
				}
			}

			.introChangeIcon {
				position: absolute;
				height: 3.5rem;
				width: 3.5rem;
				top: 2rem;
				right: 2rem;
				filter: contrast(3);
			}

			.editImageIcon img {
				height: 100%;
				width: 100%;
				// object-fit: cover;
			}
		}

		.leftImage img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 3.12685rem;
		}
	}

	.content {
		width: 55%;
		height: 100%;
		padding: 3rem 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: 10rem 0;
		.EditTextIcon {
			position: absolute;
			top: 28vh;
			left: 1vw;
			height: 3.5rem;
			width: 3.5rem;
			@media (max-width: 930px) and (min-height: 600px) {
				top: 4vh;
				left: 2vw;
			}
		}

		@media (max-width: 1280px) {
			@media (max-height: 577px) {
				padding: 7rem 0;
			}
		}

		@media (max-width: 930px) and (min-height: 600px) {
			width: 100%;
			padding: 0;
			margin-bottom: 3rem;
		}

		.myIntro {
			width: 49.24769rem;
			height: 9.53944rem;
			max-height: 14vh;
			max-width: 45vw;
			border-radius: 15.29869rem;
			background: #140a29;
			color: #fff;
			font-family: Poppins;
			font-size: 3.67169rem;
			font-style: italic;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.36719rem;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: 930px) and (min-height: 600px) {
				min-width: 68%;
				height: 7vh;
			}
		}

		.introDesc {
			width: 45.60706rem;
			min-width: 45vw;
			max-width: 50vw;
			min-height: 40vh;
			max-height: 50vh;
			color: #010101;
			text-align: center;
			font-family: Poppins;
			font-size: 2.14181rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			&::-webkit-scrollbar {
				display: none;
			}

			// @media (max-width: 850px) {
			// 	min-width: 90%;
			// 	min-height: 5vh;
			// 	max-height: 15vh;
			// 	border: 2px solid green;
			// }
			@media (max-width: 930px) and (min-height: 600px) {
				min-width: 90%;
				min-height: 15vh;
				max-height: 15vh;
				border: 2px solid green;
			}
		}

		.buttons {
			width: 100%;
			display: flex;
			justify-content: space-evenly;
		}

		.buttons button {
			color: #010101;
			font-family: Poppins;
			font-size: 2.44781rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.24475rem;
			text-transform: uppercase;
			border: none;
			background: transparent;
			cursor: pointer;
		}
	}
}

.container3 {
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	position: relative;

	.intro2ChangeIcon {
		position: absolute;
		height: 3.5rem;
		width: 3.5rem;
		top: 2rem;
		right: 2rem;
		filter: contrast(3);
	}

	.editImageIcon img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.galleryImage {
		height: 100%;
		width: 100%;
		max-height: 100vh;
		max-width: 100vw;
		overflow: hidden;

		@media (max-width: 930px) and (min-height: 600px) {
			// clip-path: polygon(0 0, 83% 0, 100% 8%, 100% 100%, 16% 100%, 0 92%);
		}
	}

	.galleryImage img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.frame {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		max-height: 100vh;
		max-width: 100vw;
	}

	.frame img {
		height: 100%;
		width: 100%;
		object-fit: 100% 100%;
	}
	.border {
		position: absolute;
		top: 2.5vh;
		left: 2.5vw;
		// width: 102.30844rem;
		width: 95vw;
		max-width: 95vw;
		height: 63.01444rem;
		height: 95vh;
		max-height: 95vh;
		border-radius: 2.6875rem 12.49544rem;
		border: 8px solid #000;
		@media (max-width: 930px) and (min-height: 600px) {
			width: 24.99088rem;
			min-width: 95vw;
			height: 56.28363rem;
			min-height: 95vh;
			border-radius: 2.6875rem 12.49544rem;
			border: 8px solid #000;
		}
	}
}

.container4 {
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	// background-color: rgb(28, 165, 184);

	.headerSection {
		height: 10%;
		width: 100%;
		padding: 0 5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// background-color: blue;

		.myGallery {
			color: #000;
			font-family: Poppins;
			font-size: 2.625rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.viewAll {
			color: #6d6d6d;
			font-family: Poppins;
			font-size: 1.625rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}

	.carouselSection {
		height: 65%;
		width: 100%;

		.activeContainer {
			width: 95.70431rem;
			max-width: 50vw;
			height: 55.35088rem;
			border-radius: 2.57763rem;
			max-height: 50vh;
			min-height: 50vh;
			// aspect-ratio: 16/9;
			margin: auto;
			position: relative;

			@media (max-width: 400px) {
				max-height: 55vh;
			}

			@media (max-width: 930px) and (min-height: 600px) {
				min-height: 58vh;
				min-width: 90vw;
			}

			.imageSection {
				height: 100%;
				width: 100%;
				background-color: #010101;
				border-radius: 2.57763rem;
			}

			.imageSection img {
				border-radius: 2.57763rem;
				height: 100%;
				width: 100%;
				object-fit: contain;
			}

			.below {
				position: absolute;
				bottom: 0;
				width: 100%;
				display: flex;
				justify-content: space-between;
				background-color: rgba(0, 0, 0, 0.2);
				border-radius: 0 0 2.57763rem 2.57763rem;

				.desc {
					margin: auto;
					margin-left: 2rem;
					width: 25.64925rem;
					max-width: 40%;
					height: 4.34688rem;
					color: #fff;
					font-family: Poppins;
					font-size: 1.79113rem;
					font-style: normal;
					font-weight: 400;
					line-height: 123.4%;
					overflow: hidden;
					// border: 1px solid red;
				}

				.like {
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 4.5rem;
						height: 4.5rem;
					}
					p {
						width: 3.2rem;
						height: 3.2rem;
						color: #fff;
						font-family: "Poppins";
						font-size: 2.46006rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 1.80175rem */
					}
				}
				// .LikeIcon {
				// }

				.icons {
					display: flex;
					gap: 2rem;
					padding-right: 1rem;

					@media (max-width: 930px) and (min-height: 600px) {
						gap: 1rem;
					}

					.Icon {
						margin-top: 0.4rem;
						height: 5.8rem;
						width: 6.4rem;
						display: flex;
						align-items: center;
						gap: 0.31rem;
						cursor: pointer;

						img {
							width: 100%;
							height: 100%;
						}

						p {
							width: 3.2rem;
							height: 3.2rem;
							color: #fff;
							font-family: "Poppins";
							font-size: 2.46006rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%; /* 1.80175rem */
						}
					}

					.comment {
						display: flex;
						align-items: center;
						justify-content: center;

						.commentIcon {
							width: 4.132rem;
							height: 5.185675rem;
							// background-color: red;
						}

						.commentIcon img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}

						.commentCount {
							color: #fff;
							font-family: Poppins;
							font-size: 2.983rem;
							font-style: normal;
							font-weight: 600;
							line-height: 123.4%;
						}
					}

					.shareIcon {
						width: 4rem;
						height: 6.285675rem;
						// background-color: red;
					}

					.shareIcon img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

	.footer {
		height: 25%;
		width: 100%;
		// background-color: red;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-evenly;
		position: relative;
		.EditTextIcon {
			position: absolute;
			left: 18vw;
			bottom: 18vh;
			height: 3.5rem;
			width: 3.5rem;
			@media (max-width: 930px) and (min-height: 600px) {
				left: 4vw;
				bottom: 23vh;
			}
		}

		.thankDesc {
			width: 48.51713rem;
			max-width: 70vw;
			height: 60%;
			max-height: 60%;
			color: #000;
			text-align: center;
			font-family: Poppins;
			font-size: 1.375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 172%;
			@media (max-width: 1950px) {
				@media (max-height: 577px) {
					min-height: 75%;
				}
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.thanku {
			color: #000;
			font-family: Playball;
			font-size: 2rem;
			font-style: normal;
			font-weight: 400;
			line-height: 94.5%;
			/* 1.89rem */
		}
	}
}
